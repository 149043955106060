import useFetchWithLoading from "../../../hooks/useFetchWithLoading";
import {useEffect} from "react";
import {IRequest} from "../../../models/interfaces/IRequest";
import {HttpMethod} from "../../../models/enums/HttpMethod";
import {useParams} from "react-router-dom";
import {URLS} from "../../../utils/url";
import SectionTitle from "../../../components/SectionTitle";
import {formatDate, removeTags, utils} from "../../../utils/Utils";
import {DATE_FORMAT1} from "../../../models/constants/Constants";
import TargetBlank from "../../../components/TargetBlank";

const RequestWrittenReportCard = ({title}: { title: string }) => {
    const {id} = useParams();
    const request: IRequest = {
        url: URLS.REQUEST_WRITTEN_REPORT + '/' + id,
        type: HttpMethod.GET
    };
    const [data, loadData] = useFetchWithLoading({
        request: request,
        initial: []
    });
    useEffect(() => {
        (async () => (await loadData()))();
    }, []);

    const getProyectista = (item: any) => {
        return item.senador_data?.filter((element: any) => element.senador !== null)
            .map((e: any) => e.senador.nombre + ' ' + e.senador.apellidos).join(', ');
    };
    return (
        <>
            <SectionTitle title={title}/>
            {data && <>
                <h3 className='title-1 text-center mt-3'>{data.titulo}</h3>
                <div className='container shadow-left-bottom my-3'>
                    <div className='p-5 d-flex flex-column'>
                        {data.type &&
                        <>
                            <label className='title-description-2'>Tipo:</label>
                            <label className='description-1'>{data.type?.name}</label>
                        </>
                        }
                        <label className='title-description-2'>Fecha de Ingreso:</label>
                        <label
                            className='description-1 text-capitalize'>{formatDate(data.fecha_recepcion, DATE_FORMAT1)}</label>
                        <label className='title-description-2'>Peticionario:</label>
                        <label
                            className='description-1'>{getProyectista(data)}</label>
                        <label className='title-description-2'>Resumen:</label>
                        <label className='description-1'>{removeTags(data.resumen, false)}</label>
                        {data.destinatario &&
                        <>
                            <label className='title-description-2'>Tipo destinatario:</label>
                            <label
                                className='description-1'>{data.destinatario?.name}</label>
                        </>
                        }

                        <label className='title-description-2'>Destinatario:</label>
                        <label className='description-1'>{data.tipo_destinatario}</label>
                        <label className='title-description-2'>Remisión al Destinatario:</label>
                        <label
                            className='description-1 text-capitalize'>{formatDate(data.fecha_remision_peticionario, DATE_FORMAT1)}</label>

                        <label className='title-description-2'>Conminatoria:</label>
                        <label
                            className='description-1 text-capitalize'>{data.conminatoria}</label>

                        <label className='title-description-2'>Fecha Respuesta:</label>
                        <label
                            className='description-1 text-capitalize'>{formatDate(data.fecha_respuesta, DATE_FORMAT1)}</label>

                        <label className='title-description-2'>Gestion Fiscalización:</label>
                        <label className='description-1'>{data.gestion_focalizacion}</label>
                    </div>
                </div>
                {data && data.doc_archivo && <div className='d-flex justify-content-center my-3'>
                    <TargetBlank className='btn download-pdf d-flex justify-content-between'
                       href={utils(data.doc_archivo)}>
                        <div className='icon mx-2'/>
                        Descargar
                    </TargetBlank>
                </div>}
            </>}
        </>
    );
};

export default RequestWrittenReportCard;
