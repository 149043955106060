export enum FieldType{
    HTML, DIRECT, FILE, LIST
}

export interface IBasicTable {
    label: string,
    field: string,
    type: FieldType,
    download?: boolean,
    formatter?: any,
    className?: string,
    styles?: any,
    source?: ISource,
    link?: ILink
}
export interface ISource{
    name: string,
    key: string,
    labels: string|string[],
}
export interface ILink{
    field: string,
    path?: string
}