import { types } from '../types/types';
export const initialStateToast = {
    message: undefined,
};
export const toastReducer = (state = initialStateToast, action: any ) => {
    switch ( action.type ) {
        case types.toast:
            return {
                ...state,
                message: action.payload
            }
        default:
            return state;
    }
}
