import {DropDown} from "../models/interfaces/NavConfig";
import Noticias from "../ui/prensa/Noticias";
import {buildDropDown} from "../utils/Utils";
import {TITLES} from "../models/constants/ConstantsUI";
import Gallery from "../ui/prensa/Gallery";
import VideoGallery from "../ui/prensa/VideoGallery";
import Advices from "../ui/prensa/Advices";

export const NewsRoutes: DropDown =
    buildDropDown({
        title: TITLES.NEWS_INFO,
        link: true,
        // component: <PrensaDescripcion/>,
        clazz: 'root-link',
        links: [
            {
                title: TITLES.NEWS,
                component: <Noticias/>
            },
            {
                title: TITLES.IMAGES_GALLERY,
                component: <Gallery/>
            },
            {
                title: TITLES.VIDEO_GALLERY,
                component: <VideoGallery/>
            },
            {
                title: TITLES.ADVICES,
                component: <Advices/>
            },
            // {
            //     title: TITLES.SENATE_RATIO,
            //     component: <UnderConstruction/>
            // }
        ]
    });
