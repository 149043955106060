import GenericDescription from "../../generic-description/GenericDescription";
import {GENERAL_SECRETARY} from "../../../mocks/StaticMocks";

const GeneralSecretary = () => {
    return (
        <>
            <GenericDescription title={GENERAL_SECRETARY.title} varImg={GENERAL_SECRETARY.imgUrl}
                                description={GENERAL_SECRETARY.description}
                                subtitle={''}/>
            <div className='container mb-5'>
                <div className='row'>
                    <div className='col-6' style={{height: '300px'}}>
                        <div className='bg-2' style={{height: '300px'}}/>
                    </div>
                    <div className='col-6' style={{height: '300px'}}>
                        <div className='bg-2' style={{height: '300px'}}/>
                    </div>
                </div>
            </div>
        </>
    );
};
export default GeneralSecretary;