import './error-page.css';

const ErrorPage = ({error}: { error: any }) => {
    return (
        <>
            <div className='error-page d-flex flex-column'>
                <div className="error-code">
                    <div className="number five">
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>

                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell empty"/>
                        <div className="cell empty"/>
                        <div className="cell empty"/>

                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell empty"/>

                        <div className="cell empty"/>
                        <div className="cell empty"/>
                        <div className="cell empty"/>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>

                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell empty"/>
                        <div className="cell empty"/>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>

                        <div className="cell empty"/>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell empty"/>
                    </div>
                    <div className="number zero">
                        <div className="cell empty"/>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell empty"/>

                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell empty"/>
                        <div className="cell empty"/>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>

                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell empty"/>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>

                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell empty"/>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>

                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell empty"/>
                        <div className="cell empty"/>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>

                        <div className="cell empty"/>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell empty"/>
                    </div>
                    <div className="number zero">
                        <div className="cell empty"/>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell empty"/>

                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell empty"/>
                        <div className="cell empty"/>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>

                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell empty"/>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>

                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell empty"/>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>

                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell empty"/>
                        <div className="cell empty"/>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>

                        <div className="cell empty"/>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell filled">
                            <div className="bug"/>
                        </div>
                        <div className="cell empty"/>
                    </div>
                </div>
                <div>
                    <a href={'/'} className='btn btn-success'>Inicio</a>
                </div>
            </div>
        </>
    )
};
export default ErrorPage;