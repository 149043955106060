import SectionTitle from "../../components/SectionTitle";
import {TITLES} from "../../models/constants/ConstantsUI";
import IconCard from "../../components/cards/card-icon/IconCard";
import icon from '../../assets/img/objetive.png';
import shortid from "shortid";

const OBJECTIVES = [
    `•	Consolidar la arquitectura de nuestro nuevo Estado al 2025. 
    A través de la aprobación, sanción o rechazo del 100% de los proyectos de ley, 
    instrumentos de gestión parlamentaria y fiscalizar de manera democrática y participativa.`,
    `•	Administrar la Cámara de Senadores de manera transparente, 
    a través de la implementación de sistemas de gestión Pública que 
    permitan atender los requerimientos de la entidad de manera eficiente y eficaz.`
];

const InstitutionalObjectives = () => {
    return (
        <>
            <SectionTitle title={TITLES.INSTITUTIONAL_OBJECTIVES} varImg={'bar-2'}/>
            <div className='container mt-10 mb-5'>
                <h3 className='title-section fs-1 fg-1'></h3>
                <div className='row'>
                    {OBJECTIVES.map(description=>
                        <div key={shortid()} className='col-xxl-15 col-md-6 col-sm-12 margin-top'>
                            <IconCard img={icon} description={description} orientation={'top'}/>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
export default InstitutionalObjectives;