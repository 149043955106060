import {useEffect} from "react";
import shortid from "shortid";
import SectionTitle from "../../../components/SectionTitle";
import SenatorCard from "../../../components/senator-card/SenatorCard";
import {HttpMethod} from "../../../models/enums/HttpMethod";
import {URLS} from "../../../utils/url";
import {useParams} from "react-router-dom";
import {TITLES} from "../../../models/constants/ConstantsUI";
import useFetchManyWithLoading from "../../../hooks/useFetchManyWithLoading";
import {FILTERS} from "../../../models/constants/Filters";

const PermanentCommissionInfo = () => {
    const {id} = useParams();
    const [data, loadData] = useFetchManyWithLoading({
        requests: [{
            url: URLS.PERMANENT_COMMISSIONS + '/' + id,
            type: HttpMethod.GET,
            key: 'info'
        },
            {
                url: FILTERS.PRESIDENCY_PERMANENT_COMMISSION,
                type: HttpMethod.GET,
                key: 'filter'
            }
        ],
        initial: null
    });

    useEffect(() => {
        (async () => await loadData())();
    }, []);
    return (
        <>
            <SectionTitle title={TITLES.COMMISSION_COMITE}/>
            {data && <div className='container mt-5 fg-1'>
                <h3 className='title-1 text-capitalize mb-5'>{data.info?.numero}. {data.info?.nombre}</h3>
                <div className='row d-flex justify-content-center my-5'>
                    {data.info?.senadores.filter((senador: any) => `${senador.cargo_comision_id}` === data.filter.value).map((item: any) =>
                        <RenderSenator item={item} key={shortid()}/>
                    )
                    }
                </div>
                <ol className='list-1'>
                    {data.info?.comites?.map((comite: any) => <li key={shortid()}>
                        <h3 className='title-1 text-capitalize'>{comite.name}</h3>
                        <p className='description-1'>
                            De conformidad a lo establecido en el artículo 12 del Reglamento Participación Ciudadana y
                            Deliberación Pública, se conforma la Comisión Especial y Permanente de Participación
                            Ciudadana
                            compuesta por los siguientes
                            Senadores:
                        </p>
                        <div className='row d-flex justify-content-center mt-5'>
                            {data.info?.senadores.filter((senador: any) => senador.comite_id === comite.id).map((item: any) =>
                                <RenderSenator item={item} key={shortid()}/>)
                            }
                        </div>
                    </li>)}
                </ol>
            </div>}
        </>
    );
};

const RenderSenator = ({item}: { item: any }) => {
    return (
        <>
            <div className='col-lg-3'>
                <SenatorCard fullName={item.nombre + ' ' + item.apellidos}
                             alt={item.nombre + ' ' + item.apellidos} src={
                    item.foto_perfil
                } benchName={item.bancada?.nombre}
                             brigade={item.brigada_catalogo?.name} id={item.id} back={'../../'}/>
            </div>
            {item.suplente_id && <div className='col-lg-3'>
                <SenatorCard fullName={item.suplente_id.nombre + ' ' + item.suplente_id.apellidos}
                             alt={item.suplente_id.nombre + ' ' + item.suplente_id.apellidos} src={
                    item.suplente_id.foto_perfil
                } benchName={item.suplente_id.bancada?.nombre}
                             brigade={item.suplente_id.brigada_catalogo?.name} id={item.suplente_id.id}
                             back={'../../'}/>
            </div>}
        </>
    );
};
export default PermanentCommissionInfo;
