import {Link} from "react-router-dom";
import navBarImg from "../../assets/img/navbar.png";
import {Navbar} from 'react-bootstrap';
import {useEffect, useRef, useState} from "react";
import './custom-navbar.css';
import {concatLinks, getLink, getMenuItem, isLink} from "../../utils/MenuUtil";
import {NAV_ROUTES} from "../../router/NAV_ROUTES";
import {DropDown, LinkType, MegaMenu, SimpleLink} from "../../models/interfaces/NavConfig";
import shortid from "shortid";


const CustomNavBar = () => {
    const [isSticky, setIsSticky] = useState(false)
    const navRef = useRef<HTMLInputElement | null>(null);
    const [open, setState] = useState(false);
    // mount
    useEffect(() => {
        const cachedRef = navRef.current;
        if (cachedRef != null) {
            const observer = new IntersectionObserver(
                ([e]) => setIsSticky(e.intersectionRatio < 1),
                {
                    threshold: [1],
                    // rootMargin: '-1px 0px 0px 0px',  // alternativly, use this and set `top:0` in the CSS
                }
            );

            observer.observe(cachedRef)

            // unmount
            return function () {
                observer.unobserve(cachedRef)
            }
        }
    }, []);
    return (
        <Navbar id='sticky-header' sticky="top"
                className={`d-flex align-items-center custom-navbar bg-1 sticky-1 ${isSticky ? 'navbar-img' : ''}`}
                style={{background: `${isSticky ? 'url(' + navBarImg + ') no-repeat var(--color-1) center/contain' : 'var(--color-1)'}`}}
                ref={navRef}>
            <div className='container h-100'>
                <div className="wrapper">
                    <input type="radio" name="toogle" id="menu-btn"/>
                    <input type="radio" name="toogle" id="cancel-btn"/>
                    <ul className="nav-links w-100">
                        <label htmlFor="cancel-btn" className="btn cancel-btn"><i className="fa fa-times"
                                                                                  aria-hidden="true"/></label>
                        {NAV_ROUTES.map(route => <RenderByType key={shortid()} route={route}/>)}
                        <li>
                            <div className='fg-white root-link' onClick={() => {
                                setState(true);
                            }}>
                                {/*<i className="fa fa-search"/>*/}
                            </div>
                        </li>
                    </ul>
                    <label htmlFor="menu-btn" className="btn menu-btn"><i className="fa fa-align-justify"
                                                                          aria-hidden="true"/></label>
                </div>
            </div>
        </Navbar>
    );
};

const RenderByType = ({route}: { route: SimpleLink | DropDown | MegaMenu }) => {
    const type = route.type;
    switch (type) {
        case LinkType.DROP_DOWN:
            return <RenderDropDown route={route as DropDown}/>;
        case LinkType.MEGA_MENU:
            return <RenderMegaMenu route={route as MegaMenu}/>
        case LinkType.SIMPLE_LINK:
            const simpleLink = route as SimpleLink;
            return <RenderSimpleLink route={simpleLink}/>
        default:
            return <li/>
    }
};
const RenderSimpleLink = ({route}: { route: SimpleLink }) => {
    return (
        <li>
            <Link to={getLink(route)} className={`${route.clazz != null ? route.clazz : ''}`}>{route.title}</Link>
        </li>
    );
};

const RenderDropDown = ({route}: { route: DropDown }) => {
    return (
        <li>
            {isLink(route) ?
                <Link className={`${route.clazz != null ? route.clazz : ''}`} to={getLink(route)}>{route.title}</Link> :
                <div><span className={`link-title ${route.clazz}`}>{route.title}</span></div>}
            <div className='arrow-up'/>
            <ul className="drop-menu">
                {route.links.map(item => <li key={shortid()}>
                    <Link to={concatLinks([route, item])}>{item.title}</Link>
                </li>)}
            </ul>
        </li>
    );
}
const RenderMegaMenu = ({route}: { route: MegaMenu }) => {
    return (<li>
        {isLink(route) ?
            <Link className={`${route.clazz != null ? route.clazz : ''}`} to={getLink(route)}>{route.title}</Link> :
            <div><span className={`link-title ${route.clazz != null ? route.clazz : ''}`}>{route.title}</span></div>}
        <div className='arrow-up'/>
        <div className="mega-box">
            <div className="content-mega-box" style={getMenuItem(route.megaItems.length)}>
                {route.megaItems.map(megaItem =>
                    <div key={shortid()} className='row-custom-navbar'>
                        <header>{isLink(megaItem) ?
                            <Link
                                to={concatLinks([route, megaItem])}>{megaItem.title}</Link> : <>{megaItem.title}</>}</header>
                        <ul className='mega-links'>
                            {megaItem.links.map(item => <li key={shortid()}>
                                <Link
                                    to={concatLinks([route, megaItem, item])}>{item.title}</Link>
                            </li>)}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    </li>);
}
export default CustomNavBar;

