import SectionTitle from "../../../../components/SectionTitle";
import {useEffect, useState} from "react";
import Search from "../../../../components/search/Search";
import SenatorCard from "../../../../components/senator-card/SenatorCard";
import shortid from "shortid";
import {URLS} from "../../../../utils/url";
import {HttpMethod} from "../../../../models/enums/HttpMethod";
import useFetchManyWithLoading from "../../../../hooks/useFetchManyWithLoading";
import {FILTERS} from "../../../../models/constants/Filters";
import {removeAccents} from "../../../../utils/Utils";

const Brigades = () => {
    const [selected, setSelected] = useState(-1);
    const [filter, setFilter] = useState<RegExp | null>(null);
    const [data, loadData] = useFetchManyWithLoading({
        requests: [{
            url: URLS.SENATORS,
            type: HttpMethod.GET,
            formatter: (response: any) => {
                response = response.sort((a: any, b: any) => a.nombre > b.nombre ? 1 : -1);
                const data: any = {};
                const brigades: string[] = [];
                response.forEach((item: any) => {
                    if (item.brigada_catalogo != null) {
                        if (data[item.brigada_catalogo.id] == null) {
                            data[item.brigada_catalogo.id] = [];
                            brigades.push(item.brigada_catalogo);
                        }
                        data[item.brigada_catalogo.id].push(item);
                        delete item.brigada;
                    }
                });
                return {
                    senators: data,
                    brigades: brigades.sort((a: any, b: any) => a.order < b.order ? -1 : 1)
                };
            },
            key: 'info'
        },
            {
                url: FILTERS.TITULAR_FILTER,
                type: HttpMethod.GET,
                formatter: (response: any) => {
                    if (response.value) {
                        return JSON.parse(response.value);
                    }
                    return null;
                },
                key: 'filters'
            }
        ],
        initial: null
    });

    useEffect(() => {
        (async () => await loadData())();
    }, []);
    if (data?.filters && selected === -1) {
        setSelected(data.filters[0]);
    }

    const searchHandler = (e: any) => {
        if (e.trim().length === 0) {
            setFilter(null);
        } else {
            setFilter(new RegExp(removeAccents(e), 'i'));
        }
    };

    const filterSenators = () => {
        setSelected(selected === data.filters[0] ? data.filters[1] : data.filters[0]);
    };

    const filterData = (item: any) => {
        return filter?.test(removeAccents(item.nombre)) || filter?.test(removeAccents(item.apellidos)) || filter?.test(removeAccents(item.bancada?.nombre));
    }

    return <>
        <div>
            <SectionTitle title='brigadas' varImg='bar-2'/>
            {data && data.filters && <div className='container'>
                <div className='row d-flex justify-content-center align-items-center'>
                    <div className='col-lg-4 col-sm-12'>
                        <button className='btn search-button'
                                onClick={filterSenators}>{selected === data.filters[0] ? 'Ver supletentes' : 'Ver titulares'}</button>
                    </div>
                    <div className='col-lg-8 col-sm-12'>
                        <Search handler={searchHandler}/>
                    </div>
                </div>
                {data && data.info?.brigades.map((brigade: any) => <Brigade key={shortid()} brigade={brigade}
                                                                            data={data.info?.senators[brigade.id].filter((item: any) => selected === item.es_titular
                                                                            && (filter ? (
                                                                                    filterData(item)
                                                                                ) : true)
                                                                            )}/>
                )}
            </div>}
        </div>
    </>
};

const Brigade = ({brigade, data}: { brigade: any, data: any }) => {
    return (
        <>
            {data.length > 0 && <div className='row'>
                <h3>{brigade.name}</h3>
                {data.map((item: any) =>
                    <div key={shortid()} className='col-xl-3 col-lg-4 col-md-6 col-sm-12'>
                        <SenatorCard fullName={item.nombre + ' ' + item.apellidos}
                                     alt={item.nombre + ' ' + item.apellidos}
                                     src={item.foto_perfil}
                                     benchName={item.bancada.nombre} brigade={''} id={item.id}
                                     back={'../../'}/>
                    </div>
                )}
            </div>}
        </>
    );
};
export default Brigades;
