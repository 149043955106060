import diputados from '../assets/img/footer/Logo-diputados.png';
import whiteLogo from '../assets/img/white-building.svg';
import vicePresident from '../assets/img/footer/logo-vicepresidencia.png';
import footerBottom from '../assets/img/detalles-andinos.png';
import React, {useEffect} from "react";
import {formatDate, titleToLink} from "../utils/Utils";
import {TITLES} from "../models/constants/ConstantsUI";
import {Link} from "react-router-dom";
import './footer.css';
import useFetchWithLoading from "../hooks/useFetchWithLoading";
import {HttpMethod} from "../models/enums/HttpMethod";
import {URLS} from "../utils/url";
import {DATE_FORMAT3} from "../models/constants/Constants";

export const Footer = () => {
    const [data, loadData] = useFetchWithLoading({
        request: {
            url: URLS.LAST_CHANGE,
            type: HttpMethod.GET
        },
        loading: false,
        initial: null
    });
    useEffect(() => {
        (async () => await loadData())();
    }, []);
    return (<footer className="container-fluid position-relative footer-bg footer">
        <div className='row px-2'>
            <div className="col-lg-3 col-md-5 d-flex align-items-end justify-content-center">
                <div className='d-flex flex-column align-items-center'>
                    <div className='p-3'>
                        <Link to={`${titleToLink(TITLES.CONTACTS_LINK)}`}
                              className='btn text-uppercase font-1 fg-white bg-5'>{TITLES.CONTACTS_2}</Link>
                    </div>
                    <div className='fg-white text-center font-2'>
                        <p>
                            Calle Comercio esquina Colón
                        </p>
                        <p>
                            Telf.: (591) 2158701
                        </p>
                        <p className='fg-6 font-4'>
                            Fecha ultima actualización: {formatDate(data?.date_time, DATE_FORMAT3)}
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-7 d-flex align-items-end justify-content-center">
                <div className='d-flex flex-column align-items-center'>
                    <img src={whiteLogo} className='img-fluid' alt='logo senado'/>
                    <div className='fg-2 text-center mb-3'>
                        <a rel='noopener noreferrer' target='_blank'
                           href='https://www.senado.gob.bo' className='fg-2'>www.senado.gob.bo</a> -
                        La Paz - Bolivia
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-sm-12 d-flex align-items-end justify-content-center">
                <div className='d-flex flex-column'>
                    <h3 className='title-1 fg-white text-uppercase text-center'>Enlaces de Interes</h3>
                    <div className='d-flex justify-content-center align-items-center mb-3 flex-column'>
                        <img className='img-fluid w-25 mx-2' src={vicePresident} alt='logo vice-presidencia'/>
                        <a rel='noopener noreferrer' className='link-footer' target='_blank'
                           href='https://www.vicepresidencia.gob.bo'>https://www.vicepresidencia.gob.bo</a>
                    </div>
                    <div className='d-flex justify-content-center flex-column align-items-center mb-3'>
                        <img className='img-fluid w-25 mx-2' src={diputados} alt='logo diputados'/>
                        <a rel='noopener noreferrer' className='link-footer' target='_blank'
                           href='https://diputados.gob.bo'>https://diputados.gob.bo</a>
                    </div>
                </div>
            </div>
        </div>
        <div className='footer-line'/>
        <div className='footer-bottom d-flex justify-content-center py-3'>
            <img src={footerBottom} className='img-fluid' alt='line border footer'/>
        </div>
    </footer>);
}
