export const estadoLey = {
    ley_tratamiento: 'Proyectos de Ley en Tratamiento',
    ley_aprobados: 'Proyectos de Ley en Aprobados',
    ley_sancionadas: 'Leyes Sancionadas',
    ley_promulgados: 'Leyes Promulgadas',
    ley_rechazadas: 'Proyectos de Ley Rechazadas',
}
export const statusCode = {
    code_422: 422,

}
