import SectionTitle from "../../components/SectionTitle";
import first from '../../assets/img/ui/1.png';
import second from '../../assets/img/ui/2.jpg';
import third from '../../assets/img/ui/3.jpg';
import fourth from '../../assets/img/ui/4.jpg';

const Institutional = () => {
    return (
        <>
            <SectionTitle title='LA ASAMBLEA LEGISLATIVA PLURINACIONAL' varImg='bar-2' />
            <div className='container mt-5'>
                <div className='row p-3 mx-1 fg-1'>
                    <div className='col-lg-20'>
                        <h3>¿Qué es la Asamblea Legislativa Plurinacional?</h3>
                        <p>Es uno de los cuatro Órganos del Estado, donde se reúnen 
                        Los representantes de los nueve departamentos y es él
                            único Órgano con facultad de aprobar y sancionar leyes
                            que rigen para todo el territorio boliviano.</p>

                       

                            <div className='col-sm-12 mt-5'></div>
                        <h3>¿Qué se hace en esta institución?</h3>
                        <p>Principalmente, dictar leyes para todo el Estado Plurinacional y además controla y fiscaliza
                            a los Órganos del Estado
                            y las instituciones públicas.</p> 
                            
            
                    </div>
                    <div className='col-sm-12 mt-5'>
                        <h3>¿En cuantas Cámaras se divide la Asamblea Legislativa Plurinacional?</h3>
                        <p>Se divide en dos: Cámara de Senadores y Cámara de Diputados:</p>
                    </div>

                    <div className='col-lg-6 col-sm-12 my-2 d-flex align-items-center'>
                        <img src={second} className='img-fluid' />
                    </div>
                    <div className='col-lg-6 col-sm-12 my-2 d-flex align-items-center'>
                        <img src={third} className='img-fluid' />
                    </div>
                    <div className='col-sm-12 py-5'>
                        <h3> ¿Cuántas Senadoras y Senadores hay ? </h3>
                        <p>La Constitución Política del Estado establece que deben
                            haber (36) treinta y seis Senadoras/es, es decir (4) por
                            cada departamento.</p>
                            <div className='col-sm-12 mt-5'></div>
                        <h3> ¿Cuáles son las actividades más importantes que se realizan? </h3>
                        <p>Legislación. Dictar, modificar, interpretar, derogar, abrogar leyes para el Estado.</p>
                        <p>Fiscalización. Controlar y fiscalizar a los diferentes Órganos del Estado y entidades
                            públicas
                            con el propósito de investigar y transparentar el ejercicio de la función pública.</p>
                        <p>Gestion. Cumplimiento refereido a las actividades que realiza la Cámara de Senadores en
                            ejercicio de sus competencias y atribuciones propias.</p>
                        
                            <div className='col-sm-12 mt-5'></div>
                        <h3> Cámara de Senadores </h3>
                        <p>
                            Bolivia se constituye en un Estado Unitario, Social de Derecho Plurinacional Comunitario,
                            Libre,
                            Independiente, Soberano Democrático, Descentralizado y con Autonomías.
                            La Cámara de Senadores forma parte de la Asamblea Legislativa Plurinacional cuyas atribucio-
                            nes
                            de manera general y específica, se encuentran establecidas en los Art. 158 y 160 de la Cons-
                            titución Política del Estado, respectivamente.
                        </p>
                    </div>

                    <div className='col-sm-12 mb-5'>
                        <img src={fourth} className='img-fluid' />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Institutional;