import {IRequest} from "../../../models/interfaces/IRequest";
import {HttpMethod} from "../../../models/enums/HttpMethod";
import {URLS} from "../../../utils/url";
import {PER_PAGE} from "../../../models/constants/Constants";
import SectionTitle from "../../../components/SectionTitle";
import {TITLES} from "../../../models/constants/ConstantsUI";
import BasicTable from "../../../components/tables/BasicTable";
import * as React from "react";
import {FieldType, IBasicTable} from "../../../models/interfaces/IBasicTable";
import {removeTags} from "../../../utils/Utils";
import ImportantAdvices from "../../important-advices/ImportantAdvices";
import {CATALOG} from "../../../models/constants/Catalog";
import SourceTable from "../../../components/tables/SourceTable";


const FIELDS: IBasicTable[] = [
    {
        label: 'Detalle',
        field: 'titulo',
        type: FieldType.DIRECT,
        styles: {width: '300px'},
    },
    {
        label: 'Descripción',
        field: 'descripcion',
        type: FieldType.DIRECT,
        formatter: removeTags,
    },
    {
        label: 'Gestión',
        field: 'gestion',
        type: FieldType.DIRECT,
        styles: {width: '100px'}
    },
    {
        label: 'Estado',
        field: 'estado',
        styles: {width: '100px'},
        type: FieldType.LIST,
        source: {
            name: 'statuses',
            key: 'id',
            labels: 'name',
        }
    },
    {
        label: 'Archivo',
        field: 'archivo',
        type: FieldType.FILE,
        styles: {width: '120px'}
    }
];
const request: IRequest = {
    type: HttpMethod.GET,
    url: URLS.DEPENDENT_PERSONNEL,
    parameters: {
        per_page: PER_PAGE
    }
};
const sources: IRequest[] = [
    {
        type: HttpMethod.GET,
        url: CATALOG.STATUSES,
        key: 'statuses',
    }
];
/**
 * Nomina de personal dependiente
 * */
const DependentPersonnel = () => {

    return (
        <>
            <SectionTitle title={TITLES.DEPENDENT_PERSONNEL} varImg={'bar-1'}/>
            <SourceTable sourceRequest={sources} request={request} fields={FIELDS}/>
            <ImportantAdvices />
        </>
    );
};
export default DependentPersonnel;
