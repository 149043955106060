import SectionTitle from "../../components/SectionTitle";
import shortid from "shortid";
import {IGenericDescription} from "../../models/interfaces/IGenericDescription";

const GenericDescription = ({
                                title,
                                varImg,
                                description,
                                subtitle,
                                childs
                            }: IGenericDescription) => {
    return (
        <>
            <SectionTitle title={title} varImg={varImg}/>
            <div className='container'>
                <h3 className='title-2 mt-5'>{subtitle}</h3>
                {description && description.map((desc: string, index: number) => <p key={shortid()}
                                                                     className={`description-1 ${index === 0 ? 'mt-3' : ''}`}>
                    {desc}
                </p>)}
                {childs && childs.map(child => child)}
            </div>
        </>
    );
};
export default GenericDescription;