import SectionTitle from "../../components/SectionTitle";
import {TITLES} from "../../models/constants/ConstantsUI";
import president from '../../assets/img/ui/president.jpg';
import CustomImage from "../../components/CustomImage";
import PresidenciaActividades from "./PresidenciaActividades";

const Presidency = () => {
    return (
        <>
            <SectionTitle title={TITLES.PRESIDENCY}/>
            <div className='container my-5 mx-auto'>
                <div className='mx-5'>
                    <h3 className='title-section-2 fg-2'>PRESENTACIÓN</h3>
                    <div className='row'>
                        <div className='col-xxl-8 col-sm-12 fs-1'>
                            <p className='description-1'>
                            Andrónico Rodríguez Ledezma es un destacado dirigente y politólogo boliviano, nacido el 11 de noviembre de 1988 en Sacaba, Cochabamba. Su carrera sindical comenzó en las organizaciones juveniles del movimiento cocalero y, posteriormente, ocupó el cargo de Secretario de Actas en la Central “Primera Manco Kapac B”. Esa trayectoria lo habilitó para asumir el cargo de Secretario Ejecutivo de la Federación Mamoré Bulo Bulo, consolidando un liderazgo orgánico en el ámbito sindical. Así, en octubre de 2018, fue electo Vicepresidente de la Coordinadora de las Seis Federaciones del Trópico de Cochabamba, siendo ratificado por unanimidad en los XV y XVI congresos, desarrollados en septiembre de 2021y noviembre de 2023.
Es graduado en Ciencias Políticas por la Universidad Mayor de San Simón (UMSS), también cuenta con una Maestría en Seguridad, Defensa y Desarrollo por la Escuela de Altos Estudios Nacionales (EAEN), y diplomados, en Gestión Política y Democracia Intercultural por la Universidad Mayor de San Simón – Centro de Estudios Superiores Universitarios (CESU-UMSS), y en Educación Superior por la Universidad Pública de El Alto (UPEA). Consciente de que la formación académica no garantiza la solidez ideológica y política, mantiene y refuerza sus raíces sindicales puesto que su liderazgo se sustenta en una visión que privilegia lo orgánico; rasgo central del movimiento campesino indígena y popular que se expresa en el MAS-IPSP como Instrumento Político. Su apelación a que “hay que hacer que el poder vuelva a manos del pueblo”, no solo refleja su profunda vocación democrática sino la necesidad de que la sociedad asuma un rol protagónico en la perspectiva de consolidar el Estado Plurinacional. 
En 2020, fue elegido Senador por Cochabamba y asumió la Presidencia del Senado, siendo ratificado en cinco ocasiones con amplio apoyo. Coherente con su capacidad conciliadora, encausó su accionar legislativo en el marco de las reglas de la democracia, promoviendo el diálogo para superar los desencuentros políticos y sociales. 
A los 35 años, Andrónico ha logrado consolidarse como una figura prominente en la política boliviana, ganándose el respeto y el afecto de la sociedad, sobre todo de los sectores campesinos indígenas y la juventud comprometida con lo nacional popular. Su influencia se extiende más allá de los sectores tradicionales del MAS-IPSP y se perfila como un referente de la nueva generación política aportando una renovada capacidad intelectual y moral que resulta fundamental para avanzar en el reencuentro y reconciliación entre bolivianas y bolivianos.

                                </p>
                            <p className='description-1'>
                                
                            </p>
                        </div>
                        <div className='col-xxl-4 col-sm-12'>
                            <CustomImage className='img-fluid' src={president} />
                        </div>
                    </div>
                </div>
            </div>

            <PresidenciaActividades />
        </>
    );
};
export default Presidency;