import ImportantAdvices from "../../important-advices/ImportantAdvices";
import {FieldType, IBasicTable} from "../../../models/interfaces/IBasicTable";
import {removeTags} from "../../../utils/Utils";
import {IRequest} from "../../../models/interfaces/IRequest";
import {HttpMethod} from "../../../models/enums/HttpMethod";
import {URLS} from "../../../utils/url";
import {PER_PAGE} from "../../../models/constants/Constants";
import SectionTitle from "../../../components/SectionTitle";
import {TITLES} from "../../../models/constants/ConstantsUI";
import {ISearcher, ISearcherType} from "../../../models/interfaces/ISearcher";
import SourceTable from "../../../components/tables/SourceTable";
import {SOURCES} from "../../../utils/Sources";

const FIELDS: IBasicTable[] = [
    {
        label: 'Título',
        field: 'titulo',
        type: FieldType.DIRECT,
        styles: {width: '200px'},
        link: {
            field: 'id',
        }
    },
    {
        label: 'Proyectistas',
        field: 'proyectista_senador_id',
        type: FieldType.LIST,
        source: {
            name: 'senators',
            key: 'id',
            labels: ['nombre', 'apellidos'],
        }
    },
    {
        label: 'Asunto',
        field: 'asunto',
        type: FieldType.DIRECT,
        formatter: removeTags,
    },
    {
        label: 'Gestión',
        field: 'gestion',
        type: FieldType.DIRECT,
        styles: {width: '200px'},
    },
    {
        label: 'Archivo PDF',
        field: 'doc_archivo',
        type: FieldType.FILE,
        styles: {width: '120px'}
    }
];

const searches: ISearcher [] = [
    {
        type: ISearcherType.TEXT,
        label: 'Título',
        field: 'titulo',
        clazz: 'col-6'
    },
    {
        type: ISearcherType.LIST,
        label: 'Proyectista',
        field: 'proyectista_senador_id',
        clazz: 'col-6',
        source: {
            name: 'senators',
            key: 'id',
            labels: ['nombre', 'apellidos'],
        }
    },
    {
        type: ISearcherType.TEXT,
        label: 'Asunto',
        field: 'asunto',
        clazz: 'col-6'
    },
    {
        type: ISearcherType.TEXT,
        label: 'Gestión',
        field: 'gestion',
        clazz: 'col-6'
    }
];
const sources: IRequest[] = [
    {
        type: HttpMethod.GET,
        url: SOURCES.SENATORS_SELECT,
        key: 'senators',
    }
];
const request: IRequest = {
    type: HttpMethod.GET,
    url: URLS.CHAMBER_DECLARATIONS,
    parameters: {
        per_page: PER_PAGE
    }
};
export const ChamberDeclarations = () => {

    return (
        <>
            <SectionTitle title={TITLES.CHAMBER_DECLARATIONS} varImg={'bar-1'}/>
            <SourceTable fields={FIELDS} request={request} sourceRequest={sources} searches={searches}/>
            <ImportantAdvices/>
        </>
    );
};
export default ChamberDeclarations;
