import SectionTitle from "../../components/SectionTitle";
import {TITLES} from "../../models/constants/ConstantsUI";
import {useEffect} from "react";
import {URLS} from "../../utils/url";
import shortid from "shortid";
import {utils} from "../../utils/Utils";
import useFetchManyWithLoading from "../../hooks/useFetchManyWithLoading";
import {HttpMethod} from "../../models/enums/HttpMethod";
import Carousel from "react-multi-carousel";
import MediaLink from "../../components/MediaLink";
import shortId from "shortid";

const buttons = [
    {
        mediaIconClass: 'fab fa-facebook-f',
        url: 'https://www.facebook.com/AndronicoRodriguezL',
        extraClazz: 'bg-10 fg-3',
    },
    {
        mediaIconClass: 'fab fa-youtube',
        url: 'https://www.youtube.com/channel/UCLPWAY7_UfAmQu1FbJhBh_Q/featured',
        extraClazz: 'bg-10 fg-3',
    },
    {
        mediaIconClass: 'fab fa-twitter',
        url: 'https://x.com/senadobolivia',
        extraClazz: 'bg-10 fg-3',
    },
    {
        mediaIconClass: 'fab fa-instagram',
        url: 'https://www.instagram.com/senadobolivia?igsh=YW4ybzc3YTRjZWE2',
        extraClazz: 'bg-10 fg-3',
    },
    {
        mediaIconClass: 'fab fa-tiktok',
        url: 'https://www.tiktok.com/@andronico_rodriguez',
        extraClazz: 'bg-10 fg-3',
    }
];
const PresidenciaActividades = () => {
    const formatter = (response: any) => {
        const data: any[] = [];
        if (response.data && response.data.length > 0) {
            response.data.forEach((elem: any) => {
                data.push({
                    alt: elem.titulo,
                    url: elem.imagenes.length > 0 ? utils(elem.imagenes[0].url) : null,
                    titulo: elem.titulo,
                });
            });
        }
        return data;
    }
    const [data, loadActivities] = useFetchManyWithLoading({
        requests: [
            {
                url: URLS.PRESIDENCY,
                key: 'activities',
                type: HttpMethod.GET,
                formatter
            }
        ],
        initial: {}
    })

    useEffect(() => {
        (async () => await loadActivities())();
    }, []);
    return (
        <>
            <SectionTitle title={TITLES.ACTIVITIES}/>
            <div className='bg-2 w-100 p-5 mt-5'>
                <div className='container'>

                    {data && data.activities && data.activities.length > 0 && <Carousel
                        additionalTransfrom={0}
                        arrows
                        autoPlaySpeed={3000}
                        centerMode={false}
                        className=""
                        containerClass="container"
                        dotListClass=""
                        draggable
                        focusOnSelect={false}
                        infinite={false}
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        pauseOnHover
                        renderArrowsWhenDisabled={false}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={{
                            desktop: {
                                breakpoint: {
                                    max: 3000,
                                    min: 1024
                                },
                                items: 3,
                                partialVisibilityGutter: 40
                            },
                            mobile: {
                                breakpoint: {
                                    max: 464,
                                    min: 0
                                },
                                items: 1,
                                partialVisibilityGutter: 30
                            },
                            tablet: {
                                breakpoint: {
                                    max: 1024,
                                    min: 464
                                },
                                items: 2,
                                partialVisibilityGutter: 30
                            }
                        }}
                        rewind={false}
                        rewindWithAnimation={false}
                        rtl={false}
                        shouldResetAutoplay
                        showDots={false}
                        sliderClass=""
                        slidesToSlide={1}
                        swipeable
                    >
                        {data.activities.map((activity: any) =>
                            <div className='mx-2 d-flex flex-column' key={shortid()}>
                                <img alt={activity.alt} src={activity.url} className='img-fluid'/>
                                <div className='text-center title-1 fg-white'>{activity.titulo}</div>
                            </div>
                        )}
                    </Carousel>}
                </div>
            </div>
            <div className='d-flex justify-content-center my-3'>
                {buttons.map(button => <div key={shortId()} className='pb-1'><MediaLink {...button} /></div>)}
            </div>
        </>
    );
};
export default PresidenciaActividades;
