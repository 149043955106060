import {ISource} from "./IBasicTable";

export interface ISearcher{
    type: ISearcherType,
    label: string,
    field: string,
    fieldType?: string,
    clazz?: string,
    source?: ISource
}

export enum ISearcherType{
    TEXT, LIST, DATE
}
export enum TypeDate{
    START, END
}