import SectionTitle from "../../components/SectionTitle";
import {TITLES} from "../../models/constants/ConstantsUI";
import useFetchWithLoading from "../../hooks/useFetchWithLoading";
import {URLS} from "../../utils/url";
import {HttpMethod} from "../../models/enums/HttpMethod";
import {useEffect} from "react";
import shortid from "shortid";
import {utils} from "../../utils/Utils";

const Advices = () => {
    const [data, loadData] = useFetchWithLoading({
        request: {
            url: URLS.VIGENT_ADVICES,
            type: HttpMethod.GET,
            formatter: (response: any[]) => {
                const advices: any[] = [];
                const ids: any[] = [];
                response.forEach(a => {
                    if (!ids.includes(a.categoria_id.id)) {
                        ids.push(a.categoria_id.id);
                        advices.push(a.categoria_id);
                    }
                });
                return {
                    advices,
                    info: response
                };
            }
        },
        initial: null
    });
    useEffect(() => {
        (async () => await loadData())();
    }, []);
    return (
        <>
            <SectionTitle title={TITLES.ADVICES}/>
            {data && data.advices.map((advice: any) =>
                <div className='my-5' key={shortid()}>
                    <h3 className='title-1 text-center my-5'>{advice.name}</h3>
                    <div className='bg-2'>
                        <div className='row w-90 mx-auto py-5'>
                            {data.info.filter((row: any) => row.categoria_id.id === advice.id).map((row: any) =>
                                <div className='col-sm-3' key={shortid()}>
                                    <img className='img-fluid' src={utils(row.archivo_1)}/>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default Advices;