import { types } from '../types/types';
export const initialStateSubMenu = {
    subMenuActive: '',
};
export const subMenuReducer = ( state = initialStateSubMenu, action: any ) => {
    switch ( action.type ) {
        case types.setSubMenu:
            return {
                ...state,
                subMenuActive: action.payload
            }
        default:
            return state;
    }
}
