import SectionTitle from "../../components/SectionTitle";
import {TITLES} from "../../models/constants/ConstantsUI";
import useFetchWithLoading from "../../hooks/useFetchWithLoading";
import {HttpMethod} from "../../models/enums/HttpMethod";
import {URLS} from "../../utils/url";
import {useEffect} from "react";
import shortid from "shortid";
import {utils, datetimeFormat, reduceString, removeTags} from "../../utils/Utils";
import ReactPaginate from "react-paginate";
import {PER_PAGE} from "../../models/constants/Constants";
import './gallery.css';
import {Link} from "react-router-dom";

const initial = {
    per_page: PER_PAGE
};

const Gallery = () => {
    const [list, loadData, pagination] = useFetchWithLoading({
        request: {
            url: URLS.IMAGES_GALLERY,
            type: HttpMethod.GET,
            parameters: initial
        },
        initial: {}
    });

    useEffect(() => {
        loadData()
    }, []);

    const handlePageClick = async (offset: any) => {
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
        const {selected}: { selected: number } = offset;
        const params = {
            page: selected + 1,
            ...initial
        }
        await loadData(params);
    }
    return (
        <>
            <SectionTitle title={TITLES.IMAGES_GALLERY}/>
            <div className='container my-5'>
                {list && list.data && list.data.map((row: any, index: number) =>
                    <Link to={`${row.id}`} key={shortid()}>
                        <div className='row mt-3 row-border ptlb'>
                            <div className='col-4 p-0'>
                                <img src={utils(row.imagenes[0].archivo)} className='img-fluid'/>
                            </div>
                            <div className='col-8 p-0'>
                                <h3 className='m-0 title-1'>
                                    <div className='bg-13 h-100 d-inline-block p-3'>{datetimeFormat(row.fecha)}</div>
                                </h3>
                                <h3 className={`${index % 2 === 0 ? 'bg-1' : 'bg-2'} fg-white text-center title-1 py-3 px-2`}>{row.titulo}</h3>
                                <div className='p-3 fg-1'>{reduceString(removeTags(row.cuerpo), 500)}</div>
                            </div>
                        </div>
                    </Link>
                )}
                {pagination.pages > 1 ?
                    <div className='d-flex justify-content-center mt-3'>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel='Siguiente'
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pagination.pages}
                            previousLabel='Anterior'
                            containerClassName='pagination'
                            previousClassName='previous-next'
                            nextClassName='previous-next'
                            pageClassName='page-item'
                            activeClassName='page-selected'
                            disabledClassName='disable-button'
                        />
                    </div> : null}
            </div>
        </>
    );
};
export default Gallery;