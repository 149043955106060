import {MegaMenu} from "../models/interfaces/NavConfig";
import LegislativeArea from "../ui/legislative-area/LegislativeArea";
import Brigades from "../ui/legislative-area/legislative-organization/brigades/Brigades";
import SpecialCommissions from "../ui/legislative-area/legislative-organization/special-commissions/SpecialCommissions";
import {PermanentCommissions} from "../ui/legislative-area/legislative-organization/PermanentCommissions";
import {Directiva} from "../ui/legislativa/organizacion/Directiva";
import GenericDescription from "../ui/generic-description/GenericDescription";
import {FISCALIZATION} from "../mocks/StaticMocks";
import ChamberDeclarations from "../ui/legislative-area/gestion/ChamberDeclarations";
import CommunicationMinutes from "../ui/legislative-area/gestion/CommunicationMinutes";
import ChamberResolutions from "../ui/legislative-area/gestion/ChamberResolutions";
import Gestion from "../ui/legislative-area/gestion/Gestion";
import RequestWrittenReport from "../ui/legislative-area/fiscalization/RequestWrittenReport";
import RequestOralReport from "../ui/legislative-area/fiscalization/RequestOralReport";
import GeneralSecretary from "../ui/legislative-area/general-secretary/GeneralSecretary";
import PlenarySession from "../ui/legislative-area/legislative-organization/PlenarySession";
import TreatmentLaws from "../ui/legislative-area/legislation/TreatmentLaws";
import ApprovedLaws from "../ui/legislative-area/legislation/ApprovedLaws";
import SanctionedLaws from "../ui/legislative-area/legislation/SanctionedLaws";
import PromulgatedLaws from "../ui/legislative-area/legislation/PromulgatedLaws";
import ReturnedLaws from "../ui/legislative-area/legislation/ReturnedLaws";
import RejectedLaws from "../ui/legislative-area/legislation/RejectedLaws";
import {buildMegaMenu} from "../utils/Utils";
import {TITLES} from "../models/constants/ConstantsUI";
import Sessions from "../ui/legislative-area/general-secretary/Sessions";
import Bancada from "../ui/legislative-area/legislative-organization/Bancada";

export const LegislativeRoutes: MegaMenu =
    buildMegaMenu({
        title: TITLES.LEGISLATIVE_AREA,
        // component: <LegislativeArea/>,
        linkeable: true,
        clazz: 'root-link',
        megaItems: [
            {
                title: TITLES.LEGISLATIVE_ORGANIZATION,
                link: true,
                items: [
                    {
                        title: TITLES.PLENARY_SESSION,
                        component: <PlenarySession/>
                    },
                    {
                        title: TITLES.DIRECTIVE,
                        component: <Directiva/>
                    },
                    {
                        title: TITLES.PERMANENT_COMMISSIONS,
                        component: <PermanentCommissions/>
                    },
                    {
                        title: TITLES.SPECIAL_COMMISSIONS,
                        component: <SpecialCommissions/>
                    },
                    {
                        title: TITLES.BRIGADES,
                        component: <Brigades/>
                    },
                    {
                        title: TITLES.BENCHS,
                        component: <Bancada/>
                    },
                ]
            },
            {
                title: TITLES.LEGISLATION,
                link: true,
                items: [
                    {
                        title: TITLES.TREATMENT_LAW,
                        component: <TreatmentLaws/>
                    },
                    {
                        title: TITLES.APPROVED_LAW,
                        component: <ApprovedLaws/>
                    },
                    {
                        title: TITLES.SANCTIONED_LAW,
                        component: <SanctionedLaws/>
                    },
                    {
                        title: TITLES.RETURNED_LAW,
                        component: <ReturnedLaws/>
                    },
                    {
                        title: TITLES.PROMULGATED_LAW,
                        component: <PromulgatedLaws/>
                    },
                    {
                        title: TITLES.REJECTED_LAW,
                        component: <RejectedLaws/>
                    },
                ]
            },
            {
                title: TITLES.FISCALIZATION,
                link: true,
                component: <GenericDescription title={FISCALIZATION.title} subtitle={FISCALIZATION.subtitle}
                                               varImg={FISCALIZATION.imgUrl}
                                               description={FISCALIZATION.description}/>,
                items: [
                    {
                        title: TITLES.REQUEST_WRITTEN_REPORT,
                        component: <RequestWrittenReport/>
                    },
                    {
                        title: TITLES.REQUEST_ORAL_REPORT,
                        component: <RequestOralReport/>
                    },
                ]
            },
            {
                title: TITLES.GESTION,
                link: true,
                component: <Gestion/>,
                items: [
                    {
                        title: TITLES.CHAMBER_RESOLUTIONS,
                        component: <ChamberResolutions/>
                    },
                    {
                        title: TITLES.CHAMBER_DECLARATIONS,
                        component: <ChamberDeclarations/>
                    },
                    {
                        title: TITLES.COMMUNICATION_MINUTES,
                        component: <CommunicationMinutes/>
                    },
                ]
            },
            {
                title: TITLES.GENERAL_SECRETARY,
                link: true,
                component: <GeneralSecretary/>,
                items: [
                    {
                        title: TITLES.SESSIONS,
                        component: <Sessions/>
                    },
                ]
            }
        ]
    });
