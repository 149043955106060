import {environment} from "./environment";
import moment from "moment/moment";
import {DropDown, LinkType, MegaItem, MegaMenu} from "../models/interfaces/NavConfig";
import {MAX_LENGTH_TEXT_TABLE} from "../models/constants/Constants";
import * as accents from 'remove-accents';

const removeAccentsFunction = accents as any;
export const utils = (fileUrl: string) => {
    return /https?/.test(fileUrl) ? fileUrl : (environment.apiUrlImage + '/' + fileUrl).replace('/storage', '');
}

export const publicFolder = (path: string) => {
    return process.env.PUBLIC_URL + path;
}

export function reduceString(text: string, maxChars: number): string {
    if (text && text.length > maxChars) {
        return text.substr(0, maxChars) + '...';
    }
    return text;
}

export function removeTags(text: string, table = true): string {
    let newText = text != null ? text.replace(/(<([^>]+)>)/ig, '') : '';
    if (table) {
        return reduceString(newText.replace(/&nbsp;/g, ''), MAX_LENGTH_TEXT_TABLE);
    }
    return newText.replace(/&nbsp;/g, '');
}

export function renderNew(path: string): boolean {
    return /\/home|\/$|legislacions/.test(path);
}

export function formatDate(date: string, format: string) {
    if (date == null) {
        return '';
    }
    return moment(date).format(format);
}

export function titleToLink(title: string): string {
    let newTitle = title.replace(/\s/g, '-').toLowerCase();
    return removeAccents(newTitle);
}

export function buildMegaItems(menu: any, items: MegaLinkParam[]) {
    const list: any[] = [];
    items.forEach(item => {
        list.push({
            title: item.title,
            component: item.component,
            link: titleToLink(item.title)
        })
    });
    menu.items = list;
    return menu;
}

interface MegaMenuParam {
    title: string,
    component?: JSX.Element,
    clazz?: string,
    megaItems: MegaItemParam[],
    linkeable?: boolean,
}

interface MegaItemParam {
    title: string,
    component?: JSX.Element,
    items: MegaLinkParam[],
    link?: boolean
}

interface MegaLinkParam {
    title: string,
    component: JSX.Element
}

interface DropDownMenuParam {
    title: string,
    link: boolean,
    component?: JSX.Element,
    links: DropDownLinkParam[],
    clazz?: string,
}

interface DropDownLinkParam {
    title: string,
    component: JSX.Element
}

export function buildMegaMenu(menu: MegaMenuParam) {
    const megaItems: MegaItem[] = [];
    menu.megaItems.forEach(megaItem => {
        const mega: MegaItem = {
            title: megaItem.title,
            component: megaItem.component,
            link: megaItem.link === true ? titleToLink(megaItem.title) : null,
            links: []
        };
        megaItem.items.forEach(item => {
            mega.links.push({
                title: item.title,
                component: item.component,
                link: titleToLink(item.title),
            });
        });
        megaItems.push(mega);
    });
    const builded: MegaMenu = {
        title: menu.title,
        link: titleToLink(menu.title),
        component: menu.component,
        type: LinkType.MEGA_MENU,
        clazz: menu.clazz,
        megaItems: megaItems,
        linkeable: menu.linkeable
    };
    return builded;
}

export function buildDropDown(menu: DropDownMenuParam): DropDown {
    const builded: DropDown = {
        title: menu.title,
        component: menu.component,
        link: titleToLink(menu.title),
        type: LinkType.DROP_DOWN,
        clazz: menu.clazz,
        links: []
    };
    const title = menu.component ? '' : builded.link + '/';
    menu.links.forEach(item => {
        builded.links.push({
            component: item.component,
            title: item.title,
            link: title + titleToLink(item.title)
        });
    });
    return builded;
}

export const getObjectProperty = (object: any, path: string) => {
    if (object == null) { // null or undefined
        return object;
    }
    const parts = path.split('.');
    return parts.reduce((object, key) => object?.[key], object);
};

export function dateFormat(value: any) {
    return value != null ? moment(value, 'YYYY-MM-DD', 'es').format('dddd, DD [de] MMMM') : '';
}

export function datetimeFormat(value: any) {
    return value != null ? moment(value, 'YYYY-MM-DD HH:mm:ss', 'es').format('dddd, MMMM DD, YYYY - HH:mm') : '';
}

function dateString(value: any) {
    return value != null ? moment(value).format('yyyy-MM-DD') : '';
}

export function dateRangeToString(range: any) {
    if (range == null) {
        return '';
    } else {
        let {startDate, endDate} = range;
        if (Array.isArray(range)) {
            [startDate, endDate] = range;
        }
        return dateString(startDate) + ' ~ ' + dateString(endDate);
    }
}

export function removeAccents(text?: string) {
    if (text) {
        return removeAccentsFunction(text);
    }
    return '';
}
