import {IRequest} from "../../../models/interfaces/IRequest";
import {HttpMethod} from "../../../models/enums/HttpMethod";
import {URLS} from "../../../utils/url";
import {PER_PAGE} from "../../../models/constants/Constants";
import SectionTitle from "../../../components/SectionTitle";
import {TITLES} from "../../../models/constants/ConstantsUI";
import BasicTable from "../../../components/tables/BasicTable";
import ImportantAdvices from "../../important-advices/ImportantAdvices";
import {FieldType, IBasicTable} from "../../../models/interfaces/IBasicTable";
import {datetimeFormat} from "../../../utils/Utils";

const FIELDS: IBasicTable[] = [
    {
        label: 'Número de Sesión',
        field: 'numero_sesion',
        type: FieldType.DIRECT,
    },
    {
        label: 'Fecha y Hora',
        field: 'fecha_hora',
        type: FieldType.DIRECT,
        formatter: datetimeFormat,
        styles: {width: '30%'},
    },
    {
        label: 'Archivo PDF',
        field: 'archivo',
        type: FieldType.FILE,
        styles: {width: '120px'}
    }
];
const Sessions = () => {
    const request: IRequest = {
        type: HttpMethod.GET,
        url: URLS.SESSIONS,
        parameters: {
            per_page: PER_PAGE
        }
    };

    return (
        <>
            <SectionTitle title={TITLES.SESSIONS} varImg={'bar-1'} />
            <BasicTable request={request} fields={FIELDS}/>
            <ImportantAdvices/>
        </>
    );
};
export default Sessions;