import {useEffect, useState} from "react";
import SectionTitle from "../../../components/SectionTitle";
import Search from "../../../components/search/Search";
import SenatorCard from "../../../components/senator-card/SenatorCard";
import shortid from "shortid";
import {URLS} from "../../../utils/url";
import {HttpMethod} from "../../../models/enums/HttpMethod";
import useFetchManyWithLoading from "../../../hooks/useFetchManyWithLoading";
import {FILTERS} from "../../../models/constants/Filters";
import {TITLES} from "../../../models/constants/ConstantsUI";
import {removeAccents} from "../../../utils/Utils";

const PlenarySession = () => {
    const [data, loadData] = useFetchManyWithLoading({
        requests: [{
            url: URLS.SENATORS,
            type: HttpMethod.GET,
            formatter: (response: any) => {
                response = response.sort((a: any, b: any) => a.nombre > b.nombre ? 1 : -1);
                return response;
            },
            key: 'senators'
        },
            {
                url: FILTERS.TITULAR_FILTER,
                type: HttpMethod.GET,
                formatter: (response: any) => {
                    if (response.value) {
                        return JSON.parse(response.value);
                    }
                    return null;
                },
                key: 'filters'
            }
        ],
        initial: null
    });
    const [selected, setSelected] = useState(-1);
    const [filter, setFilter] = useState<RegExp | null>(null);
    useEffect(() => {
        (async () => {
            await loadData();
        })();
    }, []);
    if (data?.filters && selected === -1) {
        setSelected(data.filters[0]);
    }
    const searchHandler = (e: string) => {
        if (e.trim().length === 0) {
            setFilter(null);
        } else {
            setFilter(new RegExp(removeAccents(e), 'i'));
        }
    };

    const filterSenators = () => {
        setSelected(selected === data.filters[0] ? data.filters[1] : data.filters[0]);
    };

    const filterData = (item: any) => {
        return filter?.test(removeAccents(item.nombre)) || filter?.test(removeAccents(item.apellidos)) || filter?.test(removeAccents(item.bancada?.nombre || '')) || filter?.test(removeAccents(item.cargo_directiva_catalogo?.name || ''));
    }

    return <>
        <div>
            <SectionTitle title={TITLES.PLENARY_SESSION} varImg='bar-2'/>
            {data && data.filters && <div className='container'>
                <div className='row d-flex justify-content-center align-items-center'>
                    <div className='col-lg-4 col-sm-12'>
                        <button className='btn search-button'
                                onClick={filterSenators}>{selected === data.filters[0] ? 'Ver suplentes' : 'Ver titulares'}</button>
                    </div>
                    <div className='col-lg-8 col-sm-12'>
                        <Search handler={searchHandler}/>
                    </div>
                </div>
                <div className='row'>
                    {data && data.senators.filter((item: any) => selected === item.es_titular && (filter ? (
                            filterData(item)
                        ) : true)
                    ).map((item: any) =>
                        <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12' key={shortid()}>
                            <SenatorCard fullName={item.nombre + ' ' + item.apellidos}
                                         alt={item.nombre + ' ' + item.apellidos}
                                         src={item.foto_perfil}
                                         benchName={item.bancada?.nombre} brigade={''}
                                         id={item.id} back={'../../'}/>
                        </div>
                    )}
                </div>
            </div>}
        </div>
    </>
};
export default PlenarySession;
