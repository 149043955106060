import {FieldType, IBasicTable} from "../../../models/interfaces/IBasicTable";
import {removeTags} from "../../../utils/Utils";
import {IRequest} from "../../../models/interfaces/IRequest";
import {HttpMethod} from "../../../models/enums/HttpMethod";
import {URLS} from "../../../utils/url";
import {PER_PAGE} from "../../../models/constants/Constants";
import SectionTitle from "../../../components/SectionTitle";
import {TITLES} from "../../../models/constants/ConstantsUI";
import BasicTable from "../../../components/tables/BasicTable";
import * as React from "react";
import ImportantAdvices from "../../important-advices/ImportantAdvices";
import {CATALOG} from "../../../models/constants/Catalog";
import SourceTable from "../../../components/tables/SourceTable";

const FIELDS: IBasicTable[] = [
    {
        label: 'Título',
        field: 'titulo',
        type: FieldType.DIRECT,
        styles: {width: '300px'},
    },
    {
        label: 'Gestión',
        field: 'gestion',
        type: FieldType.DIRECT,
        styles: {width: '100px'}
    },
    {
        label: 'Modalidad',
        field: 'modalidad',
        styles: {width: '100px'},
        type: FieldType.LIST,
        source: {
            name: 'modality',
            key: 'id',
            labels: 'name',
        }
    },
    {
        label: 'C.U.C.E.',
        field: 'cuce',
        type: FieldType.DIRECT,
        styles: {width: '120px'}
    }
];
const request: IRequest = {
    type: HttpMethod.GET,
    url: URLS.GOODS_SERVICES,
    parameters: {
        per_page: PER_PAGE
    }
};
const sources: IRequest[] = [
    {
        type: HttpMethod.GET,
        url: CATALOG.MODALITY,
        key: 'modality',
    }
];
/**
 * Convocatoria Bienes y Servicios
 * */
const GoodServices = () => {

    return (
        <>
            <SectionTitle title={TITLES.GOODS_SERVICES} varImg={'bar-1'}/>
            <SourceTable sourceRequest={sources} request={request} fields={FIELDS}/>
            <ImportantAdvices />
        </>
    );
};
export default GoodServices;
