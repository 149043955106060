import {Form} from "react-bootstrap";
import {useState} from "react";
import {postHttp} from "../../api/HttpClient";
import {statusCode} from "../../helpers/Constantes";
import {ErrorMessage} from "../../components/ErrorMessage";
import SectionTitle from "../../components/SectionTitle";
import {TITLES} from "../../models/constants/ConstantsUI";
import map from '../../assets/img/contact/MAPA.jpg';
import email from '../../assets/img/contact/correos.png';
import hour from '../../assets/img/contact/hora.png';
import phone from '../../assets/img/contact/telefonos.png';
import ubication from '../../assets/img/contact/Ubicacion.png';
import './contact.css';
import {useDispatch} from "react-redux";
import {types} from "../../types/types";
import ReCAPTCHA from "react-google-recaptcha";
import {environment} from "../../utils/environment";

const iniModel = {
    nombre: '',
    email: '',
    nro_celular: '',
    ciudad: '',
    mensaje: '',
    token: '',
};
export const Contact = () => {
    const [model, setModel] = useState(iniModel);
    const dispatch = useDispatch();
    const [modelMessage, setModelMessage] = useState({
        nombre: '',
        email: '',
        nro_celular: '',
        mensaje: '',
        ciudad: '',
        token: '',
    });
    const inputChange = ({target}: any) => {
        setModel({
            ...model,
            [target.name]: target.value
        });
    }
    const onSend = async () => {
        if (model.token.trim().length === 0) {
            setModelMessage({
                ...modelMessage,
                token: 'No marco la casilla es robot'
            });
            return;
        } else {
            const verification = await postHttp('verificar-captcha', {token: model.token});
            if (verification.success) {
                const {response, success, error, status} = await postHttp('contáctenos', model);
                if (success) {
                    setModel(iniModel);
                    setModelMessage(iniModel);
                    dispatch({
                        type: types.toast,
                        payload: {
                            message: 'Solicitud Enviada',
                        }
                    });
                } else {
                    const {errors} = error;
                    if (status === statusCode.code_422) {
                        const errorModel: any = {};
                        for (let key in errors) {
                            errorModel[key] = errors[key].join('<br>');
                        }
                        setModelMessage(errorModel);
                    }
                }
            } else {
                dispatch({
                    type: types.toast,
                    payload: 'La casilla del captcha expiro'
                });
            }
        }
    }
    const handleCaptcha = (value: any) => {
        setModel({
            ...model,
            token: value
        });
        const aux: any = Object.assign({}, modelMessage);
        delete aux.token;
        setModelMessage(aux);
    }

    return (
        <>
            <SectionTitle title={TITLES.CONTACTS}/>
            <div className='container mt-5'>
                <div className='row d-flex justify-content-center'>
                    <div className='col-12'>
                        <img src={map} alt={'mapa'} className='img-fluid'/>
                    </div>
                    <div className='col-xxl-8 col-sm-12 mt-5'>
                        <div className='contact'>
                            <h1 className="mb-3 form-title">{TITLES.FORM_CONTACT}</h1>
                            <h3 className="mb-3 form-subtitle">{TITLES.SEND_MESSAGE}</h3>
                            <Form className='contact-form'>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        type="text"
                                        placeholder="Nombre"
                                        name="nombre"
                                        autoComplete="off"
                                        className='py-3'
                                        required
                                        value={model?.nombre}
                                        autoFocus
                                        onChange={inputChange}
                                    />
                                    <ErrorMessage error={modelMessage.nombre}/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        type="text"
                                        placeholder="Correo"
                                        name="email"
                                        className='py-3'
                                        autoComplete="off"
                                        required
                                        value={model?.email}
                                        autoFocus
                                        onChange={inputChange}
                                    />
                                    <ErrorMessage error={modelMessage.email}/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        type="number"
                                        placeholder="Número"
                                        name="nro_celular"
                                        className='py-3'
                                        autoComplete="off"
                                        required
                                        value={model?.nro_celular}
                                        autoFocus
                                        onChange={inputChange}
                                    />
                                    <ErrorMessage error={modelMessage.nro_celular}/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        type="text"
                                        placeholder="Ciudad"
                                        name="ciudad"
                                        className='py-3'
                                        autoComplete="off"
                                        required
                                        value={model?.ciudad}
                                        autoFocus
                                        onChange={inputChange}
                                    />
                                    <ErrorMessage error={modelMessage.ciudad}/>
                                </Form.Group>
                                <Form.Group
                                    className="mb-3"
                                >
                                    <Form.Control as="textarea" rows={3}
                                                  placeholder="Mensaje"
                                                  name="mensaje"
                                                  className='py-3'
                                                  autoComplete="off"
                                                  value={model.mensaje}
                                                  autoFocus
                                                  onChange={inputChange}
                                    />
                                    <ErrorMessage error={modelMessage.mensaje}/>
                                </Form.Group>
                                <div className='mb-5'>
                                    <ReCAPTCHA className='w-100'
                                        sitekey={`${process.env.REACT_APP_SITE_KEY}`}
                                        onChange={handleCaptcha}
                                    />
                                    <ErrorMessage error={modelMessage.token}/>
                                </div>
                                <div className="form-group d-flex justify-content-center">
                                    <button type="button" className="btn rounded bg-2 fg-3 fs-3 py-2 px-5"
                                            onClick={() => onSend()}>
                                        Enviar
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-1 my-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xxl-3 col-sm-12 d-flex flex-column align-items-center py-4'>
                            <img src={ubication} className='icon-100p img-fluid' alt={TITLES.ADDRESS}/>
                            <label className='icon-label'>{TITLES.ADDRESS}</label>
                        </div>
                        <div className='col-xxl-3 col-sm-12 d-flex flex-column align-items-center py-4'>
                            <img src={phone} className='icon-100p img-fluid' alt={TITLES.PHONES}/>
                            <label className='icon-label'>{TITLES.PHONES}</label>
                        </div>
                        <div className='col-xxl-3 col-sm-12 d-flex flex-column align-items-center py-4'>
                            <img src={email} className='icon-100p img-fluid' alt={TITLES.EMAIL}/>
                            <label className='icon-label'>{TITLES.EMAIL}</label>
                        </div>
                        <div className='col-xxl-3 col-sm-12 d-flex flex-column align-items-center py-4'>
                            <img src={hour} className='icon-100p img-fluid' alt={TITLES.TIME}/>
                            <label className='icon-label'>{TITLES.TIME}</label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
