import {useState} from "react";
import {getHttp, postHttp} from "../api/HttpClient";
import {IRequest} from "../models/interfaces/IRequest";
import {HttpMethod} from "../models/enums/HttpMethod";

const useFetchManyWithLoading = ({requests, initial}: { requests: IRequest[], initial: any }) => {
    const [data, setData] = useState<any>(initial);
    const changeLoading = (hidden: boolean) => {
        if (hidden) {
            document.body.classList.remove('loader');
            document.body.classList.add('loaded');
        } else {
            document.body.classList.remove('loaded');
            document.body.classList.add('loader');
        }
    };

    const clear = () => {
        setData(initial);
    };

    const loadData = async () => {
        changeLoading(false);
        const promises: any[] = [];
        const mergeFormatters: any[] = [];
        requests.forEach(request => {
            switch (request.type) {
                case HttpMethod.GET:
                    promises.push(getHttp(request.url, request.parameters));
                    break;
                case HttpMethod.POST:
                    promises.push(postHttp(request.url, request.parameters));
                    break;
            }
            if (request.mergeFormatter) {
                mergeFormatters.push(request.mergeFormatter);
            }
        });
        const response = await Promise.all(promises);
        let obj: any = {};
        response.forEach((res, index) => {
            const key = requests[index].key!;
            if (requests[index].formatter) {
                res.response = requests[index].formatter(res.response);
            }
            obj[key] = res.response;
        });
        mergeFormatters.forEach(merge => {
            obj = merge(obj);
        });
        changeLoading(true);
        setData(obj);
    };
    return [data, loadData, clear];
};
export default useFetchManyWithLoading;
