export const URLS = {
    CALENDAR_SENATE: 'calendarios/horario?intervalo=3&categoria=senado',
    CALENDAR_ASSEMBLY: 'calendarios/horario?intervalo=3&categoria=asamblea',
    TREATMENT_LAWS: 'ley-tratamiento/buscar',
    TREATMENT_LAWS_SHOW: 'ley-tratamiento',
    APPROVED_LAWS: 'ley-aprobados/buscar',
    APPROVED_LAWS_SHOW: 'ley-aprobados',
    SANCTIONED_LAWS_SHOW: 'ley-sancionada',
    SANCTIONED_LAWS: 'ley-sancionada/buscar',
    PROMULGATED_LAW_SHOW: 'ley-promulgada',
    PROMULGATED_LAWS: 'ley-promulgada/buscar',
    REJECTED_LAWS: 'ley-rechazada/buscar',
    REJECTED_LAWS_SHOW: 'ley-rechazada',
    RETURNED_LAWS: 'ley-devuelto/buscar',
    RETURNED_LAWS_SHOW: 'ley-devuelto',
    LATEST_NOTICES: 'avisos/ultimos-avisos',
    NEWS_CATEGORY: 'noticiacategorias',
    NEWS: 'noticias',
    LAST_NEWS: 'noticias/ultimas-noticias',
    LAST_NEW: 'noticias/ultima-noticia',
    NORMATIVE_FRAME: 'marco-normativo',
    DIRECTIVE: 'directiva',
    PRESIDENCY: 'presidencia-actividades',
    REQUEST_ORAL_REPORT: 'peticion-informe-oral',
    REQUEST_WRITTEN_REPORT: 'peticion-informe-escrito',
    CHAMBER_RESOLUTIONS: 'resolucion-camarales',
    CHAMBER_DECLARATIONS: 'declaraciones-camarales',
    COMMUNICATION_MINUTES: 'minutas-comunicacion',
    SESSIONS: 'sesiones',
    FOLLOW_UP: 'seguimientos',
    SURVEY: 'balance-general',
    AUDIT_1: 'auditoria-cumplimiento',
    AUDIT_2: 'auditoria-operativa',
    AUDIT_3: 'auditoria-confiabilidad',
    INSTITUTIONAL_BUDGET: 'presupuesto-institucional',
    BUDGET_EXECUTION: 'ejecucion-presupuestaria',
    FINANCIAL_SOURCE: 'fuente-financiamiento',
    PROVIDERS: 'nomina-proveedor',
    GOODS_SERVICES: 'convocatoria-bbss',
    FOREIGN_TRAVELS: 'viajes-exterior',
    MANAGEMENT_REPORT: 'informe-gestion',
    INSTITUTIONAL_STRATEGIC_PLAN: 'plan-estrategico-institucional',
    MONITORING_EVALUATION_POA: 'evaluacion-poa',
    LIST_AUTHORITIES: 'nomina-autoridad',
    DEPENDENT_PERSONNEL: 'personal-dependiente',
    CALLS: 'convocatoria',
    SALARY_SCALE: 'escala-salarial',
    JOB_PROFILE: 'termino-referencia',
    PUBLIC_ACCOUNTABILITY: 'rendicion-cuenta-publica',
    INFORMATION_FORM: 'solicitud-informacion',
    IMAGES_GALLERY: 'galerias',
    VIDEO_GALLERY: 'videos',
    HOME_VIDEO: 'video/home',
    SENATE_TITULAR: 'senadores/tipo',
    SENATE_CAROUSEL: 'senadores/carrousel',
    NEWS_HOME: 'noticias/ultimas',
    ADVICES_HOME: 'avisos/home',
    SENATORS: "senadores/pleno",
    BENCHS: "bancada-senado",
    PERMANENT_COMMISSIONS: "comisiones-permanentes",
    SPECIAL_COMMISSIONS: "comisiones-especiales",
    SENATOR_LIST: "senadores",
    VIGENT_ADVICES: "avisos/vigentes",
    POA: 'poa',
    PUBLICATIONS: 'publications', LAST_CHANGE: "fecha_actualizacion"

};
