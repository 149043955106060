import {useParams} from "react-router-dom";
import {IRequest} from "../../../models/interfaces/IRequest";
import {URLS} from "../../../utils/url";
import {HttpMethod} from "../../../models/enums/HttpMethod";
import useFetchWithLoading from "../../../hooks/useFetchWithLoading";
import {useEffect} from "react";
import SectionTitle from "../../../components/SectionTitle";
import {formatDate, removeTags, utils} from "../../../utils/Utils";
import {DATE_FORMAT1} from "../../../models/constants/Constants";
import TargetBlank from "../../../components/TargetBlank";

const CommunicationMinutesCard = ({title}: { title: string }) => {
    const {id} = useParams();
    const request: IRequest = {
        url: URLS.COMMUNICATION_MINUTES + '/' + id,
        type: HttpMethod.GET
    };
    const [data, loadData] = useFetchWithLoading({
        request: request,
        initial: []
    });
    useEffect(() => {
        (async () => (await loadData()))();
    }, []);

    const getProyectista = (item: any) => {
        return item.senador_data?.filter((element: any) => element.senador !== null)
            .map((e: any) => e.senador.nombre + ' ' + e.senador.apellidos).join(', ');
    };
    return (
        <>
            <SectionTitle title={title}/>
            {data && <>
                <h3 className='title-1 text-center mt-3'>{data.titulo}</h3>
                <div className='container shadow-left-bottom my-3'>
                    <div className='p-5 d-flex flex-column'>
                        {data.type &&
                        <>
                            <label className='title-description-2'>Número de Minuta:</label>
                            <label className='description-1'>{data.numero}</label>
                        </>
                        }
                        <label className='title-description-2'>Fecha de Ingreso:</label>
                        <label
                            className='description-1 text-capitalize'>{formatDate(data.fecha_recepcion, DATE_FORMAT1)}</label>

                        <label className='title-description-2'>Fecha de Aprobación:</label>
                        <label
                            className='description-1 text-capitalize'>{formatDate(data.fecha_aprobacion, DATE_FORMAT1)}</label>

                        <label className='title-description-2'>Asunto:</label>
                        <label
                            className='description-1'>{removeTags(data.asunto)}</label>

                        <label className='title-description-2'>Proyectista:</label>
                        <label
                            className='description-1'>{getProyectista(data)}</label>


                        <label className='title-description-2'>Destino:</label>
                        <label
                            className='description-1'>{data.destinatario}</label>

                        <label className='title-description-2'>Fecha:</label>
                        <label className='description-1'>{data.fecha_destinatario}</label>

                        <label className='title-description-2'>Gestion Minutas:</label>
                        <label className='description-1'>{data.gestion}</label>
                        <label className='title-description-2'>Observaciones:</label>
                        <label
                            className='description-1'>{removeTags(data.observacion, false)}</label>
                    </div>
                </div>
                {data && data.doc_archivo && <div className='d-flex justify-content-center my-3'>
                    <TargetBlank className='btn download-pdf d-flex justify-content-between'
                                 href={utils(data.doc_archivo)}>
                        <div className='icon mx-2'/>
                        Descargar
                    </TargetBlank>
                </div>}
            </>}
        </>
    );
};
export default CommunicationMinutesCard;
