export const CATALOG = {
    DESTACADO: 'catalogo/opcion/Noticia_Destacada',
    NEWS_CATEGORIES: 'catalogo/opcion/Clasificacion_Noticia',
    STATUSES: 'catalogo/opcion/Estados',
    REPORT_TYPES: 'catalogo/opcion/Peticion_Informe',
    DEFAULT_DECISION: 'catalogo/opcion/SI_NO',
    CALENDAR_CATEGORY: 'catalogo/opcion/Categorias_Calendario',
    BRIGADES: 'catalogo/opcion/Brigada',
    ORIGIN_LAW: 'catalogo/opcion/ley_tratamiento',
    GENRES: 'catalogo/opcion/Genero',
    COMMISSION_TYPES: 'catalogo/opcion/comision_permanentes',
    PROJECTIST_LAW: 'catalogo/opcion/proyectista',
    NORMATIVE_FRAME: 'catalogo/opcion/Marco_Normativo',
    GUIDED_VISITS_DOCUMENT: 'documentos/VisitaGuiada',
    CATEGORIES_POA: 'catalogo/opcion/Categoria_POA',
    MODALITY: 'catalogo/opcion/Modalidad',
};
