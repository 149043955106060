import axios from 'axios';
import {environment} from "../utils/environment";

const Api = axios.create({
    baseURL: environment.apiUrl,
    headers: {
        'Cache-Control': 'no-cache',
        Accept: 'application/json; version=0',
        'Content-Type': 'application/json; charset=utf-8',
    },
    timeout: 50000,
});
Api.interceptors.request.use(
    async config => {
       return {
            ...config,
            headers: {
                ...config.headers,
            },
        };
    },
    error => Promise.reject(error),
);
// httpClient.interceptors.response.use(function(request) {
// });
export default Api;
