import {useState} from "react";
import {Toast, ToastContainer} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {setDispatch} from "../../api/HttpClient";
import {types} from "../../types/types";
import Moment from 'moment';
import './toast.css';

function ToastMessage() {
    const [visible, setVisible] = useState(false);
    const message = useSelector<any, any>(state => state.toast.message);
    const dispatch = useDispatch();
    setDispatch(dispatch);
    if (!visible && message != null) {
        setVisible(true);
    }
    return (
        <div
            aria-live="polite"
            aria-atomic="true"
            className="bg-dark toast-sticky-container"
        >
            <ToastContainer position="top-end" className="p-3">
                <Toast onClose={() => {
                    setVisible(false);
                    dispatch({
                        type: types.toast,
                        payload: undefined
                    });
                }} show={visible} delay={3000} animation={true}>
                    <Toast.Header>
                        <strong className="me-auto">{typeof  message === 'object' ? 'Info': 'Error'}</strong>
                        <small className="text-muted">{Moment().format('YYYY-MM-DD HH:mm:ss')}</small>
                    </Toast.Header>
                    <Toast.Body>{typeof  message === 'object' ? message?.message: message}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    );
}

export default ToastMessage;