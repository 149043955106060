import GenericDescription from "../ui/generic-description/GenericDescription";
import {ADMINISTRATIVE_AREA, AUDIT, FINANCE, PLANNING, RRHHMock} from "../mocks/StaticMocks";
import * as React from "react";
import {MegaMenu} from "../models/interfaces/NavConfig";
import {buildMegaMenu} from "../utils/Utils";
import {TITLES} from "../models/constants/ConstantsUI";
import InstitutionalBudget from "../ui/administrative-area/finance/InstitutionalBudget";
import ManagementReport from "../ui/administrative-area/planification/ManagementReport";
import InstitutionalStrategicPlan from "../ui/administrative-area/planification/InstitutionalStrategicPlan";
import FollowUp from "../ui/administrative-area/audit/FollowUp";
import Survey from "../ui/administrative-area/audit/Survey";
import EEFFAudit from "../ui/administrative-area/audit/EEFFAudit";
import SaycoAudit from "../ui/administrative-area/audit/SaycoAudit";
import ReliabilityAudit from "../ui/administrative-area/audit/ReliabilityAudit";
import BudgetExecution from "../ui/administrative-area/finance/BudgetExecution";
import FinancialSource from "../ui/administrative-area/finance/FinancialSource";
import Providers from "../ui/administrative-area/finance/Providers";
import GoodServices from "../ui/administrative-area/finance/GoodServices";
import ForeignTravels from "../ui/administrative-area/finance/ForeignTravels";
import MonitoringEvaluationPoa from "../ui/administrative-area/planification/MonitoringEvaluationPoa";
import ListAuthorities from "../ui/administrative-area/rrhh/ListAuthorities";
import DependentPersonnel from "../ui/administrative-area/rrhh/DependentPersonnel";
import Calls from "../ui/administrative-area/rrhh/Calls";
import SalaryScale from "../ui/administrative-area/rrhh/SalaryScale";
import JobProfile from "../ui/administrative-area/rrhh/JobProfile";
import PublicAccountability from "../ui/administrative-area/transparency/PublicAccountability";
import InformationForm from "../ui/administrative-area/transparency/InformationForm";
import POA from "../ui/administrative-area/planification/Poa";

export const AdministrativeRoutes: MegaMenu =
        buildMegaMenu({
            title: TITLES.ADMINISTRATIVE_AREA,
            // component: <GenericDescription title={ADMINISTRATIVE_AREA.title}
            //                                subtitle={ADMINISTRATIVE_AREA.subtitle}
            //                                varImg={ADMINISTRATIVE_AREA.imgUrl}
            //                                description={ADMINISTRATIVE_AREA.description}/>,
            clazz: 'root-link',
            linkeable: true,
            megaItems: [
                {
                    title: TITLES.AUDIT,
                    component: <GenericDescription title={AUDIT.title} subtitle={AUDIT.subtitle}
                                                   varImg={AUDIT.imgUrl}
                                                   description={AUDIT.description}/>,
                    link: true,
                    items: [
                        {
                            title: TITLES.AUDIT_1,
                            component: <EEFFAudit />
                        },
                        {
                            title: TITLES.AUDIT_2,
                            component: <SaycoAudit/>
                        },
                        {
                            title: TITLES.AUDIT_3,
                            component: <ReliabilityAudit />
                        },
                        {
                            title: TITLES.AUDIT_4,
                            component: <Survey />
                        },
                        {
                            title: TITLES.AUDIT_5,
                            component: <FollowUp />
                        },
                    ]
                },
                {
                    title: TITLES.FINANCIAL_INFORMATION,
                    component: <GenericDescription title={FINANCE.title} subtitle={FINANCE.subtitle}
                                                   varImg={FINANCE.imgUrl}
                                                   description={FINANCE.description}/>,
                    link: true,
                    items: [
                        {
                            title: TITLES.INSTITUTIONAL_BUDGET,
                            component: <InstitutionalBudget/>,
                        },
                        {
                            title: TITLES.BUDGET_EXECUTION,
                            component: <BudgetExecution/>,
                        },
                        {
                            title: TITLES.FINANCIAL_SOURCE,
                            component: <FinancialSource/>,
                        },
                        {
                            title: TITLES.PROVIDERS,
                            component: <Providers/>,
                        },
                        {
                            title: TITLES.GOODS_SERVICES,
                            component: <GoodServices/>,
                        },
                        {
                            title: TITLES.FOREIGN_TRAVELS,
                            component: <ForeignTravels/>,
                        },
                    ]
                },
                {
                    title: TITLES.PLANNING,
                    component: <GenericDescription title={PLANNING.title} subtitle={PLANNING.subtitle}
                                                   varImg={PLANNING.imgUrl}
                                                   description={PLANNING.description}/>,
                    link: true,
                    items: [
                        {
                            title: TITLES.MANAGEMENT_REPORT,
                            component: <ManagementReport/>,
                        },
                        {
                            title: TITLES.INSTITUTIONAL_STRATEGIC_PLAN,
                            component: <InstitutionalStrategicPlan/>,
                        },
                        {
                            title: TITLES.ANNUAL_OPERATIVE_PLAN,
                            component: <POA/>,
                        },
                        {
                            title: TITLES.MONITORING_EVALUATION_POA,
                            component: <MonitoringEvaluationPoa />,
                        },
                    ]
                },
                {
                    title: TITLES.HUMAN_RESOURCES,
                    component: <GenericDescription title={RRHHMock.title} subtitle={RRHHMock.subtitle}
                                                   varImg={RRHHMock.imgUrl}
                                                   description={RRHHMock.description}/>,
                    link: true,
                    items: [
                        {
                            title: TITLES.LIST_AUTHORITIES,
                            component: <ListAuthorities />,
                        },
                        {
                            title: TITLES.DEPENDENT_PERSONNEL,
                            component: <DependentPersonnel />,
                        },
                        {
                            title: TITLES.CALLS,
                            component: <Calls />,
                        },
                        {
                            title: TITLES.SALARY_SCALE,
                            component: <SalaryScale/>,
                        },
                        {
                            title: TITLES.JOB_PROFILE,
                            component: <JobProfile/>,
                        }
                    ]
                },
                {
                    title: TITLES.TRANSPARENCY,
                    link: false,
                    items: [
                        {
                            title: TITLES.PUBLIC_ACCOUNTABILITY,
                            component: <PublicAccountability/>,
                        },
                        {
                            title: TITLES.INFORMATION_FORM,
                            component: <InformationForm />,
                        },
                    ]
                }
            ]
        })
;
