import ImportantAdvices from "../../important-advices/ImportantAdvices";
import {FieldType, IBasicTable} from "../../../models/interfaces/IBasicTable";
import {removeTags} from "../../../utils/Utils";
import {IRequest} from "../../../models/interfaces/IRequest";
import {HttpMethod} from "../../../models/enums/HttpMethod";
import {URLS} from "../../../utils/url";
import {PER_PAGE} from "../../../models/constants/Constants";
import SectionTitle from "../../../components/SectionTitle";
import {TITLES} from "../../../models/constants/ConstantsUI";
import {SOURCES} from "../../../utils/Sources";
import SourceTable from "../../../components/tables/SourceTable";
import {ISearcher, ISearcherType} from "../../../models/interfaces/ISearcher";

const FIELDS: IBasicTable[] = [
    {
        label: 'Título',
        field: 'titulo',
        type: FieldType.DIRECT,
        styles: {width: '200px'},
        link: {
            field: 'id',
        }
    },
    {
        label: 'Destinatario',
        field: 'tipo_destinatario',
        type: FieldType.DIRECT,
    },
    {
        label: 'Resumen',
        field: 'resumen',
        type: FieldType.DIRECT,
        formatter: removeTags,
    },
    {
        label: 'Gestión',
        field: 'gestion_focalizacion',
        type: FieldType.DIRECT,
        styles: {width: '120px'}
    },
    {
        label: 'Peticionario',
        field: 'peticionario',
        type: FieldType.LIST,
        source: {
            name: 'senators',
            key: 'id',
            labels: ['nombre', 'apellidos'],
        }
    },
    {
        label: 'Fecha Destinatario',
        field: 'fecha_destinatario',
        type: FieldType.DIRECT,
        styles: {width: '200px'}
    },
    {
        label: 'Observaciones',
        field: 'observacion',
        type: FieldType.DIRECT,
        formatter: removeTags,
    },
    {
        label: 'Archivo',
        field: 'doc_archivo',
        type: FieldType.FILE,
        styles: {width: '120px'}
    }
];
const request: IRequest = {
    type: HttpMethod.GET,
    url: URLS.REQUEST_WRITTEN_REPORT,
    parameters: {
        per_page: PER_PAGE
    },
    formatter: (response: any) => {
        const data = response?.data;
        if (data) {
            data.forEach((element: any) => {
                element.peticionario = element.peticionario.map((p: any) => p.senador_id);
            });
        }
        return response;
    }
};
const sources: IRequest[] = [
    {
        type: HttpMethod.GET,
        url: SOURCES.WRITTEN_DESTINY,
        key: 'destinies',
    },
    {
        type: HttpMethod.GET,
        url: SOURCES.SENATORS_SELECT,
        key: 'senators',
    }
];

const searches: ISearcher [] = [
    {
        type: ISearcherType.TEXT,
        label: 'Título',
        field: 'titulo',
        clazz: 'col-xl-6 col-sm-12'
    },
    {
        type: ISearcherType.TEXT,
        label: 'Destinatario',
        field: 'tipo_destinatario',
        clazz: 'col-xl-6 col-sm-12',
    },
    {
        type: ISearcherType.TEXT,
        label: 'Gestión',
        field: 'gestion_focalizacion',
        clazz: 'col-xl-6 col-sm-12',
    },
    {
        type: ISearcherType.TEXT,
        label: 'Asunto',
        field: 'resumen',
        clazz: 'col-xl-6 col-sm-12'
    },
    {
        type: ISearcherType.LIST,
        label: 'Peticionario',
        field: 'peticionario',
        clazz: 'col-xl-6 col-sm-12',
        source: {
            name: 'senators',
            key: 'id',
            labels: ['nombre', 'apellidos'],
        }
    },
];
const RequestWrittenReport = () => {

    return (
        <>
            <SectionTitle title={TITLES.REQUEST_WRITTEN_REPORT} varImg={'bar-1'}/>
            <SourceTable sourceRequest={sources} request={request} fields={FIELDS} searches={searches}/>
            <ImportantAdvices/>
        </>
    );
};
export default RequestWrittenReport;
