import SectionTitle from "../../components/SectionTitle";
import {TITLES} from "../../models/constants/ConstantsUI";
import useFetchWithLoading from "../../hooks/useFetchWithLoading";
import {URLS} from "../../utils/url";
import {HttpMethod} from "../../models/enums/HttpMethod";
import {useEffect, useState} from "react";
import shortid from "shortid";
import {datetimeFormat} from "../../utils/Utils";
import ReactPaginate from "react-paginate";
import {PER_PAGE} from "../../models/constants/Constants";
import ReactPlayer from "react-player";

const initial = {
    per_page: PER_PAGE
};
const VideoGallery = () => {
    const [playing, play] = useState(false);
    const [list, loadData, pagination] = useFetchWithLoading({
        request: {
            url: URLS.VIDEO_GALLERY,
            type: HttpMethod.GET,
            parameters: initial
        },
        initial: {}
    });

    useEffect(() => {
        loadData()
    }, []);

    const handlePageClick = async (offset: any) => {
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
        const {selected}: { selected: number } = offset;
        const params = {
            page: selected + 1,
            ...initial
        }
        await loadData(params);
    }


    return (
        <>
            <SectionTitle title={TITLES.VIDEO_GALLERY}/>
            <div className='container my-5'>
                <div className='row'>
                    {list && list.data && list.data.map((row: any, index: number) =>
                        <div className='col-4 d-flex flex-column' key={shortid()}>
                            <div className='mt-3 row-border p-3 video-height'>
                                <ReactPlayer
                                    className='w-100 h-100'
                                    url={row.url} playing={playing} controls={false}
                                    onPause={() => {
                                        play(playing)
                                    }}
                                    config={
                                        {
                                            youtube: {
                                                playerVars: {
                                                    controls: 1
                                                }
                                            }
                                        }
                                    }
                                />
                            </div>
                            <h3 className='mt-3 title-1 bg-13 mb-0 single-line-text p-3'>
                                {/*<div className='bg-13 h-100 d-inline-block p-3'>*/}
                                    {row.titulo}
                                {/*</div>*/}
                            </h3>
                            <h3 className={`${index % 2 === 0 ? 'bg-1' : 'bg-2'} fg-white text-center title-1 py-3 px-2`}>{datetimeFormat(row.fecha)}</h3>
                        </div>
                    )}
                </div>
                {pagination.pages > 1 ?
                    <div className='d-flex justify-content-center mt-3'>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel='Siguiente'
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pagination.pages}
                            previousLabel='Anterior'
                            containerClassName='pagination'
                            previousClassName='previous-next'
                            nextClassName='previous-next'
                            pageClassName='page-item'
                            activeClassName='page-selected'
                            disabledClassName='disable-button'
                        />
                    </div> : null}
            </div>
        </>
    );
};
export default VideoGallery;
