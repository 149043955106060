import {IRequest} from "../../../models/interfaces/IRequest";
import {URLS} from "../../../utils/url";
import {HttpMethod} from "../../../models/enums/HttpMethod";
import {useParams} from "react-router-dom";
import LawCard from "../../../components/cards/LawCard";
import {TITLES} from "../../../models/constants/ConstantsUI";

const PromulgatedCard = () => {
    const {id} = useParams();
    const request: IRequest = {
        url: URLS.PROMULGATED_LAW_SHOW + '/' + id,
        type: HttpMethod.GET,
    };
    return (
        <>
            <LawCard title={TITLES.PROMULGATED_LAW} request={request} />
        </>
    );
};
export default PromulgatedCard;
