import {AxiosError, AxiosResponse} from 'axios';
import Api from './Api';
import {Response} from "../models/Response";
import FakeApi from "./FakeApi";
import {types} from "../types/types";

let dispatch: any;

export const setDispatch = (dispatcher: any) => {
    dispatch = dispatcher;
};

export const getHttp = async (url: string, params: any = [], formatter?: any) => {
    try {
        const res = await Api.get<AxiosResponse<any>>(url, {
            params,
        });
        const {data} = res;
        if (formatter) {
            formatter(data);
        }
        return {response: data?.data, success: true, message: ''};
    } catch (e: any) {
        const response = {
            response: [], success: false, message: e.message,
        };
        if (e instanceof AxiosError) {
            const axiosError = e as AxiosError;
            const data: any = axiosError.response?.data;
            response.message = data != null ? `Code: ${data.code || data.statusCode}, message: ${getMessage(data.error) || getMessage(data.message)}` :getMessage( e.message) || getMessage(axiosError.message);
        }
        dispatch({
            type: types.toast,
            payload: typeof response.message === 'object' ? 'Error inesperado' : response.message
        });
        return response;
    }
};

const getMessage = (error: any) => {
    if (typeof error === 'object' || error == null) {
        return 'Error inesperado';
    }
    return error;
};

export const postHttp = async (url: string, body: any, multiPart = false) => {
    try {
        const res = await Api.post<AxiosResponse<Response>>(url, body, {headers: {'Content-Type': multiPart ? 'multipart/form-data' : 'text/json'}});
        const {data} = res;
        return {response: data?.data, success: true, message: '', status: 201};
    } catch (e) {
        const {response} = e as any;
        return {response: [], success: false, message: 'Error', status: response.status, error: response?.data};
    }
};

export const getHttpFake = async (url: string, params: any = []): Promise<any> => {
    try {
        const res = await FakeApi.get<AxiosResponse<any>>(url, {
            params,
        });
        return {response: res.data, success: true, message: ''};
    } catch (e) {
        return {response: [], success: false, message: ' Error', error: ''};
    }
};
