import {useParams} from "react-router-dom";
import {IRequest} from "../../../models/interfaces/IRequest";
import {URLS} from "../../../utils/url";
import {HttpMethod} from "../../../models/enums/HttpMethod";
import useFetchWithLoading from "../../../hooks/useFetchWithLoading";
import {useEffect} from "react";
import SectionTitle from "../../../components/SectionTitle";
import {formatDate, removeTags, utils} from "../../../utils/Utils";
import {DATE_FORMAT1} from "../../../models/constants/Constants";
import TargetBlank from "../../../components/TargetBlank";

const ChamberResolutionsCard = ({title}: { title: string }) => {
    const {id} = useParams();
    const request: IRequest = {
        url: URLS.CHAMBER_RESOLUTIONS + '/' + id,
        type: HttpMethod.GET
    };
    const [data, loadData] = useFetchWithLoading({
        request: request,
        initial: []
    });
    useEffect(() => {
        (async () => (await loadData()))();
    }, []);
    return (
        <>
            <SectionTitle title={title}/>
            {data && <>
                <h3 className='title-1 text-center mt-3'>{data.titulo}</h3>
                <div className='container shadow-left-bottom my-3'>
                    <div className='p-5 d-flex flex-column'>
                        <label className='title-description-2'>Fecha de Recepción:</label>
                        <label
                            className='description-1 text-capitalize'>{formatDate(data.fecha_recepcion, DATE_FORMAT1)}</label>

                        <label className='title-description-2'>Fecha de Aprobación:</label>
                        <label
                            className='description-1 text-capitalize'>{formatDate(data.fecha_aprobacion, DATE_FORMAT1)}</label>

                        <label className='title-description-2'>Asunto:</label>
                        <label
                            className='description-1'>{removeTags(data.asunto, false)}</label>

                        <label className='title-description-2'>Institución:</label>
                        <label className='description-1'>{data.institucion_text}</label>

                        <label className='title-description-2'>Numero de Resolución:</label>
                        <label
                            className='description-1'>{data.numero}</label>

                        <label className='title-description-2'>Gestion Resoluciones:</label>
                        <label
                            className='description-1'>{data.gestion}</label>

                    </div>
                </div>
                {data && data.doc_archivo && <div className='d-flex justify-content-center my-3'>
                    <TargetBlank className='btn download-pdf d-flex justify-content-between'
                                 href={utils(data.doc_archivo)}>
                        <div className='icon mx-2'/>
                        Descargar
                    </TargetBlank>
                </div>}
            </>}
        </>
    );
};
export default ChamberResolutionsCard;
