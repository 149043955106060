import SectionTitle from "../../../components/SectionTitle";
import shortid from "shortid";
import './regulatory-framework.css';
import {useEffect} from "react";
import {URLS} from "../../../utils/url";
import {TITLES} from "../../../models/constants/ConstantsUI";
import TargetBlank from "../../../components/TargetBlank";
import {utils} from "../../../utils/Utils";
import useFetchManyWithLoading from "../../../hooks/useFetchManyWithLoading";
import {HttpMethod} from "../../../models/enums/HttpMethod";
import {CATALOG} from "../../../models/constants/Catalog";


const RegulatoryFramework = () => {
    const [data, loadData] = useFetchManyWithLoading({
        requests: [
            {url: CATALOG.NORMATIVE_FRAME, type: HttpMethod.GET, key: 'categories'},
            {url: URLS.NORMATIVE_FRAME, type: HttpMethod.GET, key: 'normatives'}
        ],
        initial: []
    });
    useEffect(() => {
        loadData();
    }, []);
    return (
        <>
            <SectionTitle title={TITLES.NORMATIVE_FRAME}/>
            <div className='container'>
                <div className='table-responsive'>
                    <table className='table regulatory'>
                        <tbody>
                        {data.categories && data.categories.map((section: any) =>
                            <Section data={data} section={section} key={shortid()} />
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};
const Section = ({data, section}: {data: any, section: any})=>{
    return (
        <>
            <tr>
                <th colSpan={2}>
                    <div className='regulatory-title text-capitalize'>
                        {section.name}
                    </div>
                </th>
            </tr>
            <tr className='regulatory-header'>
                <th>Descripción</th>
                <th>Gestión</th>
                <th>Estado</th>
                <th className='regulatory-download text-center text-uppercase'>Archivo PDF</th>
            </tr>
            {data.normatives.filter((current: any) => current.categoria === section.id).map((row: any) =>
                <tr key={shortid()} className='regulatory-data'>
                    <td className='description-1'>{row.titulo}</td>
                    <td>{row.gestion}</td>
                    <td>{row.estado_id?.name}</td>
                    <td className='regulatory-button'>
                        <div className='d-flex justify-content-center'>
                            <TargetBlank className='btn download-pdf d-flex justify-content-between'
                                         href={utils(row.archivo)} children={<>
                                <div className='icon mx-2'/>
                                Descargar
                            </>}/>
                        </div>
                    </td>
                </tr>)}
        </>
    );
};

export default RegulatoryFramework;