import {FieldType, IBasicTable} from "../../models/interfaces/IBasicTable";
import shortid from "shortid";
import TargetBlank from "../TargetBlank";
import {utils} from "../../utils/Utils";
import {IRequest} from "../../models/interfaces/IRequest";
import useFetchWithLoading from "../../hooks/useFetchWithLoading";
import {useEffect, useState} from "react";
import './table-1.css';
import ReactPaginate from "react-paginate";
import {ISearcher} from "../../models/interfaces/ISearcher";
import Searcher from "../search/Searcher";
import {PER_PAGE} from "../../models/constants/Constants";
import {Link} from "react-router-dom";

const BasicTable = ({fields, request, searches}: {
    fields: IBasicTable[], request: IRequest, searches?: ISearcher[]
}) => {
    const [result, loadData, pagination] = useFetchWithLoading({
        request: request,
        initial: []
    });
    const [currentFilter, setCurrentFilter] = useState<any>({per_page: PER_PAGE});
    useEffect(() => {
        (async () => (await loadData()))();
    }, []);

    const searchEvent = async (parameters: any) => {
        setCurrentFilter(Object.assign({}, parameters));
        await loadData({
            page: 1,
            ...parameters
        });
    };
    const handlePageClick = async (offset: any) => {
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
        const {selected}: { selected: number } = offset;
        await loadData({page: selected + 1, ...currentFilter});
    }

    return (<>
        {searches && <Searcher sources={[]} searches={searches} action={searchEvent}/>}
        <div className='w-100 mt-3'>
            <div className='px-5 table-responsive'>
                <table className='table table-1 w-100 fg-1'>
                    <thead>
                    <tr className='table-1-header'>
                        {fields.map(field => <th key={shortid()}>{field.label}</th>)}
                    </tr>
                    </thead>
                    <tbody>
                    {result.data && result.data.map((row: any) =>
                        <tr key={shortid()}>
                            {fields.map((field: IBasicTable) => <td key={shortid()} style={field.styles}>
                                <RenderTD field={field} row={row}/>
                            </td>)}
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            {pagination.pages > 1 ?
                <div className='d-flex justify-content-center'>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel='Siguiente'
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pagination.pages}
                        previousLabel='Anterior'
                        containerClassName='pagination'
                        previousClassName='previous-next'
                        nextClassName='previous-next'
                        pageClassName='page-item'
                        activeClassName='page-selected'
                        disabledClassName='disable-button'
                    />
                </div> : null}
        </div>
    </>);
};
const RenderTD = ({field, row}: { field: IBasicTable, row: any }) => {
    switch (field.type) {
        case FieldType.DIRECT:
            return (
                <>
                    {field.link == null && <>{field.formatter != null ? field.formatter(row[field.field]) : row[field.field]}</>}
                    {field.link != null &&
                    <Link className='link-field'
                          to={`${field.link.path == null ? row[field.link.field] : field.link.path}`}>{field.formatter != null ? field.formatter(row[field.field]) : row[field.field]}</Link>}
                </>
            );
        case FieldType.HTML:
            return (
                <>
                    {field.formatter != null ? field.formatter(row[field.field]) : row[field.field]}
                </>);
        case FieldType.FILE:
            return (
                <>
                    {row[field.field] &&
                    <div className='d-flex'>
                        <TargetBlank className='btn download-pdf d-flex justify-content-around'
                                     href={utils(row[field.field])}>
                            <div className='icon mr-2'/>
                            Descargar
                        </TargetBlank>
                    </div>
                    }
                </>
            );
    }
    return null;
};


export default BasicTable;