export const TITLES = {
    // PRESIDENCIA
    PRESIDENCY: 'Presidencia',
    ACTIVITIES: 'Actividades',
    // Institucional
    INSTITUTIONAL: 'Institucional',
    MISSION_VISION: 'Misión y Visión',
    INSTITUTIONAL_OBJECTIVES: 'Objetivos Institucionales',
    NORMATIVE_FRAME: 'Marco Normativo',
    ORGANIZATION: 'Organigrama',
    // Area Legislativa
    LEGISLATIVE_AREA: 'Área Legislativa',
    LEGISLATIVE_ORGANIZATION: 'Organización Legislativa',
    PLENARY_SESSION: 'Pleno Camaral',
    DIRECTIVE: 'Directiva Camaral',
    PERMANENT_COMMISSIONS: 'Comisiones Permanentes',
    SPECIAL_COMMISSIONS: 'Comisiones Especiales',
    BRIGADES: 'Brigadas',
    BENCHS: 'Bancadas',
    LEGISLATION: 'Legislación',
    TREATMENT_LAW: 'Proyectos de Ley en Tratamiento',
    APPROVED_LAW: 'Proyectos de Ley Aprobados',
    SANCTIONED_LAW: 'Proyectos de Ley Sancionados',
    RETURNED_LAW: 'Proyectos de Ley con Modificaciones',
    PROMULGATED_LAW: 'Leyes Promulgadas',
    REJECTED_LAW: 'Proyectos de Ley Rechazados',
    FISCALIZATION: 'Fiscalización',
    REQUEST_WRITTEN_REPORT: 'Petición de Informe Escrito',
    REQUEST_ORAL_REPORT: 'Petición de Informe Oral',
    GESTION: 'Gestión',
    CHAMBER_RESOLUTIONS: 'Resoluciones Camarales',
    CHAMBER_DECLARATIONS: 'Declaraciones Camarales',
    COMMUNICATION_MINUTES: 'Minutas de Comunicación',
    GENERAL_SECRETARY: 'Secretaria General',
    SESSIONS: 'Sesiones',

    // Area Administrativa
    ADMINISTRATIVE_AREA: 'Área Administrativa',
    AUDIT: 'Auditoría',
    AUDIT_1: 'Auditoría de Cumplimiento',
    AUDIT_2: 'Auditoría de Operativa',
    AUDIT_3: 'Auditoría de Confiabilidad',
    AUDIT_4: 'Relevamiento',
    AUDIT_5: 'Seguimientos',
    FINANCIAL_INFORMATION: 'Información Financiera',
    INSTITUTIONAL_BUDGET: 'Presupuesto Institucional',
    BUDGET_EXECUTION: 'Ejecución Presupuestaria',
    FINANCIAL_SOURCE: 'Fuente de Financiamiento',
    PROVIDERS: 'Nómina de Proveedores',
    GOODS_SERVICES: 'Convocatoria de Bienes y Servicios',
    FOREIGN_TRAVELS: 'Viaje Exterior',
    PLANNING: 'Planificación',
    MANAGEMENT_REPORT: 'Informe de Gestión',
    INSTITUTIONAL_STRATEGIC_PLAN: 'Plan Estratégico Institucional',
    ANNUAL_OPERATIVE_PLAN: 'Plan Operativo Anual',
    MONITORING_EVALUATION_POA: 'Seguimiento y Evaluación al POA',
    HUMAN_RESOURCES: 'Recursos Humanos',
    LIST_AUTHORITIES: 'Nómina de Autoridades',
    DEPENDENT_PERSONNEL: 'Nómina de Personal Dependiente',
    CALLS: 'Convocatorias',
    SALARY_SCALE: 'Escala Salarial',
    JOB_PROFILE: 'Perfil de Cargos',
    TRANSPARENCY: 'Transparencia',
    PUBLIC_ACCOUNTABILITY: 'Rendición Pública de Cuentas',
    INFORMATION_FORM: 'Formulario de Solicitud de Información',
    // Prensa
    NEWS_INFO: 'Prensa',
    NEWS: 'Noticias',
    IMAGES_GALLERY: 'Galería de Imágenes',
    VIDEO_GALLERY: 'Galería de Videos',
    ADVICES: 'Avisos',
    SENATE_RATIO: 'Radio Senado',
    // Consulta Ciudadana
    PUBLIC_CONSULTATION: 'Consulta Ciudadana',
    GUIDED_VISITS: 'Visitas Guiadas',
    CONTACTS: 'Contacto',
    CONTACTS_LINK: 'consulta-ciudadana/contacto',
    CONTACTS_2: 'CONTÁCTENOS',
    FORM_CONTACT: 'Formulario de Contacto',
    SEND_MESSAGE: 'Enviar un mensaje',
    PHONES: 'Telefonos',
    ADDRESS: 'Dirección',
    EMAIL: 'Correos',
    TIME: 'Días/Horas',
    FORM_GUIDED_VISIT: 'Formulario de Registro',
    COMMISSION_COMITE: "Comisiones - Comites",
    INIT: "Inicio",
    EVENT_PHOTOS: "Fotos del Evento",
    UNDER_CONSTRUCTION: "Sección en Construcción",
    NEWS_SENATE: "Prensa Senado"
};
