import {IRequest} from "../models/interfaces/IRequest";
import {useState} from "react";
import {HttpMethod} from "../models/enums/HttpMethod";
import {getHttp, postHttp} from "../api/HttpClient";
import {PER_PAGE} from "../models/constants/Constants";

const useFetchTableSource = ({
                                 table,
                                 sources,
                                 initial
                             }: { table: IRequest, sources: IRequest[], initial: any }) => {
    const [data, setData] = useState<any>(initial);
    const [pagination, setPagination] = useState<any>({
        list: [],
        pages: 0,
        perPage: 0
    });

    const changeLoading = (hidden: boolean) => {
        if (hidden) {
            document.body.classList.remove('loader');
            document.body.classList.add('loaded');
        } else {
            document.body.classList.remove('loaded');
            document.body.classList.add('loader');
        }
    };

    const loadDataAll = async () => {
        changeLoading(false);
        const promises: any[] = [];
        const requests: IRequest[] = [
            table,
            ...sources,
        ];
        requests.forEach(request => {
            switch (request.type) {
                case HttpMethod.GET:
                    promises.push(getHttp(request.url, request.parameters));
                    break;
                case HttpMethod.POST:
                    promises.push(postHttp(request.url, request.parameters));
                    break;
            }
        });
        const response = await Promise.all(promises);
        const obj: any = {
            table: {},
            sources: {}
        };
        response.forEach((res, index) => {
            const key = requests[index].key!;
            if (requests[index].formatter) {
                res.response = requests[index].formatter(res.response);
            }
            if (index === 0) {
                obj.table = res.response;
            } else {
                obj.sources[key] = res.response;
            }
            if (index === 0 && res.success) {
                setPagination({
                    list: res.response.data,
                    pages: res.response.last_page,
                    perPage: res.response.per_page
                });
            }
        });
        changeLoading(true);
        setData(obj);
    };
    const loadData = async (parameters?: any) => {
        changeLoading(false);
        const promises: any[] = [];
        switch (table.type) {
            case HttpMethod.GET:
                promises.push(getHttp(table.url, parameters || table.parameters));
                break;
            case HttpMethod.POST:
                promises.push(postHttp(table.url, parameters || table.parameters));
                break;
        }
        const response = await Promise.all(promises);
        const [res] = response;
        if (table.formatter) {
            res.response = table.formatter(res.response);
        }
        if (res.success) {
            setPagination({
                list: res.response.data,
                pages: res.response.last_page,
                perPage: res.response.per_page
            });
        }
        changeLoading(true);
        setData({
            ...data,
            table: res.response
        });
    };
    return [
        data, loadData, loadDataAll, pagination
    ];
};
export default useFetchTableSource;