import '../zoom-card.css';
import {CardZoomModel} from "../../models/interfaces/CardZoomModel";

const CardZoomEffect = ({
                            imgUrl, title, description, date, href, linkTitle
                        }: CardZoomModel) => {
    return (
        <div className="zoom-card">
            <a href={href} className="zoom-card__card-link"/>
            <img
                src={imgUrl}
                alt="" className="zoom-card__image"/>
            <div className="zoom-card__text-wrapper">
                <h2 className={`zoom-card__title ${description ? 'with-description': 'with-out-description'}`}>{title}</h2>
                <div className="zoom-card__post-date">{date}</div>
                <div className="zoom-card__details-wrapper">
                    <p className="zoom-card__excerpt">{description}</p>
                    <a href={href} className="zoom-card__read-more">{linkTitle}
                        <i className="fas fa-long-arrow-alt-right"/>
                    </a>
                </div>
            </div>
        </div>
    );
}
export default CardZoomEffect;
