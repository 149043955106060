import icon from '../assets/img/no-image.png';
const CustomImage = (props: any) => {


    return (
        <img {...props} onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = icon;
        }}/>
    );
};
export default CustomImage;