import axios from 'axios';

const FakeApi = axios.create({
    baseURL: 'https://jsonplaceholder.typicode.com',
    headers: {
        'Cache-Control': 'no-cache',
        Accept: 'application/json; version=0',
        'Content-Type': 'application/json; charset=utf-8',
    },
    timeout: 50000,
});
FakeApi.interceptors.request.use(
    async config => {
        return {
            ...config,
            headers: {
                ...config.headers,
            },
        };
    },
    error => Promise.reject(error),
);
// httpClient.interceptors.response.use(function(request) {
//   console.log(' interceptors response ', request);
// });
export default FakeApi;
