import SectionTitle from "../../components/SectionTitle";
import {TITLES} from "../../models/constants/ConstantsUI";
import visionIcon from '../../assets/img/vision.png';
import missionIcon from '../../assets/img/mission.png';
import IconCard from "../../components/cards/card-icon/IconCard";

const MISSION = {
    title: 'Misión',
    img: missionIcon,
    description: `Al 2025 conmemorando los 200 años de fundación de la Patria, 21 años del Estado Plurinacional, 
    la Cámara de Senadores se consolida como uno de los pilares fundamentales del nuevo Estado Unitario, 
    Social de Derecho Plurinacional,Comunitario, libre, independiente, soberano, democrático, intercultural, 
    descentralizado con autonomías, como expresión de la pluralidad democrática,representativa, participativa y 
    comunitaria; ejerciendo el principio deliberativo en la discusión, análisis de los proyectos de ley para su aprobación, 
    modificación y/o rechazo; fiscalizando al Órgano Ejecutivo propositivamente; acompañando y canalizando constantemente al pueblo en sus demandas. `,
};

const VISION = {
    title: 'Visión',
    img: visionIcon,
    description: `“El horizonte estratégico del Estado Plurinacional es el Vivir Bien como modelo civilizatorio alternativo
    al capitalismo y al colonialismo; la legislación y fiscalización democrática y participativa nos permitirá consolidar la 
    arquitectura de nuestro nuevo Estado al conmemorar los 200 años de fundación de la Patria.”

    La democracia deliberativa y comunitaria es la esencia de nuestro Estado Plurinacional y de nuestros pueblos, 
    la Cámara de Senadores,como expresión democrática y soberana del pueblo, institucionalizará el acompañamiento 
    y presencia de las y los senadores donde el pueblo lo requiera.
    `,
};

const MissionVision = () => {
    return (
        <>
            <SectionTitle title={TITLES.MISSION_VISION} varImg={'bar-2'}/>
            <div className='container'>
                <div className='row mt-5 d-flex justify-content-center mb-5'>
                    <div className='col-10'>
                        <h3 className='text-center title-section fg-1 py-5 fs-1'>{MISSION.title}</h3>
                        <IconCard orientation={'left'} img={MISSION.img} description={MISSION.description}/>
                    </div>
                </div>
                <div className='row mt-5 d-flex justify-content-center mb-5'>
                    <div className='col-10'>
                        <h3 className='text-center title-section fg-1 py-5 fs-1 mt-5'>{VISION.title}</h3>
                        <IconCard orientation={'right'} img={VISION.img} description={VISION.description}/>
                    </div>
                </div>
            </div>
        </>
    );
};
export default MissionVision;