import {Modal} from "react-bootstrap";
import {useEffect, useState} from "react";
import './publication.css';
import useFetchWithLoading from "../../hooks/useFetchWithLoading";
import {HttpMethod} from "../../models/enums/HttpMethod";
import {URLS} from "../../utils/url";
import Carousel from "react-multi-carousel";
import {utils} from "../../utils/Utils";
import TargetBlank from "../../components/TargetBlank";
import shortid from "shortid";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 3000},
        items: 1
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 1
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 1
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1
    }
};
const Publication = () => {
    const [show, setShow] = useState({
        open: false,
        closed: false,
    });

    const handleClose = () => setShow({open: false, closed: true});
    const [data, loadData] = useFetchWithLoading({
        request: {
            type: HttpMethod.GET,
            url: URLS.PUBLICATIONS
        },
        initial: null,
        loading: false
    });

    useEffect(() => {
        (async () => await showModal())();
    }, []);
    const showModal = async () => {
        const info = sessionStorage.getItem('publications');
        if (!info) {
            await loadData();
        }
    };
    if (data && data.length > 0 && !show.open && !show.closed) {
        sessionStorage.setItem('publications', 'true');
        setShow({...show, open: true});
    }
    return (
        <>
            {data && <Modal
                show={show.open}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className='publication modal-lg'
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title/>
                </Modal.Header>
                <Modal.Body>
                    <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={false}
                        autoPlay={true}
                        // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                        autoPlaySpeed={2500}
                        keyBoardControl={true}
                        customTransition="all .5s"
                        pauseOnHover
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        // deviceType={this.props.deviceType}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                    >
                        {data && data.map((row: any) => (
                            <div className='d-flex flex-column' key={shortid()}>
                                <img src={utils(row.poster)} alt={row.titulo} className='img-fluid publication-img'/>
                                <div className='text-center py-1'>
                                    <TargetBlank href={utils(row.archivo)}
                                                 children={'Para descargar el informe ejecutivo presiona AQUI'}
                                                 className={'fg-2'}/>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </Modal.Body>
            </Modal>}
        </>
    );
};

export default Publication;
