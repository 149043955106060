import './icon-card.css';

const IconCard = ({ orientation,img, description}: {img: any, description: string, orientation: string})=>{
    return (
        <>
            <div className='col-8 shadow-left-bottom bg-12 p-5 w-100'>
                <div className='d-flex justify-content-center align-items-center position-relative'>
                    <div className={`d-flex justify-content-center align-items-center position-absolute circle-${orientation}`}>
                        <img src={img} className='img-fluid rounded-circle'/>
                    </div>
                    <div className={`fs-4 fg-1 p-4 text-${orientation}`}>
                        {description}
                    </div>
                </div>
            </div>
        </>
    );
};
export default IconCard;