import {useEffect, useState} from "react";
import {getHttp} from "../../api/HttpClient";
import shortid from "shortid";
import {URLS} from "../../utils/url";
import {titleToLink, utils} from "../../utils/Utils";
import {Link} from "react-router-dom";
import {TITLES} from "../../models/constants/ConstantsUI";


const ImportantAdvices = () => {

    const [advices, setAdvices] = useState<any>([]);
    useEffect(() => {
        (async () => await loadData())();
    }, []);
    const loadData = async () => {
        let {response, success} = await getHttp(URLS.LATEST_NOTICES, {cantidad: 4});
        if (success) {
            setAdvices(response);
        }
    }

    return (
        <>
            <h3 className='title-section fg-1'>Avisos importantes</h3>
            <div className='bg-2 w-100 p-5 mt-5'>
                <div className='container'>
                    <div className='row'>
                        {advices.length > 0 ? advices.map((advice: any) =>
                            <div className='col-lg-3 p-2' key={shortid()}>
                                <img alt={advice.titulo} src={utils(advice.archivo_1)} className='img-fluid'/>
                            </div>
                        ) : null}
                    </div>
                    <div className='text-center'>
                        <Link to={`/${titleToLink(TITLES.NEWS_INFO)}/${titleToLink(TITLES.ADVICES)}`} className='btn btn-danger px-3 py-1 mt-5 text-uppercase'>Ver más</Link>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ImportantAdvices;
