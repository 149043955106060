import {buildCustomImgCss} from "../utils/CustomImgCss";

const SectionTitle = ({title, varImg, extraClass}: { title: string, varImg?: string, extraClass?: string }) => {
    return (
        <div
            className={`bg-title-section d-flex justify-content-center align-items-center${extraClass ? extraClass : ''}`}
            style={buildCustomImgCss(`var(--${varImg != null ? varImg: 'bar-1'})`)}>
            <h3 className="title-section">{title}</h3>
        </div>
    );
};

export default SectionTitle;