import * as React from "react";
import './assets/css/vars.css';
import './assets/css/common.css';
import './assets/css/fonts.css';
import './assets/css/colors.css';
import 'react-multi-carousel/lib/styles.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './App.css';
import 'moment/locale/es';

import {AppRouter} from "./router/AppRouter";
import {Provider} from "react-redux";
import {store} from "./store/store";
import ToastMessage from "./components/toast/ToastMessage";
import {ErrorBoundary} from "react-error-boundary";
import ErrorPage from "./ui/pages/ErrorPage";

function App() {
    return (
        <ErrorBoundary FallbackComponent={ErrorPage}>
            <Provider store={store}>
                <div id="loader-wrapper">
                    <div id="loader"/>
                    <div className="loader-section section-left"/>
                    <div className="loader-section section-right"/>
                </div>
                <ToastMessage/>
                <AppRouter/>
            </Provider>
        </ErrorBoundary>
    );
}

export default App;
