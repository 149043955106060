import SectionTitle from "../../components/SectionTitle";
import {TITLES} from "../../models/constants/ConstantsUI";
import useFetchWithLoading from "../../hooks/useFetchWithLoading";
import {HttpMethod} from "../../models/enums/HttpMethod";
import {URLS} from "../../utils/url";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import shortid from "shortid";
import {utils} from "../../utils/Utils";
import Html from "../../components/Html";

const GalleryInfo = () => {
    const {id} = useParams();
    const [data, loadData] = useFetchWithLoading({
        request: {
            type: HttpMethod.GET,
            url: URLS.IMAGES_GALLERY + '/' + id
        },
        initial: null
    });
    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <SectionTitle title={TITLES.EVENT_PHOTOS}/>
            <div className='container'>
                {data && <div>
                    <h3 className='my-4 title-2'>{data.titulo}</h3>
                    <div className='row my-3'>
                        {data.imagenes.map((imagen: any) =>
                            <div className='col-xxl-4 col-sm-12' key={shortid()}>
                                <img className='img-fluid' src={utils(imagen.archivo)}/>
                            </div>
                        )}
                    </div>
                    <div className='description-1 my-3'>
                        <Html body={data.cuerpo}/>
                    </div>
                </div>}
            </div>
        </>
    );
};
export default GalleryInfo;