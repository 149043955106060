import './not-found.css';
import {Link} from "react-router-dom";

const NotFound = () => {
    return (
        <div className='not-found'>
                <header className="top-header">
                </header>


                <div>
                    <div className="starsec"/>
                    <div className="starthird"/>
                    <div className="starfourth"/>
                    <div className="starfifth"/>
                </div>


                <div className="lamp__wrap">
                    <div className="lamp">
                        <div className="cable"/>
                        <div className="cover"/>
                        <div className="in-cover">
                            <div className="bulb"/>
                        </div>
                        <div className="light"/>
                    </div>
                </div>

                <section className="error">

                    <div className="error__content">
                        <div className="error__message message">
                            <h1 className="message__title">Página no encontrada</h1>
                            <p className="message__text">Lo sentimos, la página que buscaba no se encuentra aquí.
                                El enlace que siguió puede estar roto o ya no existe. Vuelva a intentarlo más tarde.</p>
                        </div>
                        <div className="error__nav e-nav">
                            <Link to="/" className="e-nav__link"/>
                        </div>
                    </div>


                </section>


        </div>
    );
};
export default NotFound;
