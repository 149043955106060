import './senator-card.css';
import CustomImage from "../CustomImage";
import {Link} from "react-router-dom";
import {utils} from "../../utils/Utils";

const SenatorCard = ({fullName, alt, src, benchName, brigade, id, back}: {
    fullName: string, alt: string,
    src: string, benchName: string, brigade: string, id: number, back: string
}) => {
    return (
        <Link to={`${back}area-legislativa/pleno-camaral/${id}`}>
            <div className='card card-1 d-flex flex-column align-items-center mb-4 text-center'>
                <div className='w-0 border-img border-img-bottom mt-4'>
                    <CustomImage className='img-fluid p-1p bg-3 card-1-img w-100' alt={alt}
                                 src={utils(src)}/>
                </div>
                <div className='fullName mt-1'>{fullName}</div>
                <div className='bench'>{benchName}</div>
                {brigade && <div className='mb-4 city'>{brigade}</div>}
                {!brigade && <div className='mb-4 city'>&nbsp;</div>}
            </div>
        </Link>
    );
};
export default SenatorCard;
