import {ISearcher, ISearcherType} from "../../models/interfaces/ISearcher";
import {useState} from "react";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {ISource} from "../../models/interfaces/IBasicTable";
import {DateRangePicker} from "react-date-range";
import {es} from 'date-fns/locale'
import {dateRangeToString} from "../../utils/Utils";
import moment from "moment";
import shortid from "shortid";
import {PER_PAGE} from "../../models/constants/Constants";

const animatedComponents = makeAnimated();

const Searcher = ({
                      searches,
                      action,
                      sources,
                  }: { searches?: ISearcher[], action?: any, sources: any }) => {
    const [parameters, setParameters] = useState<any>({per_page: PER_PAGE});
    const [isSearch, setSearch] = useState(false);
    const handleFilters = (field: string, value: any) => {
        let val;
        if (Array.isArray(value)) {
            val = value.length > 0 ? value : null;
        } else {
            if (typeof value === 'string') {
                val = value.trim().length > 0 ? value : null;
            } else {
                val = value;
            }
        }
        if (val == null) {
            delete parameters[field];
        } else {
            parameters[field] = val;
        }
        setParameters(parameters);
        if (Object.keys(parameters).length === 1 && isSearch) {
            search(false);
            setSearch(false);
        }
    };

    const search = (search = true) => {
        if (action) {
            const params: any = {};
            Object.keys(parameters).forEach(key => {
                const current = searches?.find(search => search.field === key);
                if (Array.isArray(parameters[key])) {
                    if (current && current.type === ISearcherType.DATE) {
                        params[key] = parameters[key];
                    } else {
                        params[key] = parameters[key].map((p: any) => p.value);
                    }
                } else {
                    params[key] = parameters[key];
                }
            });
            action(params);
            if (search && Object.keys(params).length > 1) {
                setSearch(true);
            }
        }
    };
    return (
        <div className='container mt-5'>
            <div className='row'>
                {searches ? searches.map((search) => <RenderSearch key={shortid()} search={search}
                                                                   handler={handleFilters}
                                                                   sources={sources}
                                                                   currentFilter={parameters}/>) : null}
            </div>
            {searches && <div>
                <button className='btn search-button' onClick={() => {
                    search();
                }}>Buscar
                </button>
            </div>}
        </div>
    );
};
export default Searcher;

const RenderSearch = ({
                          search,
                          handler,
                          sources,
                          currentFilter,
                      }: { search: ISearcher, handler: any, sources: any, currentFilter: any, }) => {
    return (<div className={`${search.clazz} my-2`}>
        {search.type === ISearcherType.TEXT &&
        <TextSearcher label={search.label} field={search.field} fieldType={search.fieldType}
                      filterValue={currentFilter[search.field]}
                      handler={handler}/>}
        {search.type === ISearcherType.DATE &&
        <DateSearcher label={search.label} field={search.field}
                      filterValue={currentFilter[search.field]}
                      handler={handler}/>}
        {search.type === ISearcherType.LIST &&
        <ListSearcher sources={sources} handler={handler} field={search.field} label={search.label}
                      filterValue={currentFilter[search.field]}
                      source={search.source!}/>}
    </div>);
};
const TextSearcher = ({
                          label,
                          fieldType,
                          handler,
                          field,
                          filterValue,
                      }: { label: string, fieldType?: string, handler: any, field: string, filterValue: any }) => {
    const [value, setValue] = useState(filterValue);
    return (
        <>
            <div className='d-flex align-items-center h-100 justify-content-between align-items-center flex-column'>
                <label className='form-label text-capitalize label-search fw-bolder'>{label}:</label>
                <div className='search-container'>
                    <input onChange={(event) => {
                        setValue(event.target.value);
                        if (handler) {
                            handler(field, event.target.value);
                        }
                    }} type={fieldType || 'text'} className='search mx-2 fs-4' value={value}/>
                </div>
            </div>
        </>
    );
};
const DateSearcher = ({
                          label,
                          handler,
                          field,
                          filterValue
                      }: { label: string, handler: any, field: string, filterValue: any }) => {
    const [date, setDate] = useState<any>(filterValue);
    const [state, setState] = useState<any>([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
            change: false
        }
    ]);
    const [visible, setVisible] = useState(false);
    return (
        <>
            <div className='d-flex align-items-center h-100 justify-content-between align-items-center flex-column'>
                <label className='form-label text-capitalize label-search'>{label}:</label>
                <div className='d-flex flex-column w-100 position-relative'>
                    <div className='search-container w-100 d-flex justify-content-between'>
                        <input type='text' className='w-100' readOnly={true} value={dateRangeToString(date)}/>
                        <button className='btn btn-rounded rounded-circle bg-9 mx-1' disabled={visible}
                                onClick={() => setVisible(true)}>
                            <i className="fa fa-calendar" aria-hidden="true"/>
                        </button>
                        <button className='btn rounded-circle bg-9' disabled={visible} onClick={() => {
                            if (handler) {
                                handler(field, null);
                            }
                            setDate(null);
                        }}><i className="fa fa-times" aria-hidden="true"/>
                        </button>
                    </div>
                    {visible && <div className='d-flex w-100 flex-column date-picker-container'>
                        <DateRangePicker className='d-flex'
                                         onChange={item => {
                                             const newState: any = item.selection;
                                             newState.change = true;
                                             setState([newState])
                                         }}
                                         moveRangeOnFirstSelection={false}
                                         months={1}
                                         ranges={state}
                                         staticRanges={[]}
                                         inputRanges={[]}
                                         locale={es}
                        />
                        <div className='d-flex justify-content-center bg-3 py-2'>
                            <button className='btn btn-secondary' onClick={() => {
                                setVisible(false);
                            }}>Cancelar
                            </button>
                            <button className='btn mx-2 btn-primary' onClick={() => {
                                if (state[0].change) {
                                    const start = moment(state[0].startDate).startOf('day').format('yyyy-MM-DD HH:mm:ss');
                                    const end = moment(state[0].endDate).endOf('day').format('yyyy-MM-DD HH:mm:ss');
                                    if (handler) {
                                        handler(field, [start,
                                            end]);
                                    }
                                    setDate(state[0]);
                                }
                                setVisible(false);
                            }}>Aceptar
                            </button>
                        </div>
                    </div>}
                </div>
            </div>
        </>
    );
}

const ListSearcher = ({
                          label,
                          handler,
                          field,
                          sources,
                          source,
                          filterValue
                      }: { label: string, handler: any, field: string, sources: any, source: ISource, filterValue: any }) => {
    const [value, setValue] = useState(filterValue || []);
    if (sources === null) {
        return null;
    }
    const current = sources[source.name] != null ? sources[source.name] : [];
    const options: any[] = [];
    const sourceLabel = source.labels;
    current.forEach((option: any) => {
        let name;
        if (Array.isArray(sourceLabel)) {
            const vals: string[] = [];
            sourceLabel.forEach(f => {
                vals.push(option[f]);
            });
            name = vals.join(' ').replace(/\s{2,}/g, ' ');
        } else {
            name = option[sourceLabel];
        }
        options.push({
            value: option[source.key],
            label: name
        });
    });
    return (
        <>
            <div className='d-flex justify-content-center align-items-center flex-column'>
                <label className='form-label text-capitalize label-search fw-bolder'>{label}:</label>
                <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={options}
                    className='w-100 mx-2'
                    placeholder={'Seleccionar...'}
                    onChange={(val) => {
                        setValue(val);
                        if (handler) {
                            handler(field, val);
                        }
                    }}
                    value={value}
                />
            </div>
        </>
    );
}
