import {CSSProperties} from "react";

interface CustomImgCss extends CSSProperties {
    '--img': any;
}

export function buildCustomImgCss(imgVar: any): CustomImgCss {
    return {
        '--img': imgVar
    };
}