import GenericDescription from "../../generic-description/GenericDescription";
import {GESTION} from "../../../mocks/StaticMocks";

const Gestion = ()=>{
    return (
        <>
        <GenericDescription title={GESTION.title} varImg={GESTION.imgUrl} description={GESTION.description} subtitle='' />
        </>
    );
};
export default Gestion;