import SectionTitle from "../../components/SectionTitle";
import {TITLES} from "../../models/constants/ConstantsUI";
import img from '../../assets/img/guided-visit/imaggen.jpg';
import {Form} from "react-bootstrap";
import {ErrorMessage} from "../../components/ErrorMessage";
import {useEffect, useState} from "react";
import {postHttp} from "../../api/HttpClient";
import {statusCode} from "../../helpers/Constantes";
import TargetBlank from "../../components/TargetBlank";
import ReCAPTCHA from "react-google-recaptcha";
import {environment} from "../../utils/environment";
import {types} from "../../types/types";
import {useDispatch} from "react-redux";
import useFetchWithLoading from "../../hooks/useFetchWithLoading";
import {CATALOG} from "../../models/constants/Catalog";
import {HttpMethod} from "../../models/enums/HttpMethod";
import {utils} from "../../utils/Utils";

const iniModel = {
    entidad: '',
    procedencia: '',
    nombres: '',
    email: '',
    fecha_visita: '',
    archivo: '',
    cantidad_visitantes: '',
    token: '',
};

const GuidedVisit = () => {
    const [data, loadData] = useFetchWithLoading({
        request: {
            url: CATALOG.GUIDED_VISITS_DOCUMENT,
            type: HttpMethod.GET
        },
        initial: null
    });
    const [model, setModel] = useState(iniModel);
    const dispatch = useDispatch();
    useEffect(() => {
        (async () => (await loadData()))();
    }, []);
    const [modelMessage, setModelMessage] = useState({
        entidad: '',
        procedencia: '',
        nombres: '',
        email: '',
        fecha_visita: '',
        archivo: '',
        cantidad_visitantes: '',
        token: '',
    });
    const inputChange = ({target}: any) => {
        setModel({
            ...model,
            [target.name]: target.value
        });
    }
    const onSend = async () => {
        if (model.token.trim().length === 0) {
            setModelMessage({
                ...modelMessage,
                token: 'No marco la casilla es robot'
            });
            return;
        } else {
            const verification = await postHttp('verificar-captcha', {token: model.token});
            if (verification.success) {
                const {response, success, error, status} = await postHttp('visita-guiada', model);
                if (success) {
                    setModel(iniModel);
                    setModelMessage(iniModel);
                    dispatch({
                        type: types.toast,
                        payload: {
                            message: 'Solicitud Enviada',
                        }
                    });
                } else {
                    const {errors} = error;
                    if (status === statusCode.code_422) {
                        const errorModel: any = {};
                        for (let key in errors) {
                            errorModel[key] = errors[key].join('<br>');
                        }
                        setModelMessage(errorModel);
                    }
                }
            } else {
                dispatch({
                    type: types.toast,
                    payload: 'La casilla del captcha expiro'
                });
            }
        }
    }
    const handleCaptcha = (value: any) => {
        setModel({
            ...model,
            token: value
        });
        const aux: any = Object.assign({}, modelMessage);
        delete aux.token;
        setModelMessage(aux);
    }
    return (
        <>
            <SectionTitle title={TITLES.GUIDED_VISITS}/>
            <div className='container mt-5'>
                <h3 className='title-1'>Visitas</h3>
                <p className='description-1 mt-4'>
                    Las visitas guiadas al edificio legislativo tienen por objetivo generar un espacio de acercamiento
                    al Poder Legislativo, para que los visitantes conozcan dónde y cómo trabajan los representantes
                    del pueblo en la confección de las leyes que nos permiten ampliar nuestros derechos y fortalecer
                    la democracia.
                </p>
                <p className='description-1 mb-4'>
                    Para la asistencia se deberá cumplir con todas las medidas de prevención del Covid-19: uso correcto
                    del tapabocas, mantener la distancia, uso de alcohol en gel, respeto de las indicaciones.
                </p>
                <img src={img} alt={TITLES.GUIDED_VISITS} className='img-fluid'/>
                <div className='row my-5 d-flex justify-content-between'>
                    <div className='col-xxl-5 col-sm-12'>
                        <h3 className='title-1'>
                            SISTEMA DE SOLICITUD
                        </h3>
                        <p className='description-1'>
                            Para brindarles un mejor servicio, les pedimos que envíen su solicitud con 15 días de
                            anticipación, que sean puntuales en su cita
                            y que atiendan las normas de conducta previstas en el Reglamento del Senado.
                        </p>
                    </div>
                    <div className='col-xxl-5 col-sm-12'>
                        <h3 className='title-1'>
                            REQUISITOS PARA EL INGRESO
                        </h3>
                        <p className='description-1'>
                            Las personas que ingresen al Congreso Nacional en su calidad de visitantes deben
                            cumplir con las siguientes normas:
                        </p>
                    </div>
                    <div className='col-12 mt-5'>
                        <div className='contact'>
                            <h1 className="mb-3 form-title">{TITLES.FORM_GUIDED_VISIT}</h1>
                            <Form className='contact-form'>
                                <div className='row d-flex justify-content-between'>
                                    <Form.Group className="mb-3 col-xxl-5 col-sm-12">
                                        <Form.Control
                                            type="text"
                                            placeholder="Institución o Entidad"
                                            name="entidad"
                                            autoComplete="off"
                                            className='py-3'
                                            required
                                            value={model?.entidad}
                                            autoFocus
                                            onChange={inputChange}
                                        />
                                        <ErrorMessage error={modelMessage.entidad}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3 col-xxl-5 col-sm-12">
                                        <Form.Control
                                            type="text"
                                            placeholder="dd/mm/aaaa"
                                            name="fecha_visita"
                                            className='py-3'
                                            autoComplete="off"
                                            required
                                            value={model?.fecha_visita}
                                            autoFocus
                                            onChange={inputChange}
                                        />
                                        <ErrorMessage error={modelMessage.fecha_visita}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3 col-xxl-5 col-sm-12">
                                        <Form.Control
                                            type="text"
                                            placeholder="Lugar de Procedencia"
                                            name="procedencia"
                                            className='py-3'
                                            autoComplete="off"
                                            required
                                            value={model?.procedencia}
                                            autoFocus
                                            onChange={inputChange}
                                        />
                                        <ErrorMessage error={modelMessage.procedencia}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3 col-xxl-5 col-sm-12">
                                        <Form.Control
                                            type="text"
                                            placeholder="Cantidad de visitantes"
                                            name="cantidad_visitantes"
                                            className='py-3'
                                            autoComplete="off"
                                            required
                                            value={model?.cantidad_visitantes}
                                            autoFocus
                                            onChange={inputChange}
                                        />
                                        <ErrorMessage error={modelMessage.cantidad_visitantes}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3 col-xxl-5 col-sm-12">
                                        <Form.Control
                                            type="text"
                                            placeholder="Nombre del coordinador"
                                            name="nombres"
                                            className='py-3'
                                            autoComplete="off"
                                            required
                                            value={model?.nombres}
                                            autoFocus
                                            onChange={inputChange}
                                        />
                                        <ErrorMessage error={modelMessage.nombres}/>
                                    </Form.Group>
                                    <Form.Group className="mb-3 col-xxl-5 col-sm-12 d-flex">
                                        <TargetBlank href={data!= null ? utils(data.url): ''} className='btn btn-info'>Carta Ejemplo</TargetBlank>&nbsp;
                                        <div>
                                            <Form.Control
                                                type="file"
                                                placeholder="Carta"
                                                name="archivo"
                                                className='py-3'
                                                autoComplete="off"
                                                required
                                                value={model?.archivo}
                                                autoFocus
                                                onChange={inputChange}
                                            />
                                            <ErrorMessage error={modelMessage.archivo}/>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mb-3 col-xxl-5 col-sm-12">
                                        <Form.Control
                                            type="text"
                                            placeholder="Correo electrónico"
                                            name="email"
                                            className='py-3'
                                            autoComplete="off"
                                            required
                                            value={model?.email}
                                            autoFocus
                                            onChange={inputChange}
                                        />
                                        <ErrorMessage error={modelMessage.email}/>
                                    </Form.Group>
                                    <div className='mb-3 col-xxl-5 col-sm-12'>
                                        <ReCAPTCHA
                                            sitekey={`${environment.sitekey}`}
                                            onChange={handleCaptcha}
                                        />
                                        <ErrorMessage error={modelMessage.token}/>
                                    </div>
                                    <div className="form-group d-flex justify-content-center">
                                        <button type="button" className="btn rounded bg-2 fg-3 fs-3 py-2 px-5"
                                                onClick={() => onSend()}>
                                            Enviar
                                        </button>
                                    </div>
                                </div>
                            </Form>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default GuidedVisit;