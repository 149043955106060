import {BAR_1, BAR_2, BAR_3} from "../models/constants/Constants";

export const RRHHMock = {
    title: 'Recursos Humanos',
    subtitle: 'Recursos Humanos',
    description: [`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper \\n' +
                                                    'suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto \\n' +
                                                    'odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.`,
        `Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper \\n' +
                                                        'suscipit lobortis nisl ut aliquip ex ea commodo consequat.`],
    imgUrl: BAR_1
};
export const ADMINISTRATIVE_AREA = {
    title: 'ÁREA ADMINISTRATIVA',
    subtitle: 'ÁREA ADMINISTRATIVA',
    description: [`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper 
suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto 
odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.`,
        `lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper 
suscipit lobortis nisl ut aliquip ex ea commodo consequat.`],
    imgUrl: BAR_1
};
export const AUDIT = {
    title: 'AUDITORÍA',
    subtitle: 'AUDITORÍA',
    description: [`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper 
suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto 
odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.`,
        `Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper 
suscipit lobortis nisl ut aliquip ex ea commodo consequat.`],
    imgUrl: BAR_1
};

export const SAYCO_AUDITS = {
    title: 'AUDITORÍAS SAYCO',
    subtitle: 'AUDITORÍAS SAYCO',
    description: [`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper 
suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto 
odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.`,
        `Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper 
suscipit lobortis nisl ut aliquip ex ea commodo consequat.`],
    imgUrl: BAR_1
};

export const HIRING = {
    title: 'Contrataciones',
    subtitle: 'Contrataciones',
    description: [`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper 
suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto 
odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
`,
        `Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper 
suscipit lobortis nisl ut aliquip ex ea commodo consequat.`],
    imgUrl: BAR_1
};

export const FINANCE = {
    title: 'FINANZAS',
    subtitle: 'FINANZAS',
    description: [`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper 
suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto 
odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
`,
        `Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper 
suscipit lobortis nisl ut aliquip ex ea commodo consequat.`],
    imgUrl: BAR_1
};

export const PLANNING = {
    title: 'PLANIFICACIÓN',
    subtitle: 'PLANIFICACIÓN',
    description: [`Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper 
suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto 
odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
`,
        `Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper 
suscipit lobortis nisl ut aliquip ex ea commodo consequat.`],
    imgUrl: BAR_1
};

export const FISCALIZATION = {
    title: 'FISCALIZACIÓN',
    subtitle: 'CONSULTA A LOS ÓRGANOS DEL ESTADO',
    description: [`Artículo 150. (Obligación de prestar información). Las Autoridades y Servidores Públicos del Órgano Ejecutivo, 
Órgano Electoral, Órgano Judicial, Consejo de la Magistratura, Contraloría General del Estado, Fiscal General 
del Estado, Procuraduría General del Estado y Defensoría del Pueblo tienen la obligación de prestar información o colaboración ante las consultas realizadas por las Comisiones o Comités de la Cámara de Senadores.`,
        `Asimismo, deberán prestar información o colaboración todas las autoridades de la Entidades Territoriales Autónomas, instituciones públicas y personas colectivas o naturales, en el marco de la Constitución y las leyes 
vigentes.`,
        `Las Consultas serán remitidas vía Presidencia de la Cámara de Senadores`],
    imgUrl: BAR_1
};

export const GESTION = {
    title: 'GESTIÓN',
    description: [`El Área Legislativa de la Cámara de Senadores está compuesta por la organización legislativa, compuesta 
por el Pleno Camaral, la Directiva, las Comisiones Permanentes y Especiales, la composición de Brigadas y 
Bancadas al interior del Legislativo.`,
        `Del mismo modo se dispone información acerca la Secretaría General, el trabajo en Sesiones Ordinarias, 
leyes promulgadas, sancionadas y aprobadas, proyectos de ley en tratamiento y rechazados, el trabajo de 
fiscalización que desarrollan legisladores a través de peticiones de informe escrito y oral e interpelaciones.`,
        `"Participación y Deliberación Ciudadana" es la sección innovadora de esta nueva versión web de la Cámara 
de Senadores, destinada a la atención de la población, compuesta por los mecanismos de participación, comisiones permanentes y el material de difusión de la unidad.`],
    imgUrl: BAR_3
};

export const GENERAL_SECRETARY = {
    title: 'Secretaría General',
    description: [`Secretaría General de la Cámara de Senadores, tiene como objetivo velar por el correcto procesamiento de la 
documentación legislativa y los instrumentos camarales asistiendo a Presidencia y Directiva en Sesiones de 
la Cámara de Senadores y apoyando en el funcionamiento de las mismas.`,
        `Asimismo, en conformidad al Reglamento General de la Cámara de Senadores en su Art. 170, Secretaría General dirige el Sistema de Investigación y Asesoramiento de la Cámara de Senadores, prestando apoyo técnico 
especializado al Pleno Camaral, Directiva, Comisiones, Comités, Brigadas Departamentales, Bancadas políticas y a todas las Senadoras y Senadores que lo requieran`,
        `Actualmente, Secretaría General está conformada por Unidades como la de Hemiciclo, la de Seguimiento, 
Control Legislativo y Redacción, la de Análisis Legislativo, la de Participación y Deliberación Ciudadana, y la de 
Archivo, que contribuyen al cumplimiento constitucional de las atribuciones asignadas a la Cámara de Senadores.`],
    imgUrl: BAR_3
};

