import * as React from "react";
import {Home} from "../ui/home/Home";
import {AdministrativeRoutes} from "./AdministrativeRoutes";
import {DropDown, LinkType, MegaMenu, SimpleLink} from "../models/interfaces/NavConfig";
import {LegislativeRoutes} from "./LegislativeRoutes";
import {InstitutionalRoutes} from "./InstitutionalRoutes";
import {NewsRoutes} from "./NewsRoutes";
import {PublicConsultationRoutes} from "./PublicConsultationRoutes";
import {TITLES} from "../models/constants/ConstantsUI";
import {titleToLink} from "../utils/Utils";
import Presidency from "../ui/presidencia/Presidency";


export const NAV_ROUTES: (SimpleLink | DropDown | MegaMenu)[] = [
    {
        title: 'Inicio',
        link: '/',
        component: <Home/>,
        type: LinkType.SIMPLE_LINK,
        clazz: 'root-link'
    },
    {
        title: TITLES.PRESIDENCY,
        link: titleToLink(TITLES.PRESIDENCY),
        component: <Presidency />,
        clazz: 'root-link',
        type: LinkType.SIMPLE_LINK
    },
    InstitutionalRoutes,
    LegislativeRoutes,
    AdministrativeRoutes,
    NewsRoutes,
    PublicConsultationRoutes,
];