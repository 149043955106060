import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import SectionTitle from "../../../components/SectionTitle";
import Html from "../../../components/Html";
import {URLS} from "../../../utils/url";
import CardZoomEffect from "../../../components/cards/CardZoomEffect";
import {formatDate, reduceString, removeTags, titleToLink, utils} from "../../../utils/Utils";
import shortId from "shortid";
import Carousel from "react-multi-carousel";
import './news.css';
import {CATALOG} from "../../../models/constants/Catalog";
import useFetchManyWithLoading from "../../../hooks/useFetchManyWithLoading";
import {HttpMethod} from "../../../models/enums/HttpMethod";
import {useDispatch} from "react-redux";
import {TITLES} from "../../../models/constants/ConstantsUI";
import {types} from "../../../types/types";
import {DATE_FORMAT2} from "../../../models/constants/Constants";

const News = ({newInfo, actionCategories}: { newInfo?: any, actionCategories?: any }) => {
    const {id} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const getRequests = () => {
        const requests = [
            {url: `${URLS.NEWS}/${id}`, type: HttpMethod.GET, key: 'currentNew'},
            {
                url: URLS.LAST_NEWS,
                parameters: {cantidadNoticias: 6, exclude: newInfo ? newInfo.id : id},
                type: HttpMethod.GET,
                key: 'lastNews',
                formatter: (ultimasNoticias: any) => {
                    const response: any[] = [];
                    ultimasNoticias.forEach((element: any) => {
                        const img = element.imagenes && element.imagenes.length > 0 ? utils(element.imagenes[0].archivo) : '';
                        response.push({
                            title: element.titulo,
                            description: reduceString(removeTags(element.cuerpo), 50),
                            date: element.fecha_publicacion,
                            imgUrl: img,
                            href: `/${titleToLink(TITLES.NEWS_INFO)}/${titleToLink(TITLES.NEWS)}/${element.id}`,
                            linkTitle: `Ir a la noticia`,
                        })
                    });
                    return response;
                },
            },
            {url: CATALOG.NEWS_CATEGORIES, type: HttpMethod.GET, key: 'categories'},
        ];
        if (!newInfo && !id) {
            requests.splice(0, 2);
        }else{
            if(newInfo){
                requests.splice(0,1);
            }
        }
        return requests;
    }

    const [data, loadData] = useFetchManyWithLoading({
        requests: getRequests(),
        initial: {}
    });
    const fetchData = () => {
        return {
            currentNew: newInfo != null ? newInfo : (data.currentNew != null ? data.currentNew : ( data.categories != null ? {categoria: {id: data.categories[0].id}}: null)),
            lastNews: data.lastNews,
            categories: data.categories,
        }
    };
    const {currentNew, lastNews, categories} = fetchData();
    useEffect(() => {
        (async () => await loadData())();
    }, []);
    const goTo = (id: number) => {
        if (actionCategories) {
            actionCategories(id);
        } else {
            dispatch({
                type: types.setSubMenu,
                payload: id
            })
            navigate(`/${titleToLink(TITLES.NEWS_INFO)}/${titleToLink(TITLES.NEWS)}`);
        }
    };
    return (
        <>
            <div id="loader-wrapper">
                <div id="loader"/>

                <div className="loader-section section-left"/>
                <div className="loader-section section-right"/>

            </div>
            <SectionTitle title={'Noticias'}/>
            <div className='container my-5'>
                    <div className='d-flex justify-content-center flex-wrap my-3'>
                        {categories && categories.map((category: any) =>
                            <a type='button' key={`news_category_${category.id} `}
                               className={`text-capitalize btn btn-category p-2 mx-2 my-2 ${currentNew.categoria?.id === category.id ? 'active' : ''}`}
                               onClick={() => {
                                   goTo(category.id)
                               }}>
                                {category.name}
                            </a>)}
                    </div>
                {currentNew && currentNew.titulo &&
                <>
                    <div className='row'>
                        <div className='col-xxl-8 col-sm-12'>
                            <h3 className='title-1'>
                                {currentNew.titulo}
                            </h3>
                            <ImagesCarrousel images={currentNew.imagenes}/>
                            <div className='bg-13 mt-3'>
                                <h3 className='title-2 font-4 p-2 text-capitalize'>{TITLES.NEWS_SENATE} | {formatDate(currentNew.fecha_publicacion, DATE_FORMAT2)} | {
                                    currentNew.categoria?.name
                                } </h3>
                            </div>
                            <div className='description-1'>
                                <Html
                                    body={currentNew.cuerpo.replace(/(background-)?color: rgb\(\d+,\d+,\d+\);|font-size: \d+px;|font-family: ([a-zA-Z ",]+;)/g, '')}/>
                            </div>
                        </div>
                        <div className='col-xxl-4 col-sm-12'>
                            <h3 className='title-1 text-uppercase text-center'>
                                Ultimas noticias
                            </h3>
                            <div className='d-flex flex-column'>
                                {lastNews && <>
                                    {lastNews.map((current: any) =>
                                        <CardZoomEffect key={shortId()} imgUrl={current.imgUrl}
                                                        title={current.title}
                                                        date={current.date}
                                                        description={current.description}
                                                        href={current.href}
                                                        linkTitle={current.linkTitle}/>
                                    )}
                                </>}
                            </div>
                        </div>
                    </div>
                </>}
            </div>
        </>
    );
};
const ImagesCarrousel = ({images}: { images: any[] }) => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: {max: 4000, min: 3000},
            items: 1,
            partialVisibilityGutter: 0
        },
        desktop2: {
            breakpoint: {max: 3000, min: 1200},
            items: 1,
            partialVisibilityGutter: 0
        },
        tablet1: {
            breakpoint: {max: 1200, min: 464},
            items: 1,
            partialVisibilityGutter: 0
        },
        tablet2: {
            breakpoint: {max: 800, min: 580},
            items: 1,
            partialVisibilityGutter: 0
        },
        mobile: {
            breakpoint: {max: 580, min: 0},
            items: 1,
            partialVisibilityGutter: 0
        }
    };
    return (
        <div>
            {images.length > 1 && <Carousel
                additionalTransfrom={0}
                arrows={false}
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="d-flex flex-wrap flex-column"
                dotListClass=""
                draggable
                focusOnSelect
                infinite={true}
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                partialVisible
                pauseOnHover
                autoPlay={true}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={responsive}
                rewind={false}
                rewindWithAnimation={false}
                // rtl
                shouldResetAutoplay
                showDots={true}
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >
                {images && images.map(image =>
                    <div className='img-carrousel' key={shortId()}>
                        <img
                            key={shortId()} src={utils(image.archivo)} className='img-fluid w-100'
                        /></div>)}
            </Carousel>}
            {images.length <= 1 && images.map(image =>
                <div className='img-carrousel d-flex justify-content-center align-items-end' key={shortId()}>
                    <img
                        key={shortId()} src={utils(image.archivo)} className='img-fluid w-100'
                    /></div>)}
        </div>
    );
};
export default News;
