import {useState} from "react";
import {getHttp, postHttp} from "../api/HttpClient";
import {HttpMethod} from "../models/enums/HttpMethod";
import {IRequest} from "../models/interfaces/IRequest";


const useFetchWithLoading = ({
                                 request,
                                 initial,
                                 loading = true
                             }: { request: IRequest, initial: any, loading?: boolean }) => {
    const [data, setData] = useState<any>(initial);
    const [pagination, setPagination] = useState<any>({
        list: [],
        pages: 0,
        perPage: 0
    });
    const changeLoading = (hidden: boolean) => {
        if (loading) {
            if (hidden) {
                document.body.classList.remove('loader');
                document.body.classList.add('loaded');
            } else {
                document.body.classList.remove('loaded');
                document.body.classList.add('loader');
            }
        }
    };

    const loadData = async (parameters?: any) => {
        changeLoading(false);
        const promises: any[] = [];
        switch (request.type) {
            case HttpMethod.GET:
                promises.push(getHttp(request.url, parameters || request.parameters));
                break;
            case HttpMethod.POST:
                promises.push(postHttp(request.url, parameters || request.parameters));
                break;
        }
        const response = await Promise.all(promises);
        const [res] = response;
        if (request.formatter) {
            res.response = request.formatter(res.response);
        }
        if (res.success) {
            setPagination({
                list: res.response?.data,
                pages: res.response.last_page,
                perPage: res.response.per_page
            });
        }
        changeLoading(true);
        setData(res.response);
    };
    return [data, loadData, pagination];
};
export default useFetchWithLoading;
