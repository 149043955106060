import useFetchWithLoading from "../../../hooks/useFetchWithLoading";
import {Link, useParams} from "react-router-dom";
import {IRequest} from "../../../models/interfaces/IRequest";
import {HttpMethod} from "../../../models/enums/HttpMethod";
import {useEffect, useState} from "react";
import CustomImage from "../../../components/CustomImage";
import {formatDate, titleToLink, utils} from "../../../utils/Utils";
import shortid from "shortid";
import {TITLES} from "../../../models/constants/ConstantsUI";
import {URLS} from "../../../utils/url";
import MediaLink from "../../../components/MediaLink";
import {DATE_FORMAT1} from "../../../models/constants/Constants";
import TargetBlank from "../../../components/TargetBlank";

const SenatorInfo = () => {
    const {id} = useParams();
    const [state, setState] = useState(id);
    const request: IRequest = {
        type: HttpMethod.GET,
        url: URLS.SENATOR_LIST + '/' + id,
    };
    const [data, loadData] = useFetchWithLoading({request, initial: []});
    useEffect(() => {
        loadData();
    }, []);
    if (state !== id) {
        setState(id);
        loadData().then()
            .catch((e: any) => {});

    }
    return (
        <>
            <div className='container my-5'>
                <div className='row d-flex justify-content-between'>
                    <div className='col-xxl-4 col-sm-12 my-5 d-flex align-items-center flex-column'>
                        <CustomImage src={utils(data.foto_perfil)} className='img-fluid shadow-left-bottom'/>
                        {data.redes?.length > 0 && <>
                            <label className='description-1 mt-5'>
                                {data.redes && <div>
                                    {data.redes.map((red: any) =>
                                        <MediaLink url={red.name} key={shortid()} circle={false}/>)}
                                </div>}
                            </label>
                        </>}
                    </div>
                    { data && <div className='col-xxl-7 col-sm-11'>
                        <h3 className='title-1 fs-2 text-center'>{data.nombre} {data.apellidos}</h3>
                        <div className='row shadow-left-bottom p-5'>
                            <div className='col-6'>
                                <div className='d-flex flex-column'>
                                    <label className='title-description-1'>Fecha de Nacimiento:</label>
                                    <label
                                        className='description-1 text-capitalize'>{formatDate(data.fecha_nacimiento, DATE_FORMAT1)}</label>
                                    <label className='title-description-1'>Nacido en:</label>
                                    <label
                                        className='description-1'>{data.lugar_nacimiento}</label>
                                    <label className='title-description-1'>Bancada:</label>
                                    <label className='description-1'>{data.bancada?.nombre}</label>
                                    <label className='title-description-1'>Brigada</label>
                                    <label className='description-1'>{data.brigada_catalogo?.name}</label>
                                    <label className='title-description-1'>Senador(a) Titular:</label>
                                    <label
                                        className='description-1'>{data.titular?.name}</label>
                                    <label className='title-description-1'>Correo Electrónico:</label>
                                    <label className='description-1'>{data.email}</label>
                                    {data.cargo_directiva_catalogo && data.cargo_directiva_catalogo?.id && <>
                                        <label className='title-description-1'>Cargo:</label>
                                        <label className='description-1'>{data.cargo_directiva_catalogo?.name}</label>
                                    </>}
                                    {data.suplente_id &&
                                    <RenderTitularSuplente label={'Senador Suplente'} senator={data.suplente_id}/>
                                    }
                                    {data.titular_id &&
                                    <RenderTitularSuplente label={'Senador Titular'} senator={data.titular_id}/>
                                    }
                                </div>
                            </div>
                            <div className='col-6 d-flex justify-content-center align-items-center flex-column'>
                                <img className='img-fluid' src={utils(data.bancada?.image)} alt={data.bancada?.sigla}/>
                            </div>
                        </div>
                        { data.cv &&
                        <div className='mt-5 d-flex justify-content-center'>
                            <TargetBlank style={{background: data.bancada?.brigada_color || '#000'}} className='btn btn-md bg-2 text-uppercase fg-3' href={utils(data.cv)}>Ver más
                            </TargetBlank>
                        </div>}
                    </div>}
                </div>
            </div>
        </>
    );
};
const RenderTitularSuplente = ({label, senator}: { label: string, senator?: any }) => {
    return (<>
        <label className='title-description-1'>{label}:</label>
        {senator && <label className='description-1'>
            <Link className='fg-1'
                  to={`../${titleToLink(TITLES.LEGISLATIVE_AREA)}/${titleToLink(TITLES.PLENARY_SESSION)}/${senator.id}`}>
                {senator?.nombre} {senator?.apellidos}
            </Link>
        </label>}
        {!senator &&
        <label className='description-1'>{senator?.nombre} {senator?.apellidos}</label>
        }
    </>);
};
export default SenatorInfo;
