import {IRequest} from "../../../models/interfaces/IRequest";
import {HttpMethod} from "../../../models/enums/HttpMethod";
import {URLS} from "../../../utils/url";
import {PER_PAGE} from "../../../models/constants/Constants";
import SectionTitle from "../../../components/SectionTitle";
import {TITLES} from "../../../models/constants/ConstantsUI";
import BasicTable from "../../../components/tables/BasicTable";
import {FieldType, IBasicTable} from "../../../models/interfaces/IBasicTable";
import {removeTags} from "../../../utils/Utils";
import ImportantAdvices from "../../important-advices/ImportantAdvices";

const FIELDS: IBasicTable[] = [
    {
        label: 'Título',
        field: 'titulo',
        type: FieldType.DIRECT,
        styles: {width: '300px'},
    },
    {
        label: 'Gestión',
        field: 'gestion',
        type: FieldType.DIRECT,
        styles: {width: '100px'}
    },
    {
        label: 'Descripción',
        field: 'descripcion',
        type: FieldType.DIRECT,
        formatter: removeTags,
    },
    {
        label: 'Archivo',
        field: 'archivo',
        type: FieldType.FILE,
        styles: {width: '120px'}
    }
];
/**
* Seguimientos
* */
const FollowUp = () => {
    const request: IRequest = {
        type: HttpMethod.GET,
        url: URLS.FOLLOW_UP,
        parameters: {
            per_page: PER_PAGE
        }
    };

    return (
        <>
            <SectionTitle title={TITLES.AUDIT_5} varImg={'bar-1'} />
            <BasicTable request={request} fields={FIELDS}/>
            <ImportantAdvices />
        </>
    );
}
export default FollowUp;