import {applyMiddleware, combineReducers, createStore, compose} from 'redux';
import thunk from 'redux-thunk';
import {subMenuReducer} from '../reducers/subMenuReducer';
import {toastReducer} from "../reducers/ToastReducer";
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
// const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducers = combineReducers({
  subMenu: subMenuReducer,
  toast: toastReducer,
});
export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk)),
);
