import {DropDown} from "../models/interfaces/NavConfig";
import {buildDropDown} from "../utils/Utils";
import {TITLES} from "../models/constants/ConstantsUI";
import {Contact} from "../ui/public-consultation/Contact";
import GuidedVisit from "../ui/public-consultation/GuidedVisit";

export const PublicConsultationRoutes: DropDown =
    buildDropDown({
        title: TITLES.PUBLIC_CONSULTATION,
        link: true,
        clazz: 'root-link',
        links: [
            {
                title: TITLES.GUIDED_VISITS,
                component: <GuidedVisit/>
            },
            {
                title: TITLES.CONTACTS,
                component: <Contact/>
            }
        ]
    });