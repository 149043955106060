import ImportantAdvices from "../../important-advices/ImportantAdvices";
import {IRequest} from "../../../models/interfaces/IRequest";
import {HttpMethod} from "../../../models/enums/HttpMethod";
import {URLS} from "../../../utils/url";
import {PER_PAGE} from "../../../models/constants/Constants";
import SectionTitle from "../../../components/SectionTitle";
import {TITLES} from "../../../models/constants/ConstantsUI";
import {FieldType, IBasicTable} from "../../../models/interfaces/IBasicTable";
import {removeTags} from "../../../utils/Utils";
import {ISearcher, ISearcherType} from "../../../models/interfaces/ISearcher";
import {SOURCES} from "../../../utils/Sources";
import SourceTable from "../../../components/tables/SourceTable";

const FIELDS: IBasicTable[] = [
    {
        label: 'Título',
        field: 'titulo',
        type: FieldType.DIRECT,
        styles: {width: '200px'},
        link: {
            field: 'id',
        },
    },
    {
        label: 'Proyectistas',
        field: 'proyectista_senador_id',
        type: FieldType.LIST,
        source: {
            name: 'senators',
            key: 'id',
            labels: ['nombre', 'apellidos'],
        }
    },
    {
        label: 'Destinatario',
        field: 'destinatario',
        type: FieldType.DIRECT,
    },
    {
        label: 'Asunto',
        field: 'asunto',
        type: FieldType.DIRECT,
        formatter: removeTags,
    },
    {
        label: 'Gestión',
        field: 'gestion',
        type: FieldType.DIRECT,
        styles: {width: '200px'}
    },
    {
        label: 'Archivo PDF',
        field: 'doc_archivo',
        type: FieldType.FILE,
        styles: {width: '120px'}
    }
];

const searches: ISearcher [] = [
    {
        type: ISearcherType.TEXT,
        label: 'Título',
        field: 'titulo',
        clazz: 'col-6'
    },
    {
        type: ISearcherType.TEXT,
        label: 'Gestión',
        field: 'gestion',
        clazz: 'col-6',
    },
    {
        type: ISearcherType.TEXT,
        label: 'Destinatario',
        field: 'destinatario',
        clazz: 'col-6',
    },
    {
        type: ISearcherType.LIST,
        label: 'Proyectista',
        field: 'proyectista_senador_id',
        clazz: 'col-6',
        source: {
            name: 'senators',
            key: 'id',
            labels: ['nombre', 'apellidos'],
        }
    },
    {
        type: ISearcherType.TEXT,
        label: 'Asunto',
        field: 'asunto',
        clazz: 'col-6'
    },
];
const sources: IRequest[] = [
    {
        type: HttpMethod.GET,
        url: SOURCES.SENATORS_SELECT,
        key: 'senators',
    }
];
const request: IRequest = {
    type: HttpMethod.GET,
    url: URLS.COMMUNICATION_MINUTES,
    parameters: {
        per_page: PER_PAGE
    },
    formatter: (response: any) => {
        const data = response?.data;
        if (data) {
            data.forEach((element: any) => {
                element.proyectista_senador_id = element.proyectista.map((p: any) => p.senador_id);
                delete element.proyectista;
            });
        }
        return response;
    }
};
const CommunicationMinutes = () => {

    return (
        <>
            <SectionTitle title={TITLES.COMMUNICATION_MINUTES} varImg={'bar-1'}/>
            <SourceTable sourceRequest={sources} searches={searches} request={request} fields={FIELDS}/>
            <ImportantAdvices/>
        </>
    );
};
export default CommunicationMinutes;
