import React, {Suspense} from "react";
import Publication from "./Publication";

const LawContainer = React.lazy(() => import("./LawContainer"));
const VideoHome = React.lazy(() => import("./VideoHome"));
const CarouselSenator = React.lazy(() => import("./CarouselSenator"));
const LegislativeCalendar = React.lazy(() => import("./LegislativeCalendar"));
const NewsHome = React.lazy(() => import("./NewsHome"));
export const Home = () => {

    return <>
        <Suspense fallback={<div>Loading...</div>}>
            <Publication />
            <LawContainer/>
            <NewsHome/>
            <CarouselSenator/>
            <LegislativeCalendar/>
            <VideoHome/>
        </Suspense>
    </>
}
