import SectionTitle from "../../../../components/SectionTitle";
import {useEffect} from "react";
import SenatorCard from "../../../../components/senator-card/SenatorCard";
import shortid from "shortid";
import useFetchWithLoading from "../../../../hooks/useFetchWithLoading";
import {HttpMethod} from "../../../../models/enums/HttpMethod";
import {URLS} from "../../../../utils/url";

const SpecialCommissions = () => {
        const [data, loadData] = useFetchWithLoading({
            request: {
                type: HttpMethod.GET,
                url: URLS.SPECIAL_COMMISSIONS,
                formatter: (response: any) => {
                    const first = response.filter((senador: any) => senador.comision_especial_id === 105 && senador.comision_especial != null)
                        .sort((a: any, b: any) => a.comision_especial_order < b.comision_especial_order ? -1 : 1);
                    const second = response.filter((senador: any) => senador.comision_especial_id === 106 && senador.comision_especial != null)
                        .sort((a: any, b: any) => a.comision_especial_order < b.comision_especial_order ? -1 : 1);
                    return {
                        first: first,
                        second: second,
                    }
                }
            },
            initial: null
        });

        useEffect(() => {
            (async () => await loadData())();
        }, []);
        return (
            <>
                <div>
                    <SectionTitle title='Comisiones especiales' varImg='legislative-title-bg'/>
                </div>
                {data && <div className='container mt-5 fg-1'>
                    {data.first.length > 0 && <>
                        <h3 className='text-center title-1'>{data.first[0].comision_especial.name}</h3>
                        <h3 className='title-2 fg-1'>BASE LEGAL</h3>
                        <p className='description-1'>
                            De conformidad a lo establecido en el artículo 12 del Reglamento Participación Ciudadana y
                            Deliberación Pública, se conforma la Comisión Especial y Permanente de Participación Ciudadana
                            compuesta por los siguientes
                            Senadores:
                        </p>
                        <div className='row d-flex justify-content-center mt-5'>
                            {data.first.length > 0 ? data.first.map((item: any) =>
                                <div className='col-lg-3' key={shortid()}>
                                    <SenatorCard fullName={item.nombre + ' ' + item.apellidos}
                                                 alt={item.nombre + ' ' + item.apellidos} src={
                                        item.foto_perfil
                                    } benchName={item.bancada.nombre}
                                                 brigade={item.brigada} id={item.id} back={'../../'}/>
                                </div>
                            ) : null}
                        </div>
                        <h3 className='mt-5 title-2 fg-1'>ATRIBUCIONES</h3>
                        <p className='description-1'>
                            Corresponde a la Comisión Especial y Permanente de Participación Ciudadana el seguimiento a los
                            procesos
                            de participación ciudadana en todos sus mecanismos (Audiencia Pública, Foro Ciudadano, Encuentro
                            Territorial, Difusión Legislativa, Socialización de Política Pública, etc.) y en todas sus
                            fases, es
                            decir, (fase preparatoria,
                            fase de ejecución y fase de seguimiento).
                        </p>
                        <p className='description-1'>
                            En cumplimiento de sus atribuciones, la Comisión Especial y Permanente de Participación
                            Ciudadana
                            tiene a su
                            cargo la coordinación de los requerimientos necesarios para la realización de las diferentes
                            actividades de participación ciudadana realizados por las y los Senadores,así como proponer
                            mecanismos alternativos y procedimientos necesarios para facilitar la participación ciudadana.
                        </p>
                    </>}
                    {data.second.length > 0 && <>
                        <h3 className='text-center title-1'>{data.second[0].comision_especial.name}</h3>
                        <h3 className='title-2 fg-1'>ANTECEDENTES</h3>
                        <p className='description-1'>
                            Compuesta por:
                        </p>
                        <div className='row mb-5'>
                            {data.second.length > 0 ? data.second.map((item: any) =>
                                <div className='col-lg-3' key={shortid()}>
                                    <SenatorCard fullName={item.nombre + ' ' + item.apellidos}
                                                 alt={item.nombre + ' ' + item.apellidos} src={
                                        item.foto_perfil
                                    } benchName={item.bancada.nombre}
                                                 brigade={item.brigada} id={item.id} back={'../../'}/>
                                </div>
                            ) : null}
                        </div>
                        <h3 className='title-2 fg-1'>OBJETO</h3>
                        <p className='description-1'>
                            Tiene por objeto establecer las normas que regulan la conducta y transparencia que deben
                            observar
                            las
                            Senadoras y Senadores del Estado Plurinacional de Bolivia en el ejercicio de sus funciones.
                            Asimismo, norma las instancias de investigación, procedimiento, resolución y sanciones cuando
                            existan contravenciones a las disposiciones establecidas en la Constitución Política del Estado,
                            él
                            Reglamento General de la Cámara de Senadores y
                            en el Reglamento de Ética y Transparencia.
                        </p>
                    </>}
                </div>}
            </>
        );
    }
;
export default SpecialCommissions;
