import {CSSProperties, ReactNode} from "react";
import {BaseLink, DropDown, LinkType, MegaMenu, SimpleLink} from "../models/interfaces/NavConfig";
import {NAV_ROUTES} from "../router/NAV_ROUTES";

interface MegaMenuItem extends CSSProperties {
    menuItems: string;
}

export interface RouteData {
    path: string,
    element: ReactNode
}

export const getMenuItem = (itemSize: number): MegaMenuItem => {
    return {
        menuItems: `${100 / itemSize}%`
    };
};

const dropDownToArray = (dropdown: DropDown): RouteData[] => {
    const routes: RouteData[] = [];
    if (dropdown.link != null && dropdown.component) {
        routes.push({
            path: dropdown.link,
            element: dropdown.component
        });
    }
    dropdown.links.forEach((item) => {
        routes.push({
            path: concatLinks([dropdown, item]),
            element: item.component
        });
    });
    return routes;
};

const simpleLinkToArray = (simpleLink: SimpleLink): RouteData[] => {
    return [
        {
            element: simpleLink.component,
            path: simpleLink.link || '#'
        }
    ];
};
const megaMenuToArray = (megaMenu: MegaMenu): RouteData[] => {
    const routes: RouteData[] = [];
    if (megaMenu.link != null) {
        routes.push({
            path: megaMenu.link,
            element: megaMenu.component,
        });
    }
    megaMenu.megaItems.forEach(item => {
        if (item.link != null && item.component) {
            routes.push({
                path: concatLinks([megaMenu, item]),
                element: item.component
            });
        }
        item.links.forEach(megaItem => {
            routes.push({
                path: concatLinks([megaMenu, item, megaItem]),
                element: megaItem.component
            });
        });
    });
    return routes;
}

export const concatLinks = (links: BaseLink[]) => {
    return links.reduce((acc: string[], current: BaseLink) => {
        if (current.link != null && (current.link || '').trim().length !== 0 && (current.component != null || current.linkeable === true)) {
            acc.push(current.link);
        }
        return acc;
    }, []).join('/');
};

export const concatRoutes = (): RouteData[] => {
    const routes: RouteData[] = [];
    NAV_ROUTES.forEach(r => {
        switch (r.type) {
            case LinkType.SIMPLE_LINK:
                routes.push(...simpleLinkToArray(r as SimpleLink));
                break;
            case LinkType.MEGA_MENU:
                routes.push(...megaMenuToArray(r as MegaMenu));
                break;
            case LinkType.DROP_DOWN:
                routes.push(...dropDownToArray(r as DropDown));
                break;
            default:
                break;
        }
    });
    return routes;
};

export const isLink = (route: BaseLink): boolean => {
    return route.link !== null && (route.link || '').trim().length > 0 && route.component != null;
}
export const getLink = (route: BaseLink): string => {
    return route.link || '';
}
