import leftShield from '../assets/img/header/escudo-izquierdo.png';
import rightShield from '../assets/img/header/escudo-derecho.png';
import monolitoLeft from '../assets/img/header/monolito-left.png';
import monolitoRight from '../assets/img/header/monolito-right.png';
import jaguarLeft from '../assets/img/header/Jaguar_Izquierdo.png';
import jaguarRight from '../assets/img/header/Jaguar_Derecho.png';
import left from '../assets/img/header/detalle_Izquierdo_home.png';
import right from '../assets/img/header/detalle_derecho_home.png';
import whiteBuilding from '../assets/img/white-building.svg';

import headerBar from '../assets/img/header-bar.png';
import {useEffect, useRef, useState} from "react";
import CustomNavBar from "./custom-navbar/CustomNavBar";
import './header-app.css';
import SearchModal from "./SearchModal";

const images = [
    [leftShield, rightShield],
    [monolitoLeft, monolitoRight],
    [left, right],
    [jaguarLeft, jaguarRight],
    
];

const HeaderImages = () => {
    const [position, setPosition] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            setPosition((prevCounter) => prevCounter + 1 === images.length ? 0 : prevCounter + 1);
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <div className='bg-1 my-0 h-50p'/>
            <div className="d-flex bg-1 header-img-container w-100">
                <div className='col-4 d-flex'>
                    <img alt='icono izquierdo' className="img-fluid" src={images[position][0]}/>
                </div>
                <div className='col-4 d-flex justify-content-center'>
                    <img alt='icono central' className="img-fluid" src={whiteBuilding}/>
                </div>
                <div className='col-4 d-flex justify-content-end'>
                    <img alt='icono derecho' className="img-fluid" src={images[position][1]}/>
                </div>
            </div>
        </>
    );
}

const HeaderApp = () => {
    const [isSticky, setIsSticky] = useState(false)
    const navRef = useRef<HTMLInputElement | null>(null);
    // mount
    useEffect(() => {
        const cachedRef = navRef.current;
        if (cachedRef != null) {
            const observer = new IntersectionObserver(
                ([e]) => setIsSticky(e.intersectionRatio < 1),
                {
                    threshold: [1],
                    // rootMargin: '-1px 0px 0px 0px',  // alternativly, use this and set `top:0` in the CSS
                }
            );

            observer.observe(cachedRef)

            // unmount
            return function () {
                observer.unobserve(cachedRef)
            }
        }
    }, [])

    return (
        <>
            <HeaderImages/>
            <CustomNavBar/>
            <SearchModal />
            <div className='bg-1' style={{height: '40px'}}>
                <img alt='barra cabezera' className="img-fluid" src={headerBar}/>
            </div>
        </>
    );
}
export default HeaderApp;
