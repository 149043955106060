import {useEffect} from "react";
import SenatorCard from "../../../components/senator-card/SenatorCard";
import shortid from "shortid";
import SectionTitle from "../../../components/SectionTitle";
import {URLS} from "../../../utils/url";
import useFetchManyWithLoading from "../../../hooks/useFetchManyWithLoading";
import {HttpMethod} from "../../../models/enums/HttpMethod";
import './directiva.css';
import {Link} from "react-router-dom";
import {titleToLink} from "../../../utils/Utils";
import {TITLES} from "../../../models/constants/ConstantsUI";
import {FILTERS} from "../../../models/constants/Filters";

export const Directiva = () => {
    const [data, loadData] = useFetchManyWithLoading({
        requests: [{
            url: URLS.DIRECTIVE,
            type: HttpMethod.GET,
            key: 'senators',
        },
            {
                url: FILTERS.DIRECTIVE_FILTER,
                type: HttpMethod.GET,
                key: 'filters',
                formatter: (res: any) => {
                    if (res.value) {
                        return JSON.parse(res.value);
                    }
                    return [];
                },
                mergeFormatter: ((data: any) => {
                    const senators: any[] = [];
                    data.filters.forEach((f: any) => {
                        const filtered = data.senators.filter((senator: any) => f.includes(senator.cargo_directiva_id));
                        senators.push(filtered);
                    });
                    return {senators};
                })
            }
        ],
        initial: {}
    });
    useEffect(() => {
        (async () => await loadData())();
    }, []);
    return (
        <>
            <SectionTitle title={'DIRECTIVA'} varImg={'bar-2'}/>
            <div>
                <div className='text-center'>
                    <h4 className='title-1 text-capitalize'>Asamblea Legislativa Plurinacional de Bolivia</h4>
                    <h4 className='title-2'>DIRECTIVA LEGISLATURA 2022 -2023</h4>
                </div>
                <div className='directive-line'/>
            </div>
            <div className={'container p-5'}>
                {data.senators && data.senators.map((row: any) =>
                    <div key={shortid()} className='row d-flex justify-content-center'>
                        {row.map((item: any) => <div key={shortid()} className='col-xl-3 col-lg-4 col-md-6 col-sm-12'>
                            <SenatorCard fullName={`${item.nombre} ${item.apellidos}`}
                                         alt={`${item.nombre} ${item.apellidos}`} src={item.foto_perfil}
                                         benchName={item.bancada?.sigla} brigade={item.cargo_directiva_catalogo?.name}
                                         id={item.id} back={'../../'}/>
                        </div>)}
                    </div>
                )}
                <div className='container'>
                    <div className='d-flex justify-content-center flex-wrap my-3'>
                        <Link to={`../../${titleToLink(TITLES.LEGISLATIVE_AREA)}/${titleToLink(TITLES.DIRECTIVE)}`}
                              className={`text-capitalize btn btn-category p-2 mx-2 my-2}`}>Directiva</Link>
                        <Link
                            to={`../../${titleToLink(TITLES.LEGISLATIVE_AREA)}/${titleToLink(TITLES.PERMANENT_COMMISSIONS)}`}
                            className={`text-capitalize btn btn-category p-2 mx-2 my-2}`}>Comisiones</Link>
                        <Link to={`../../${titleToLink(TITLES.LEGISLATIVE_AREA)}/${titleToLink(TITLES.BENCHS)}`}
                              className={`text-capitalize btn btn-category p-2 mx-2 my-2}`}>Bancadas</Link>
                        <Link to={`../../${titleToLink(TITLES.LEGISLATIVE_AREA)}/${titleToLink(TITLES.BRIGADES)}`}
                              className={`text-capitalize btn btn-category p-2 mx-2 my-2}`}>Brigada</Link>
                    </div>
                </div>
            </div>
        </>
    );
}
