import React from "react";

const links = [
    {
        mediaIconClass: 'fab fa-facebook-f',
        media: 'facebook',
    },
    {
        mediaIconClass: 'fab fa-youtube',
        media: 'youtube',
    },
    {
        mediaIconClass: 'fab fa-twitter',
        media: 'twitter',
    },
    {
        mediaIconClass: 'fab fa-instagram',
        media: 'instagram',
    }
];
const MediaLink = (props: any) => {

    const getMediaIcon = (url: string, mediaIcon?: string) => {
        if (mediaIcon == null) {
            const link = links.find(l => new RegExp(l.media, 'ig').test(url));
            if (link) {
                return link.mediaIconClass;
            }
        }
        return mediaIcon;
    };

    const {
        mediaIconClass,
        url,
        extraClazz,
        circle
    }: { mediaIconClass?: string, url: string, extraClazz: string, circle?: boolean } = props;
    const clazz = getMediaIcon(url, mediaIconClass);
    return (
        <>
            {clazz && <a target='_blank' rel='noopener noreferrer'
                         className={`btn btn-sm ${circle == null || circle ? 'rounded-circle' : ''} ${extraClazz ? extraClazz : 'bg-16 fg-white'} mx-2 width-30 my-1`}
                         href={url}>
                <i className={clazz} aria-hidden="true"/>
            </a>}
        </>
    );
}
export default MediaLink;
