import SectionTitle from "../../components/SectionTitle";
import {PRENSA_MOCK} from "../../mocks/prensa/PRENSA_DESCRIPTION_MOCK";
import {useEffect, useState} from "react";
import {URLS} from "../../utils/url";
import {getHttp} from "../../api/HttpClient";
import CardZoomEffect from "../../components/cards/CardZoomEffect";
import {reduceString, removeTags, titleToLink, utils} from "../../utils/Utils";
import ReactPaginate from "react-paginate";
import {CATALOG} from "../../models/constants/Catalog";
import {TITLES} from "../../models/constants/ConstantsUI";
import {useDispatch, useSelector} from "react-redux";
import shortid from "shortid";
import {types} from "../../types/types";
import {HttpMethod} from "../../models/enums/HttpMethod";
import useFetchManyWithLoading from "../../hooks/useFetchManyWithLoading";
import News from "./news/News";

const Noticias = () => {
    const [categories, setCategories] = useState([]);
    const [news, setNews] = useState<any>(null);
    const [pagination, setPagination] = useState<any>({
        list: [],
        pages: 0,
        perPage: 0
    });
    const [topNew, loadTopNew] = useFetchManyWithLoading({
        requests: [{
            url: URLS.LAST_NEW,
            type: HttpMethod.GET,
            key: 'current'
        }, {
            url: CATALOG.NEWS_CATEGORIES,
            type: HttpMethod.GET,
            key: 'categories'
        }
        ],
        initial: null
    });

    const selector = useSelector(((state: any) => state.subMenu))?.subMenuActive;
    const dispatch = useDispatch();
    const [active, setActive] = useState(selector != null ? selector : -1);
    if (selector) {
        dispatch({type: types.setSubMenu, payload: null})
    }
    useEffect(() => {
        if (active === -1 || active === '') {
            (async () => {
                await loadTopNew();
                // setCategories(topNew.categories);
            })();
        } else {
            changeLoading(false);
            getHttp(CATALOG.NEWS_CATEGORIES).then((res: any) => {
                setCategories(res.response);
                loadData(res.response.length > 0 ? selector ? selector : res.response[0].id : -1);
            })
                .catch(error => {

                });
        }
    }, []);

    const loadData = (categoryId: number, page?: number) => {
        setActive(categoryId);
        changeLoading(false);
        getHttp(URLS.NEWS, {
            categoriaId: categoryId,
            per_page: 12,
            page: page == null ? 1 : page
        }).then(res => {
            const data = res.response.data;
            const response: any[] = [];
            data.forEach((element: any) => {
                const img = element.imagenes && element.imagenes.length > 0 ? utils(element.imagenes[0].archivo) : '';
                response.push({
                    title: element.titulo,
                    description: reduceString(removeTags(element.cuerpo), 100),
                    date: element.fecha_publicacion,
                    imgUrl: img,
                    href: `/${titleToLink(TITLES.NEWS_INFO)}/${titleToLink(TITLES.NEWS)}/${element.id}`,
                    linkTitle: `Ir a la noticia`,
                })
            });
            setNews(response);
            setPagination({
                list: response,
                pages: res.response.last_page,
                perPage: res.response.per_page
            });
            changeLoading(true);
        }).catch(error => {
            changeLoading(true)
        });
    };

    const changeLoading = (hidden: boolean) => {
        if (hidden) {
            document.body.classList.remove('loader');
            document.body.classList.add('loaded');
        } else {
            document.body.classList.remove('loaded');
            document.body.classList.add('loader');
        }
    };

    const handlePageClick = async (offset: any) => {
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
        // setLoading(true);
        const {selected}: { selected: number } = offset;
        await loadData(active, selected + 1);
    }

    const getCategories = () => {
        if (topNew) {
            return topNew.categories;
        }
        return categories;
    };
    return (<>
        {(active === -1 || active === '') && topNew ?
            <News newInfo={topNew.current} actionCategories={loadData}/> : null}
        {getCategories() && news && <>
            <SectionTitle {...PRENSA_MOCK} />
            {getCategories() && <div className='container'>
                <div className='d-flex justify-content-center flex-wrap my-3'>
                    {getCategories().map((category: any) =>
                        <button key={`news_category_${category.id} `}
                                className={`text-capitalize btn btn-category p-2 mx-2 my-2 ${category.id === active ? 'active' : ''}`}
                                onClick={() => {
                                    loadData(category.id);
                                }}>
                            {category.name}
                        </button>)}
                </div>
            </div>}
            {news &&
            <div className='w-90 mx-auto my-5'>
                <div className='row'>
                    {news.map((current: any) => <div className='col-4' key={shortid()}>
                        <CardZoomEffect imgUrl={current.imgUrl}
                                        title={current.title}
                                        date={current.date}
                                        description={current.description}
                                        href={current.href}
                                        linkTitle={current.linkTitle}/>
                    </div>)}
                </div>
            </div>
            }
            {pagination.pages > 1 ?
                <div className='d-flex justify-content-center'>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel='Siguiente'
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pagination.pages}
                        previousLabel='Anterior'
                        containerClassName='pagination'
                        previousClassName='previous-next'
                        nextClassName='previous-next'
                        pageClassName='page-item'
                        activeClassName='page-selected'
                        disabledClassName='disable-button'
                    />
                </div> : null}
        </>
        }
    </>)
};
export default Noticias;
