import {DropDown} from "../models/interfaces/NavConfig";
import Organigram from "../ui/institutional/organigram/Organigram";
import Institutional from "../ui/institutional/Institutional";
import RegulatoryFramework from "../ui/institutional/regulatory-framework/RegulatoryFramework";
import {buildDropDown} from "../utils/Utils";
import {TITLES} from "../models/constants/ConstantsUI";
import MissionVision from "../ui/institutional/MissionVision";
import InstitutionalObjectives from "../ui/institutional/InstitutionalObjectives";

export const InstitutionalRoutes: DropDown =
    buildDropDown({
        title: TITLES.INSTITUTIONAL,
        link: true,
        component: <Institutional/>,
        clazz: 'root-link',
        links: [
            {
                title: TITLES.MISSION_VISION,
                component: <MissionVision/>
            },
            {
                title: TITLES.INSTITUTIONAL_OBJECTIVES,
                component: <InstitutionalObjectives/>
            },
            {
                title: TITLES.NORMATIVE_FRAME,
                component: <RegulatoryFramework/>
            },
            {
                title: TITLES.ORGANIZATION,
                component: <Organigram/>
            }
        ],
    });