import {useEffect} from "react";
import SectionTitle from "../../../components/SectionTitle";
import {URLS} from "../../../utils/url";
import useFetchWithLoading from "../../../hooks/useFetchWithLoading";
import {HttpMethod} from "../../../models/enums/HttpMethod";
import {Link} from "react-router-dom";
import {TITLES} from "../../../models/constants/ConstantsUI";

export const PermanentCommissions = () => {
    const [list, loadData] = useFetchWithLoading({
        request: {
            url: URLS.PERMANENT_COMMISSIONS,
            type: HttpMethod.GET,
            key: 'commissions',
        },
        initial: []
    });
    useEffect(() => {
        (async () => await loadData())();
    }, []);
    return (
        <>
            <SectionTitle title={TITLES.PERMANENT_COMMISSIONS} varImg='bar-2'/>
            <div className={'container'}>
                <p className='description-1 mt-5'>La Cámara de Senadores cuenta con diez Comisiones y veinte Comités
                    permanentes, cuyas denominaciones
                    y afinidad temática están definidas por la estructura de la Constitución Política del Estado.
                </p>
                <p className='description-1 mb-5'>
                    Puede acceder a más información sobre cada una de las comisiones a través del enlace del título de
                    cada una.
                </p>
                {list.map((item: any, index: number) => (
                    <PermanentCommissionItem key={index} item={item} index={index}/>
                ))}
            </div>
        </>);
}

const PermanentCommissionItem = ({item, index}: any) => {
    return (
        <div>
            {item.comites.length > 0 && <Link to={`${item.id}`}>
                <h4 className='bg-9 p-2 fg-white black-1'> {index + 1} . {item.nombre}</h4>
            </Link>}
            {item.comites.length === 0 && <h4 className='bg-9 p-2 fg-white black-1'> {index + 1} . {item.nombre}</h4>}
            <div className='border-3 mt-1 mb-2'>
                {item.comites.length > 0 &&
                <ol className='list-1'>
                    {item.comites.map((crite: any, key: number) => (
                        <li key={key} className='px-2 fg-1 py-1'> {crite.name}</li>
                    ))}
                </ol>}
            </div>
        </div>
    );
}