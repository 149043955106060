import {useRef} from "react";
import './search.css';

const Search = ({handler}: { handler: any }) => {
    const input = useRef<HTMLInputElement | null>(null);
    const searchEvent = () => {
        handler(input.current?.value);
    };

    const searchEventKeyBoard = (e: any) => {
        if (['Enter', 'NumpadEnter'].includes(e.code)) {
            handler(input.current?.value);
        }
    };

    const changeValue = (e: any) => {
        const value = e.target.value;
        if (value.trim().length === 0) {
            handler('');
        }
    };

    return (
        <div className='d-flex justify-content-between mx-2 my-5'>
            <div className='search-container position-relative'>
                <input onChange={changeValue} onKeyDown={searchEventKeyBoard} type='text' ref={input}
                       className='search mx-2 fs-4 h-100'/>
            </div>
            <div className='mx-1'>
                <button className='btn search-button' onClick={searchEvent}>Buscar</button>
            </div>
        </div>
    );
};
export default Search;
