import organigram from '../../../assets/img/Organigram.jpg';
import pdf from '../../../assets/pdf/Organigrama.pdf';
import './organigram.css';

const Organigram = () => {
    return (
        <>
            <div>
                <div className="d-flex flex-column justify-content-center align-items-center w-100">
                    <div className='bg-organigram py-2 w-100 d-flex justify-content-center align-items-center'>
                        <h3 className="text-uppercase fg-white text-center">
                            ORGANIGRAMA GENERAL<br/>
                            DE LA CÁMARA DE SENADORES</h3>
                    </div>
                    <div className='mt-5 organigram'>
                        <img src={organigram} className='img-fluid' alt='Organigrama'/>
                    </div>
                    <div className='my-5'>
                        <a className='bg-2 fg-white border-radius-0-5 p-3' href={pdf} target='_blank'
                           rel='noopener noreferrer'>Descargar</a>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Organigram;
