import {URLS} from "../../../utils/url";
import {FieldType, IBasicTable} from "../../../models/interfaces/IBasicTable";
import {removeTags} from "../../../utils/Utils";
import {ISearcher, ISearcherType} from "../../../models/interfaces/ISearcher";
import {IRequest} from "../../../models/interfaces/IRequest";
import {HttpMethod} from "../../../models/enums/HttpMethod";
import {PER_PAGE} from "../../../models/constants/Constants";
import SectionTitle from "../../../components/SectionTitle";
import {TITLES} from "../../../models/constants/ConstantsUI";
import BasicTable from "../../../components/tables/BasicTable";
import ImportantAdvices from "../../important-advices/ImportantAdvices";


const FIELDS: IBasicTable[] = [
    {
        label: 'Título',
        field: 'titulo',
        type: FieldType.DIRECT,
        styles: {width: '200px'},
        link: {
            field: 'id',
        }
    },
    {
        label: 'Número',
        field: 'numero',
        type: FieldType.DIRECT,
        styles: {width: '80px'},
    },
    {
        label: 'Asunto',
        field: 'asunto',
        type: FieldType.DIRECT,
        formatter: removeTags,
    },
    {
        label: 'PDF',
        field: 'documento',
        type: FieldType.FILE,
        styles: {width: '120px'}
    }
];

const searches: ISearcher [] = [
    {
        type: ISearcherType.TEXT,
        label: 'Título',
        field: 'titulo',
        clazz: 'col-xl-4 col-sm-12'
    },
    {
        type: ISearcherType.TEXT,
        label: 'Número',
        field: 'numero',
        clazz: 'col-xl-4 col-sm-12'
    },
    {
        type: ISearcherType.TEXT,
        label: 'Asunto',
        field: 'asunto',
        clazz: 'col-xl-4 col-sm-12'
    },
];
const request: IRequest = {
    type: HttpMethod.GET,
    url: URLS.REJECTED_LAWS,
    parameters: {
        per_page: PER_PAGE
    }
};
const RejectedLaws = () => {
    return (
        <>
            <SectionTitle title={TITLES.REJECTED_LAW} varImg={'bar-3'}/>
            <BasicTable fields={FIELDS} request={request} searches={searches}/>
            <ImportantAdvices />
        </>
    );
};
export default RejectedLaws;
