import * as React from "react";

export enum LinkType {
    SIMPLE_LINK, DROP_DOWN, MEGA_MENU
}

export interface MegaMenu extends BaseLinkType{
    megaItems: MegaItem[],
}

export interface MegaItem extends BaseLink{
    links: ComponentLink[]
}

interface ComponentLink extends BaseLink{
    title: string,
    component: React.ReactNode,
    link: string
}

export interface DropDown extends BaseLinkType{
    links: ComponentLink[],
}

export interface SimpleLink extends BaseLinkType{
    link?: string,
    component: React.ReactNode,
}

export interface BaseLink {
    title: string,
    link?: string| null,
    component?: React.ReactNode,
    linkeable?: boolean,
}
interface BaseLinkType extends BaseLink{
    type: LinkType,
    clazz?: string,
}
