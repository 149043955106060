import React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Footer} from "../components/Footer";
import HeaderApp from "../components/HeaderApp";
import ScrollToTop from "../components/ScrollToTop";
import StickyMediaButtons from "../components/StickyMediaButtons";
import {concatRoutes} from "../utils/MenuUtil";
import News from "../ui/prensa/news/News";
import {titleToLink} from "../utils/Utils";
import {TITLES} from "../models/constants/ConstantsUI";
import TreatmentCard from "../ui/legislative-area/legislation/TreatmentCard";
import ApprovedCard from "../ui/legislative-area/legislation/ApprovedCard";
import SanctionedCard from "../ui/legislative-area/legislation/SanctionedCard";
import RejectedCard from "../ui/legislative-area/legislation/RejectedCard";
import PromulgatedCard from "../ui/legislative-area/legislation/PromulgatedCard";
import SenatorInfo from "../ui/legislative-area/legislative-organization/SenatorInfo";
import PermanentCommissionInfo from "../ui/legislative-area/legislative-organization/PermanentCommissionInfo";
import NotFound from "../ui/pages/NotFound";
import GalleryInfo from "../ui/prensa/GalleryInfo";
import ReturnedCard from "../ui/legislative-area/legislation/ReturnedCard";
import RequestWrittenReportCard from "../ui/legislative-area/fiscalization/RequestWrittenReportCard";
import RequestOralReportCard from "../ui/legislative-area/fiscalization/RequestOralReportCard";
import ChamberDeclarationsCard from "../ui/legislative-area/gestion/ChamberDeclarationsCard";
import ChamberResolutionsCard from "../ui/legislative-area/gestion/ChamberResolutionsCard";
import CommunicationMinutesCard from "../ui/legislative-area/gestion/CommunicationMinutesCard";

export const AppRouter = (props: any) => {
    const routes = concatRoutes();
    return (
        <Router>
            <ScrollToTop/>
            <HeaderApp/>
            <StickyMediaButtons/>
            <Routes>
                {routes.map(r => <Route key={r.path}
                                        path={r.path} element={r.element}
                />)}
                <Route path={`${titleToLink(TITLES.NEWS_INFO)}/${titleToLink(TITLES.NEWS)}/:id`} element={<News/>}/>
                <Route path={`${titleToLink(TITLES.LEGISLATIVE_AREA)}/${titleToLink(TITLES.TREATMENT_LAW)}/:id`}
                       element={<TreatmentCard/>}/>
                <Route path={`${titleToLink(TITLES.LEGISLATIVE_AREA)}/${titleToLink(TITLES.APPROVED_LAW)}/:id`}
                       element={<ApprovedCard/>}/>
                <Route path={`${titleToLink(TITLES.LEGISLATIVE_AREA)}/${titleToLink(TITLES.SANCTIONED_LAW)}/:id`}
                       element={<SanctionedCard/>}/>
                <Route path={`${titleToLink(TITLES.LEGISLATIVE_AREA)}/${titleToLink(TITLES.REJECTED_LAW)}/:id`}
                       element={<RejectedCard/>}/>
                <Route path={`${titleToLink(TITLES.LEGISLATIVE_AREA)}/${titleToLink(TITLES.RETURNED_LAW)}/:id`}
                       element={<ReturnedCard/>}/>
                <Route path={`${titleToLink(TITLES.LEGISLATIVE_AREA)}/${titleToLink(TITLES.PROMULGATED_LAW)}/:id`}
                       element={<PromulgatedCard/>}/>
                <Route path={`${titleToLink(TITLES.LEGISLATIVE_AREA)}/${titleToLink(TITLES.PLENARY_SESSION)}/:id`}
                       element={<SenatorInfo/>}/>
                <Route path={`${titleToLink(TITLES.LEGISLATIVE_AREA)}/${titleToLink(TITLES.PERMANENT_COMMISSIONS)}/:id`}
                       element={<PermanentCommissionInfo/>}/>
                <Route path={`${titleToLink(TITLES.NEWS_INFO)}/${titleToLink(TITLES.IMAGES_GALLERY)}/:id`}
                       element={<GalleryInfo/>}/>

                <Route
                    path={`${titleToLink(TITLES.LEGISLATIVE_AREA)}/${titleToLink(TITLES.FISCALIZATION)}/${titleToLink(TITLES.REQUEST_WRITTEN_REPORT)}/:id`}
                    element={<RequestWrittenReportCard title={TITLES.REQUEST_WRITTEN_REPORT}/>}/>

                <Route
                    path={`${titleToLink(TITLES.LEGISLATIVE_AREA)}/${titleToLink(TITLES.FISCALIZATION)}/${titleToLink(TITLES.REQUEST_ORAL_REPORT)}/:id`}
                    element={<RequestOralReportCard title={TITLES.REQUEST_ORAL_REPORT}/>}/>

                <Route
                    path={`${titleToLink(TITLES.LEGISLATIVE_AREA)}/${titleToLink(TITLES.GESTION)}/${titleToLink(TITLES.CHAMBER_DECLARATIONS)}/:id`}
                    element={<ChamberDeclarationsCard title={TITLES.CHAMBER_DECLARATIONS}/>}/>
                <Route
                    path={`${titleToLink(TITLES.LEGISLATIVE_AREA)}/${titleToLink(TITLES.GESTION)}/${titleToLink(TITLES.CHAMBER_RESOLUTIONS)}/:id`}
                    element={<ChamberResolutionsCard title={TITLES.CHAMBER_RESOLUTIONS}/>}/>
                <Route
                    path={`${titleToLink(TITLES.LEGISLATIVE_AREA)}/${titleToLink(TITLES.GESTION)}/${titleToLink(TITLES.COMMUNICATION_MINUTES)}/:id`}
                    element={<CommunicationMinutesCard title={TITLES.COMMUNICATION_MINUTES}/>}/>

                <Route path='*' element={<NotFound/>}/>
            </Routes>
            <Footer/>
        </Router>
    )
}

