import SectionTitle from "../SectionTitle";
import useFetchWithLoading from "../../hooks/useFetchWithLoading";
import {IRequest} from "../../models/interfaces/IRequest";
import {useEffect} from "react";
import {formatDate, removeTags, utils} from "../../utils/Utils";
import {DATE_FORMAT1} from "../../models/constants/Constants";

const LawCard = ({
                     title,
                     request,
                 }: { title: string, request: IRequest}) => {
        const [data, loadData] = useFetchWithLoading({
            request: request,
            initial: []
        });
        useEffect(() => {
            (async () => (await loadData()))();
        }, []);
        return (
            <>
                <SectionTitle title={title}/>
                {data && <>
                    <h3 className='title-1 text-center mt-3 text-break'>{data.titulo}</h3>
                    <div className='container shadow-left-bottom my-3'>
                        <div className='p-5 d-flex flex-column'>
                            {data.gestion && <>
                                <label className='title-description-2'>Legislatura:</label>
                                <label className='description-1'>{data.gestion}</label>
                            </>}
                            {data.numero && <>
                                <label className='title-description-2'>Numero:</label>
                                <label className='description-1 text-break'>{data.numero}</label>
                            </>}
                            {data.origen_catalogo && <>
                                <label className='title-description-2'>Origen o Revisión:</label>
                                <label
                                    className='description-1'>{data.origen_catalogo.name}</label>
                            </>}
                            {data.fecha_ingreso && <>
                                <label className='title-description-2'>Fecha Ingreso:</label>
                                <label
                                    className='description-1 text-capitalize'>{formatDate(data.fecha_ingreso, DATE_FORMAT1)}</label>
                            </>}
                            {data.fecha_cd &&
                            <>
                                <label className='title-description-2'>Fecha CD:</label>
                                <label
                                    className='description-1 text-capitalize'>{formatDate(data.fecha_cd, DATE_FORMAT1)}</label>
                            </>
                            }
                            {data.asunto && <>
                                <label className='title-description-2'>Asunto Ley:</label>
                                <label className='description-1 text-break'>{data.asunto}</label>
                            </>}
                            {data.proyectista_catalogo && <>
                                <label className='title-description-2'>Proyectistas:</label>
                                <label
                                    className='description-1'>{data.proyectista_catalogo.name}</label>
                            </>}
                            {data.comision1 && <>
                                <label className='title-description-2'>Comisión I:</label>
                                <label className='description-1'>{data.comision1.name}</label>
                            </>}
                            {data.comision2 && <>
                                <label className='title-description-2'>Comisión II:</label>
                                <label className='description-1'>{data.comision2.name}</label>
                            </>}
                            {data.fecha_sancion &&
                            <>
                                <label className='title-description-2'>Fecha Sancion:</label>
                                <label
                                    className='description-1 text-capitalize'>{formatDate(data.fecha_sancion, DATE_FORMAT1)}</label>
                            </>
                            }
                            {data.fecha_remision &&
                            <>
                                <label className='title-description-2'>Fecha Remision:</label>
                                <label
                                    className='description-1 text-capitalize'>{formatDate(data.fecha_remision, DATE_FORMAT1)}</label>
                            </>
                            }
                            {data.observaciones && <>
                                <label className='title-description-2'>Observaciones Ley:</label>
                                <label className='description-1 text-break'>{removeTags(data.observaciones, false)}</label>
                            </>}
                        </div>
                    </div>
                    {data && <div className='d-flex justify-content-center my-3'>
                        <a className='btn download-pdf d-flex justify-content-between'
                           href={utils(data.documento)}>
                            <div className='icon mx-2'/>
                            Descargar
                        </a>
                    </div>}
                </>}
            </>
        );
    }
;
export default LawCard;
