import {FieldType, IBasicTable} from "../../models/interfaces/IBasicTable";
import shortid from "shortid";
import TargetBlank from "../TargetBlank";
import {utils} from "../../utils/Utils";
import {IRequest} from "../../models/interfaces/IRequest";
import {useEffect, useState} from "react";
import ReactPaginate from "react-paginate";
import Searcher from "../search/Searcher";
import {ISearcher} from "../../models/interfaces/ISearcher";
import useFetchTableSource from "../../hooks/useFetchTableSource";
import {Link} from "react-router-dom";
import './table-1.css';

const SourceTable = ({fields, request, sourceRequest, searches}: {
        fields: IBasicTable[], request: IRequest, sourceRequest: IRequest[], searches?: ISearcher[]
    }) => {
        const [result, loadData, loadDataAll, pagination] = useFetchTableSource({
            table: request,
            sources: sourceRequest,
            initial: {}
        });
        const [currentFilter, setCurrentFilter] = useState<any>({});
        const {table, sources} = result;
        useEffect(() => {
            (async () => (await loadDataAll()))();
        }, []);

        const searchEvent = async (parameters: any) => {
            setCurrentFilter(Object.assign({}, parameters));
            await loadData({
                page: 1,
                ...parameters
            });
        };
        const handlePageClick = async (offset: any) => {
            window.scrollTo({top: 0, left: 0, behavior: 'auto'});
            const {selected}: { selected: number } = offset;
            await loadData({page: selected + 1, ...currentFilter});
        }
        return (<>
            {table && searches &&
            <Searcher sources={sources} searches={searches} action={searchEvent}/>}
            <div className='w-100 mt-3'>
                <div className='px-5 table-responsive'>
                    <table className='table table-1 w-100 fg-1'>
                        <thead>
                        <tr className='table-1-header'>
                            {fields.map(field => <th key={shortid()}>{field.label}</th>)}
                        </tr>
                        </thead>
                        <tbody>
                        {table && table.data?.map((row: any) =>
                            <tr key={shortid()}>
                                {fields.map((field: IBasicTable) => <td key={shortid()} style={field.styles}>
                                    <RenderTD fieldConfig={field} row={row} sources={sources}/>
                                </td>)}
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
                {pagination.pages > 1 ?
                    <div className='d-flex justify-content-center'>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel='Siguiente'
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pagination.pages}
                            previousLabel='Anterior'
                            containerClassName='pagination'
                            previousClassName='previous-next'
                            nextClassName='previous-next'
                            pageClassName='page-item'
                            activeClassName='page-selected'
                            disabledClassName='disable-button'
                        />
                    </div> : null}
            </div>
        </>);
    }
;
const RenderTD = (
    {
        fieldConfig, row, sources
    }
        :
        {
            fieldConfig: IBasicTable, row
                :
                any, sources
                :
                any
        }
    ) => {
        switch (fieldConfig.type) {
            case FieldType.DIRECT:
                return (
                    <>
                        {fieldConfig.link == null && <>{fieldConfig.formatter != null ? fieldConfig.formatter(row[fieldConfig.field]) : row[fieldConfig.field]}</>}
                        {fieldConfig.link != null &&
                        <Link className='link-field'
                            to={`${fieldConfig.link.path == null ? row[fieldConfig.link.field] : fieldConfig.link.path}`}>{fieldConfig.formatter != null ? fieldConfig.formatter(row[fieldConfig.field]) : row[fieldConfig.field]}</Link>}
                    </>
                );
            case FieldType.HTML:
                return (
                    <>
                        {fieldConfig.formatter != null ? fieldConfig.formatter(row[fieldConfig.field]) : row[fieldConfig.field]}
                    </>);
            case FieldType.FILE:
                return (
                    <>
                        {row[fieldConfig.field] &&
                        <div className='d-flex'>
                            <TargetBlank className='btn download-pdf d-flex justify-content-around'
                                         href={utils(row[fieldConfig.field])}>
                                <div className='icon mr-2'/>
                                Descargar
                            </TargetBlank>
                        </div>
                        }
                    </>
                );
            case FieldType.LIST:
                if (fieldConfig.source && sources[fieldConfig.source.name]) {
                    const source = sources[fieldConfig.source.name];
                    let value = row[fieldConfig.field];
                    if (!Array.isArray(value)) {
                        value = [value];
                    }
                    const rows: any[] = [];
                    value.forEach((val: any) => {
                        const selected = source.find((s: any) => s[fieldConfig.source!.key] === val);
                        if (selected) {
                            const current = fieldConfig.source!;
                            if (typeof current.labels === 'string') {
                                const label = current.labels as string;
                                rows.push(`${selected[label]}`);
                            } else {
                                const values: string[] = [];
                                current.labels.forEach(label => {
                                    values.push(selected[label]);
                                });
                                rows.push(`${values.join(' ').replace(/\s{2,}/g, ' ').trim()}`);
                            }
                        }
                    });
                    return <>{rows.join(', \n')}</>;
                }
                break;
        }
        return null;
    }
;


export default SourceTable;
